import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'

function QMuiModal({ title, open, onClose, children, ...props }) {
  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={onClose}
      classes={{
        paper: 'QMuiModal-paper',
      }}
      {...props}
    >
      <DialogTitle classes={{ root: 'QMuiModal-title' }}>{title}</DialogTitle>
      {children}
    </Dialog>
  )
}

export default QMuiModal
