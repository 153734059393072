import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import ArrowRight from '@Assets/img/fleche2.png'

export default function SchemaItem({ step, onClick, className }) {
  const [status, setStatus] = useState('progress')

  useEffect(() => {
    if (step.startedAt === null) {
      setStatus('pending')
    } else if (step.finishedAt !== null && step.validatedAt !== null) {
      setStatus('finished')
    } else if (step.validatedAt === null) {
      setStatus('progress')
    }
  }, [step, setStatus])

  return (
    <div onClick={() => onClick(step)} className={cx(className, status)}>
      <div className="SchemaItem-left">
        <img style={{ width: 40 }} src={step.stepType.image} alt="" />
      </div>
      <div className="SchemaItem-content">
        <span className="SchemaItem-title">{step.title}</span>
        <span className="SchemaItem-subtitle">{step.subtitle}</span>
      </div>
      <div className="SchemaItem-right">
        <img src={ArrowRight} />
      </div>
    </div>
  )
}
