import React from 'react'

export default function Email(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 18">
      <g
        transform="translate(1.372 .607)"
        fill="none"
        stroke={props.color ? props.color : '#fff'}
        strokeMiterlimit="10"
        strokeWidth="2"
      >
        <rect width="21" height="16" rx="4" transform="translate(-.372 .393)" />
        <path d="M1.548 1.213L9.135 8.8a1.76 1.76 0 002.489 0l7.588-7.587" />
      </g>
    </svg>
  )
}
