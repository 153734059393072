import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from '@Context/auth.js'
import QSelect from '@CommonComponents/QSelect'
import QFile from '@CommonComponents/QFile'
import formRules from '@Utils/form-rules'
import {
  Add as AddIcon,
  AttachFile as AttachFileIcon,
} from '@material-ui/icons'
import {
  fileToBase64,
  formatSymfonyValidationErrors,
  getErrorMsgFromSymfonyResponse,
} from '@Helpers'
import { useSnackbar } from '@Context/snackbar'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'
import QMuiTextarea from '@CommonComponents/Mui/QMuiTextarea'
import { patchCustomer } from '@API/customer'

export default function CustomerProfileForm({ customer }) {
  const { logout, setUser } = useAuth()
  const [userData, setUserData] = useState({ companies: [] })
  const [avatarFile, setAvatarFile] = useState(null)

  const { register, control, handleSubmit, errors, setValue, setError } =
    useForm()

  const { addSnackbarMsg } = useSnackbar()

  useEffect(() => {
    setUserData(customer)
  }, [customer])

  useEffect(() => {
    for (let key in userData) {
      setValue(key, userData[key])
    }
  }, [userData, setValue])

  const onSubmit = async (data) => {
    if (!data.hasOwnProperty('companies')) {
      data.companies = []
    }
    if (avatarFile != null) {
      data.fileName = avatarFile.name
      data.fileContent = await fileToBase64(avatarFile)
    }

    try {
      const response = await patchCustomer(data)
      if (response.data.jwtToken) {
        localStorage.setItem('auth_token', response.data.jwtToken)
      }
      setUserData(response.data)
      setUser(response.data)
      addSnackbarMsg({
        severity: 'success',
        content: 'Profil modifié',
      })
    } catch (error) {
      const response = error.response
      if (response.data.message === 'Validation Failed') {
        const formatedErrors = formatSymfonyValidationErrors(
          response.data.errors
        )
        for (let key in formatedErrors) {
          setError(key, formatedErrors[key])
        }
        onError(formatedErrors)
      } else {
        addSnackbarMsg({
          severity: 'error',
          content: getErrorMsgFromSymfonyResponse(error.response),
        })
      }
    }
  }

  const addCompany = () => {
    setUserData({
      ...userData,
      companies: [...userData.companies, {}],
    })
  }

  const deleteCompany = (idx) => {
    const companies = userData.companies
    companies.splice(idx, 1)
    setUserData({
      ...userData,
      companies,
    })
  }

  const onError = (errors) => {
    for (let key in errors) {
      if (['fileName', 'fileContent'].includes(key)) {
        addSnackbarMsg({
          severity: 'error',
          content: errors[key].message,
        })
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <section className="page-section">
        <h2>État civil</h2>
        <div className="card">
          <div className="flex align-center p-4">
            {userData.image && (
              <img className="profile-pic" src={userData.image} alt="Profile" />
            )}

            <QFile
              color="secondary"
              file={avatarFile}
              onChange={setAvatarFile}
              endIcon={<AttachFileIcon />}
            >
              Votre avatar
            </QFile>
          </div>

          <fieldset className="flex flex-wrap">
            <QSelect
              className="form-field"
              name="civility"
              label="Civilité*"
              control={control}
              options={[
                { label: 'Mr', value: 'm' },
                { label: 'Mme', value: 'mme' },
              ]}
              errorMsg={errors?.civility?.message}
            />

            <QMuiFormInput
              className="form-field"
              label="Nom*"
              name="lastName"
              register={register({ ...formRules.isRequired })}
              control={control}
              errorMsg={errors?.lastName?.message}
            />

            <QMuiFormInput
              className="form-field"
              label="Prénom*"
              name="firstName"
              register={register({ ...formRules.isRequired })}
              control={control}
              errorMsg={errors?.firstName?.message}
            />

            <QMuiFormInput
              className="form-field"
              label="Email*"
              name="email"
              register={register({ ...formRules.isRequired })}
              control={control}
              errorMsg={errors?.email?.message}
            />

            <QMuiFormInput
              className="form-field"
              label="Téléphone*"
              name="phone"
              register={register({ ...formRules.isRequired })}
              control={control}
              errorMsg={errors?.phone?.message}
            />

            <QMuiFormInput
              className="form-field"
              label="Adresse*"
              name="address.street"
              register={register({ ...formRules.isRequired })}
              control={control}
              errorMsg={errors?.address?.street?.message}
            />

            <QMuiFormInput
              className="form-field"
              label="Ville*"
              name="address.city"
              register={register({ ...formRules.isRequired })}
              control={control}
              errorMsg={errors?.address?.city?.message}
            />

            <QMuiFormInput
              className="form-field"
              label="Code postal*"
              name="address.postalCode"
              register={register({ ...formRules.isRequired })}
              control={control}
              errorMsg={errors?.address?.postalCode?.message}
            />
          </fieldset>
        </div>
      </section>

      {userData.companies.map((company, companyIdx) => (
        <section key={companyIdx} className="page-section">
          <div className="flex align-items-center justify-between">
            <h2>Entreprise {companyIdx + 1}</h2>
            <QMuiButton type="button" onClick={() => deleteCompany(companyIdx)}>
              Supprimer
            </QMuiButton>
          </div>

          <div className="card">
            <fieldset>
              <QMuiFormInput
                className="form-field"
                label="Raison sociale*"
                name={`companies[${companyIdx}].name`}
                register={register({ ...formRules.isRequired })}
                control={control}
                errorMsg={errors?.companies?.[companyIdx]?.name?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="Siren*"
                name={`companies[${companyIdx}].siren`}
                register={register({ ...formRules.isRequired })}
                control={control}
                errorMsg={errors?.companies?.[companyIdx]?.siren?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="Secteur"
                name={`companies[${companyIdx}].sector`}
                control={control}
                errorMsg={errors?.companies?.[companyIdx]?.sector?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="Qualité"
                name={`companies[${companyIdx}].quality`}
                control={control}
                errorMsg={errors?.companies?.[companyIdx]?.quality?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="N° associé"
                name={`companies[${companyIdx}].associated_nb`}
                control={control}
                errorMsg={
                  errors?.companies?.[companyIdx]?.associated_nb?.message
                }
              />

              <QMuiFormInput
                className="form-field"
                label="Adresse"
                name={`companies[${companyIdx}].address.street`}
                control={control}
                errorMsg={
                  errors?.companies?.[companyIdx]?.address?.street?.message
                }
              />

              <QMuiFormInput
                className="form-field"
                label="Ville"
                name={`companies[${companyIdx}].address.city`}
                control={control}
                errorMsg={
                  errors?.companies?.[companyIdx]?.address?.city?.message
                }
              />

              <QMuiFormInput
                className="form-field"
                label="Code postal"
                name={`companies[${companyIdx}].address.postalCode`}
                control={control}
                errorMsg={
                  errors?.companies?.[companyIdx]?.address?.postalCode?.message
                }
              />

              <QMuiTextarea
                className="form-field"
                rowsMin={3}
                label="Description"
                name={`companies[${companyIdx}].description`}
                control={control}
                errorMsg={errors?.companies?.[companyIdx]?.description}
              />
            </fieldset>
          </div>
        </section>
      ))}

      <div>
        <QMuiButton
          color="secondary"
          onClick={addCompany}
          endIcon={<AddIcon />}
        >
          Ajouter une entreprise
        </QMuiButton>
      </div>

      <div className="flex justify-end">
        <QMuiButton type="submit">Enregistrer</QMuiButton>
      </div>
    </form>
  )
}
