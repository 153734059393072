import React from 'react'
import { Controller } from 'react-hook-form'
import Switch from '@material-ui/core/Switch'

export default function QMuiSwitch({ control, name, ...props }) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={false}
      render={({ onChange, value }) => (
        <Switch
          classes={{ root: 'QMuiSwitch' }}
          onChange={(e) => onChange(e.target.checked)}
          checked={value}
          {...props}
        />
      )}
    />
  )
}
