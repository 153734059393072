import { differenceInDays, format } from 'date-fns'
import { upperFirst } from 'lodash'
import clientIcon from '@Assets/img/icon-client.png'
import messagerieIcon from '@Assets/img/icon-messagerie.png'
import cx from 'classnames'

export default function DiscussionItem({ discussion, current, onClick, read }) {
  return (
    <div
      onClick={() => onClick(discussion)}
      className={cx('discussions-item', {
        'discussions-item-current': !!current,
        'discussions-item-hasUnseenMsgs': discussion?.nbMessagesNotSeen > 0,
      })}
    >
      <div className="discussions-item-avatar">
        {discussion?.type === 'collaboration avocats et client' ? (
          <img src={messagerieIcon} />
        ) : (
          <img src={clientIcon} />
        )}
      </div>
      <div className="discussions-item-content">
        <div className="discussions-item-title">
          {discussion?.type === 'collaboration avocats et client' ? (
            <div>{discussion?.folder?.name}</div>
          ) : (
            <div>
              Service client: {upperFirst(discussion?.user?.civility)}{' '}
              {upperFirst(discussion?.user?.lastName)}{' '}
              {upperFirst(discussion?.user?.firstName)}
            </div>
          )}

          {discussion?.nbMessagesNotSeen > 0 && (
            <span className="badge">{discussion?.nbMessagesNotSeen}</span>
          )}
        </div>
        <div className="discussions-item-message">
          {discussion?.lastMessage ? (
            <div>{discussion?.lastMessage?.content}</div>
          ) : (
            <div>Aucun message</div>
          )}
        </div>
      </div>
      <div className="discussions-item-date">
        {differenceInDays(new Date(), new Date(discussion?.updatedAt)) ===
          0 && <div>{format(new Date(discussion?.updatedAt), 'HH:mm')}</div>}
        {differenceInDays(new Date(), new Date(discussion?.updatedAt)) ===
          1 && <div>Hier</div>}
        {differenceInDays(new Date(), new Date(discussion?.updatedAt)) > 1 && (
          <div>{format(new Date(discussion?.updatedAt), 'dd/MM/yy')}</div>
        )}
      </div>
    </div>
  )
}
