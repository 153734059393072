import DomainAPI from '@API/admin/parameters/domains'
import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import SearchIcon from '@Components/icons/SearchIcon'
import DomainManager from '@Components/DomainManager'
import Pagination from '@material-ui/lab/Pagination'
import { motion } from 'framer-motion'
import { debounce } from 'lodash'
import { InputAdornment } from '@material-ui/core'
import QMuiInput from '@CommonComponents/Mui/QMuiInput'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import AddDomainModalForm from '@Components/forms/AddDomainModalForm'
import Plus from '@Components/icons/Plus'

export default function DomainsManagement() {
  const [domains, setDomains] = useState([])
  const [meta, setMeta] = useState({})
  const [modalShow, setModalShow] = useState(false)
  const [search, setSearch] = useState(null)

  const pageNum = parseInt(
    new URLSearchParams(useLocation().search).get('p') || 1
  )

  const history = useHistory()

  const debounceSearch = debounce(async (e) => setSearch(e.target.value), 800)

  useEffect(() => {
    async function fetchDomains() {
      try {
        const { data } = await DomainAPI.getAllDomains(pageNum, search)
        setDomains(data.results)
        setMeta(data.meta)
      } catch (e) {
        console.error(e)
      }
    }
    fetchDomains()
  }, [pageNum, search])

  const handleNewDomain = (data) => {
    if (domains.length < 10) {
      setDomains([...domains, data])
    } else {
      history.push(`?p=${pageNum + 1}`)
    }
  }

  const updateDomain = (domain) => {
    setDomains(domains.map((d) => (d.id === domain.id ? domain : d)))
  }

  const handleDeleteDomain = (index) => {
    const copyDomainList = [...domains]
    copyDomainList.splice(index, 1)

    if (copyDomainList.length > 0) {
      setDomains(copyDomainList)
    } else {
      history.push(`?p=${pageNum - 1}`)
    }
  }

  return (
    <motion.div exit={{ opacity: 0 }} transition={{ duration: 0.5 }}>
      <h1 className="page-title">Domaine d'activité</h1>

      <header className="flex align-center justify-end mb-6">
        {domains.length > 0 && (
          <Pagination
            classes={{ root: 'mr-auto' }}
            page={pageNum}
            count={Math.ceil(meta.total_items / meta.items_per_page)}
            onChange={(e, page) => history.push(`?p=${page}`)}
            size="small"
          />
        )}
        <QMuiInput
          style={{ marginRight: 10 }}
          onChange={debounceSearch}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon style={{ width: 24, height: 24 }} />
            </InputAdornment>
          }
        />
        <QMuiButton onClick={() => setModalShow(true)}>
          Ajouter un nouveau domaine
          <Plus width={15} style={{ marginLeft: 12 }} />
        </QMuiButton>
        <AddDomainModalForm
          open={modalShow}
          handleNewDomain={handleNewDomain}
          handleClose={() => setModalShow(false)}
        />
      </header>

      <div>
        {domains.map((domain, index) => (
          <DomainManager
            key={domain.id}
            domain={domain}
            updateDomain={updateDomain}
            handleDeleteDomain={() => handleDeleteDomain(index)}
          />
        ))}
      </div>
    </motion.div>
  )
}
