import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import LawyerAPI from '@API/admin/experts'
import ExpertProfileForm from '@Components/forms/ExpertProfileForm'
import Breadcrumb from '@CommonComponents/Breadcrumb'
import DomainsAPI from '@API/admin/parameters/domains'
import DocumentsGestion from '@Components/DocumentsGestion'

export default function Lawyer() {
  const [lawyer, setLawyer] = useState(null)
  const [domains, setDomains] = useState([])

  const params = useParams()
  const isCreation = !('lawyerId' in params)

  useEffect(() => {
    const fetchLawyer = async () => {
      if (params.lawyerId !== null && params.lawyerId !== undefined) {
        const { data: lawyerData } = await LawyerAPI.getOne(params.lawyerId)
        setLawyer(lawyerData)
      }
    }

    fetchLawyer()
  }, [params])

  useEffect(() => {
    const fetchDomains = async () => {
      const response = await DomainsAPI.getAllDomainsAtOnce('admin')
      setDomains(response.data)
    }

    fetchDomains()
  }, [setDomains])

  return (
    <div className="page-container page-thin-container">
      <Breadcrumb
        items={
          isCreation
            ? [
                { label: 'Avocats', link: '/admin/lawyers' },
                { label: 'Nouvel avocat' },
              ]
            : [
                { label: 'Avocats', link: '/admin/lawyers' },
                {
                  label: lawyer?.lastName ? lawyer.lastName : '',
                  link: lawyer?.lastName
                    ? `/admin/lawyers/${lawyer.id}/folders`
                    : null,
                },
                { label: 'Fiche avocat' },
              ]
        }
      />

      <h1 className="page-title">Fiche avocat</h1>

      <ExpertProfileForm
        expert={lawyer}
        domains={domains}
        isCreation={isCreation}
        apiMode="admin"
      />
      {params?.lawyerId && (
        <section className="page-section">
          <DocumentsGestion
            title="Documents liés à la page gestion"
            apiMode="admin"
            mode="experts"
            id={params.lawyerId}
          />
        </section>
      )}
    </div>
  )
}
