import APIClient from './APIClient'

export const getMessages = async (
  type,
  folderId,
  customerId,
  discussionId,
  apiMode,
  params
) => {
  let response
  switch (type) {
    case 'folder':
      response = await APIClient.get(
        `/api/${apiMode}/folders/${folderId}/history/messages`,
        { params: { ...params } }
      )
      break
    case 'customer':
      response = await APIClient.get(
        `/api/${apiMode}/customers/${customerId}/history/messages`,
        { params: { ...params } }
      )
      break
    case 'collaboration':
      response = await APIClient.get(
        `/api/${apiMode}/folders/${folderId}/collaboration-experts/messages`,
        { params: { ...params } }
      )
      break
    case 'discussion':
      response = await APIClient.get(
        `/api/${apiMode}/discussions/${discussionId}/messages`,
        { params: { ...params } }
      )
      break
    default:
      break
  }
  return response
}

export const getDiscussions = async (apiMode, params) => {
  return await APIClient.get(`/api/${apiMode}/discussions`, {
    params: { ...params },
  })
}

export const postMessage = async (data, type, apiMode) => {
  const content = data.content
  let response
  switch (type) {
    case 'folder':
      response = await APIClient.post(
        `/api/${apiMode}/folders/${data.folderId}/history/messages`,
        { content }
      )
      break
    case 'customer':
      response = await APIClient.post(
        `/api/${apiMode}/customers/${data.customerId}/history/messages`,
        { content }
      )
      break
    case 'collaboration':
      response = await APIClient.post(
        `/api/${apiMode}/folders/${data.folderId}/collaboration-experts/messages`,
        { content }
      )
      break
    case 'discussion':
      response = await APIClient.post(
        `/api/${apiMode}/discussions/${data.discussionId}/messages`,
        { content }
      )
      break
    default:
      break
  }
  return response
}
