import APIClient from '../../APIClient'

const CHRONOLOGIES_URI = '/api/admin/chronologies'

const getAllChronologies = async (page, search, sortParams) => {
  return await APIClient.get(CHRONOLOGIES_URI, {
    params: { page, search, ...sortParams },
  })
}

const getSubdomainChronologies = async (subdomainId, apiMode) => {
  return await APIClient.get(
    `/api/${apiMode}/subdomains/${subdomainId}/chronologies`
  )
}

const addChronologie = async (data) => {
  return await APIClient.post(CHRONOLOGIES_URI, data)
}

const getChronologieById = async (id) => {
  return await APIClient.get(CHRONOLOGIES_URI + `/${id}`)
}

const deleteChronologie = async (id) => {
  return await APIClient.delete(CHRONOLOGIES_URI + `/${id}`)
}

const patchChronologie = async (id, form) => {
  return await APIClient.patch(CHRONOLOGIES_URI + `/${id}`, form)
}

const addStepChronologie = async (chronologieId, data, apiMode) => {
  return await APIClient.post(
    `/api/${apiMode}/chronologies/${chronologieId}/steps`,
    data
  )
}

const duplicateChronologie = async (chronologieId) => {
  return await APIClient.patch(CHRONOLOGIES_URI + `/${chronologieId}/copy`)
}

const patchStepChronologie = async (stepId, data, apiMode) => {
  return await APIClient.patch(`/api/${apiMode}/steps/${stepId}`, data)
}

const deleteStepChronologie = async (id, apiMode) => {
  return await APIClient.delete(`/api/${apiMode}/steps/${id}`)
}

const getAllStepsTypes = async (apiMode) => {
  return await APIClient.get(`/api/${apiMode}/steps-types`)
}

const patchStartStep = async (stepId) => {
  return await APIClient.patch(`/api/expert/steps/${stepId}/start`)
}

const patchValidateStep = async (stepId) => {
  return await APIClient.patch(`/api/expert/steps/${stepId}/validate`)
}

const patchRefuseStep = async (stepId) => {
  return await APIClient.patch(`/api/expert/steps/${stepId}/refuse`)
}

const patchFinishStep = async (stepId) => {
  return await APIClient.patch(`/api/expert/steps/${stepId}/finish`)
}

const ChronologiesAPI = {
  getAllChronologies,
  getSubdomainChronologies,
  addChronologie,
  patchChronologie,
  getChronologieById,
  deleteChronologie,
  addStepChronologie,
  deleteStepChronologie,
  getAllStepsTypes,
  patchStepChronologie,
  duplicateChronologie,
  patchStartStep,
  patchValidateStep,
  patchRefuseStep,
  patchFinishStep,
}
export default ChronologiesAPI
