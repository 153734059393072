import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from '@Context/auth.js'
import { useParams } from 'react-router-dom'
import QMsg from '@CommonComponents/QMsg'
import { getErrorMsgFromSymfonyResponse } from '@Helpers'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'
import Password from '@Components/icons/Password'
import { InputAdornment } from '@material-ui/core'
import Email from '@Components/icons/Email'
import userAPI from '@API/user'

export default function FirstLoginForm() {
  const { login } = useAuth()

  const { token } = useParams()

  const [errorMsg, setErrorMsg] = useState(null)

  const { register, handleSubmit, control } = useForm()

  const onSubmit = async ({ email, plainPassword }) => {
    setErrorMsg(null)

    try {
      const { data } = await userAPI.postInitPassword({
        token,
        email,
        plainPassword,
      })
      login(data.token)
    } catch (error) {
      setErrorMsg(getErrorMsgFromSymfonyResponse(error.response))
    }
  }

  const onError = (errors) => {
    setErrorMsg(null) // Needed to reset the transition
    setErrorMsg(errors[Object.keys(errors)[0]].message)
  }

  return (
    <>
      <h2 className="Login-title">Première connexion</h2>

      <span className="Login-subtitle">
        Bienvenue sur votre espace sécurisé
      </span>

      <form onSubmit={handleSubmit(onSubmit, onError)} className="Login-form">
        <div>
          <QMuiFormInput
            containerStyle={{ width: '100%', marginBottom: 10 }}
            variant="firstLoginVariant"
            register={register}
            control={control}
            name="email"
            type="email"
            placeholder="Votre email"
            startAdornment={
              <InputAdornment position="start">
                <Email width={20} color="#0880aa" />
              </InputAdornment>
            }
          />
        </div>

        <div>
          <QMuiFormInput
            containerStyle={{ width: '100%', marginBottom: 10 }}
            variant="firstLoginVariant"
            register={register}
            control={control}
            name="plainPassword"
            type="password"
            placeholder="Votre mot de passe"
            color="#0880aa"
            startAdornment={
              <InputAdornment position="start">
                <Password width={20} color="#0880aa" />
              </InputAdornment>
            }
          />
          <div style={{ marginBottom: 10 }}>
            Votre mot de passe doit contenir au moins 8 caractères, 1 chiffre, 1
            lettre et 1 caractère spécial
          </div>
        </div>

        <div>
          <QMuiFormInput
            containerStyle={{ width: '100%', marginBottom: 10 }}
            variant="firstLoginVariant"
            register={register}
            control={control}
            name="passwordConfirmation"
            type="password"
            placeholder="Confirmation"
            color="#0880aa"
            startAdornment={
              <InputAdornment position="start">
                <Password width={20} color="#0880aa" />
              </InputAdornment>
            }
          />
        </div>

        <QMsg className="Login-validationMsg" variant="Danger">
          {errorMsg}
        </QMsg>

        <QMuiButton
          className="Login-submitBtn"
          type="submit"
          color="blue"
          size="large"
        >
          Connectez-vous
        </QMuiButton>
      </form>
    </>
  )
}
