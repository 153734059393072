import axios from 'axios'

if (process.env.REACT_APP_API_URL === undefined) {
  console.warn('Env variable REACT_APP_API_URL is not defined')
}

const APIClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

APIClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('auth_token')

  if (token != null) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

export default APIClient
