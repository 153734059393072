import React from 'react'
import { Button } from '@material-ui/core'
import classnames from 'classnames'

const colors = {
  primary: 'isPrimary',
  secondary: 'isSecondary',
  danger: 'isDanger',
  gray: 'isGray',
  white: 'isWhite',
  iconDanger: 'isIconDanger',
  transparent: 'isTransparent',
}

export default function QMuiButton({
  children,
  className,
  color,
  isIcon = false,
  variant = 'contained',
  ...props
}) {
  return (
    <Button
      variant={variant}
      classes={{
        root: classnames(
          'QMuiButton',
          className,
          colors[color] || colors['primary'],
          {
            isIcon: isIcon,
          }
        ),
        disabled: 'isDisabled',
        sizeLarge: 'isLarge',
        sizeSmall: 'isSmall',
        outlined: 'isOutlined',
      }}
      color="primary"
      {...props}
    >
      {children}
    </Button>
  )
}
