import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Header from '@Components/Header'
import MainMenu from '@Components/MainMenu'
import { motion } from 'framer-motion'
import ExpertFolders from './ExpertFolders'
import ExpertProfile from './ExpertProfile'
import ExpertMessages from './ExpertMessages'
import ExpertAccounting from './ExpertAccounting'
import ExpertFaq from './ExpertFaq'
import Folder from '@Components/folder/Folder'

export default function ExpertHome() {
  return (
    <motion.div exit={{ opacity: 0 }}>
      <Header />

      <div className="ExpertHome">
        <MainMenu />
        <div className="main-wrapper">
          <Switch>
            <Route path="/profile" exact>
              <ExpertProfile />
            </Route>

            <Route path="/folders" exact>
              <ExpertFolders />
            </Route>

            <Route path="/messages" exact>
              <ExpertMessages />
            </Route>

            <Route path="/accounting" exact>
              <ExpertAccounting />
            </Route>
            <Route path="/faq" exact>
              <ExpertFaq />
            </Route>

            <Route path="/folders/:folderId(\d+)">
              <Folder apiMode="expert" />
            </Route>

            <Route path="/" exact>
              <Redirect to={{ pathname: '/folders' }} />
            </Route>

            <Route>
              <div className="ExpertHome-404">
                <h1>404</h1>
                <p>Cette page n'existe pas.</p>
              </div>
            </Route>
          </Switch>
        </div>
      </div>
    </motion.div>
  )
}
