import { useEffect, useState } from 'react'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import { MoreHoriz as MoreHorizIcon, Add as AddIcon } from '@material-ui/icons'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import QTable from '@CommonComponents/QTable'
import { DialogActions, DialogContent } from '@material-ui/core'
import QMuiModal from '@CommonComponents/Mui/QMuiModal'

export default function DocumentTable({
  documents,
  columns,
  actions,
  isLoading,
  type,
}) {
  const [menuAnchor, setMenuAnchor] = useState(null)
  const [currentDoc, setCurrentDoc] = useState(null)
  const [finalColumns, setFinalColumns] = useState(columns)

  useEffect(() => {
    if (actions.type === 'menu') {
      setFinalColumns([
        ...columns,
        {
          headerName: ' ',
          field: 'action',
          disableClickEventBubbling: true,
          width: 80,
          renderCell: (props) => (
            <QMuiButton
              variant="outlined"
              color="transparent"
              isIcon
              onClick={(event) => openMenu(props.row, event.currentTarget)}
            >
              <MoreHorizIcon />
            </QMuiButton>
          ),
        },
      ])
    } else {
      setFinalColumns([
        ...columns,
        {
          headerName: ' ',
          field: 'id',
          disableClickEventBubbling: true,
          width: 250,
          renderCell: (props) =>
            actions?.items?.map((i, index) => (
              <QMuiButton
                key={index}
                color="secondary"
                onClick={() => i.onClick(props.value)}
              >
                <span className="mr-2">Ajouter un document</span>
                <AddIcon style={{ fontSize: 14 }} />
              </QMuiButton>
            )),
        },
      ])
    }
  }, [actions, setFinalColumns])

  const openMenu = (document, menuAnchor) => {
    setCurrentDoc(document)
    setMenuAnchor(menuAnchor)
  }

  const closeMenu = () => {
    setCurrentDoc(null)
    setMenuAnchor(null)
  }

  const [confirm, setConfirm] = useState(null)

  return (
    <>
      <QTable
        rows={documents}
        columns={finalColumns}
        loading={isLoading}
        noResultMsg="Aucun document"
        className={
          type === 'request'
            ? 'QTable-documents document-table-request'
            : 'QTable-documents'
        }
      />

      {actions?.type === 'menu' && (
        <Menu
          id="documents-menu"
          anchorEl={menuAnchor}
          keepMounted
          open={menuAnchor !== null}
          onClose={closeMenu}
          transformOrigin={{ vertical: -40, horizontal: 0 }}
        >
          {actions?.items?.map(({ label, onClick, confirm }, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                if (typeof onClick === 'function') {
                  onClick(currentDoc)
                }

                if (typeof confirm?.handleConfirmation === 'function') {
                  setConfirm({ ...confirm, args: [currentDoc] })
                }

                closeMenu()
              }}
            >
              {label}
            </MenuItem>
          ))}
        </Menu>
      )}

      <QMuiModal title="Confirmation" open={confirm !== null}>
        <DialogContent>
          <p style={{ marginBottom: 10 }}>{confirm?.msg}</p>
        </DialogContent>

        <DialogActions>
          <QMuiButton onClick={() => setConfirm(null)} color="danger">
            Annuler
          </QMuiButton>

          <QMuiButton
            type={type}
            onClick={() => {
              confirm?.handleConfirmation(...confirm.args)
              setConfirm(null)
            }}
          >
            {confirm?.btnText}
          </QMuiButton>
        </DialogActions>
      </QMuiModal>
    </>
  )
}
