import React from 'react'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import { Close as CloseIcon } from '@material-ui/icons'

function QFile({ color, className, children, file, onChange, ...props }) {
  const setFile = async (event) => {
    if (event.target.files) {
      const file = event.target.files[0]
      onChange(file)
    }
  }

  const clear = () => {
    onChange(null)
  }

  return (
    <div className="QFile">
      <QMuiButton
        component="label"
        className={className}
        color={color}
        {...props}
      >
        {children}
        <input type="file" onChange={setFile} />
      </QMuiButton>

      {file !== null && file !== undefined && (
        <div className="QFile-fileName">
          <span>{file?.name}</span>
          <button className="QFile-clearBtn" type="button" onClick={clear}>
            <CloseIcon />
          </button>
        </div>
      )}
    </div>
  )
}

export default QFile
