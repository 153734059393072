import React from 'react'

export default function Plus(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12.09 12.09"
    >
      <g>
        <path
          d="M6.363 5.708l.01-3.721a.317.317 0 00-.32-.32.325.325 0 00-.324.323l-.01 3.718-3.762-.001a.324.324 0 00.001.648h3.76l-.008 3.766a.318.318 0 00.32.32.325.325 0 00.323-.323l.011-3.76h3.748a.324.324 0 10-.001-.647zm0 0"
          fill="#fff"
          stroke="#fff"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}
