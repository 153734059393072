import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'

export default function QMuiLinkButton({ to, children, ...props }) {
  const CustomLink = useMemo(
    () =>
      React.forwardRef(({ children, ...linkProps }, ref) => (
        <Link ref={ref} to={to} {...linkProps}>
          {children}
        </Link>
      )),
    [to]
  )
  return (
    <QMuiButton {...props} component={CustomLink}>
      {children}
    </QMuiButton>
  )
}
