import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useAuth } from '@Context/auth'
import * as documentsAPI from '@API/admin/documents'
import { ReactComponent as Loader } from '@Assets/svg/loader.svg'
import variant from '@Variants'

export default function Downloader() {
  const { docId } = useParams()

  const { user } = useAuth()

  const [isLoading, setIsLoading] = useState(true)
  const [hasFailed, setHasFailed] = useState(false)

  useEffect(() => {
    const apiMode = user.role.endsWith('Admin')
      ? 'admin'
      : user.role === 'Client'
      ? 'customer'
      : 'expert'

    const fetch = async () => {
      try {
        const response = await documentsAPI.getDocumentBlob(docId, apiMode)
        window.location = URL.createObjectURL(response.data)
      } catch (error) {
        setHasFailed(true)
      }
      setIsLoading(false)
    }

    fetch()
  }, [docId, user])

  return (
    <div className="Downloader">
      <img className="Downloader-logo" src={variant.logoWhite} alt="Logo" />

      {isLoading && (
        <>
          <Loader />
          <h1>Téléchargement du fichier</h1>
        </>
      )}

      {hasFailed && <h1>Impossible de télécharger le document</h1>}
    </div>
  )
}
