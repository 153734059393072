import { useEffect, useState, createRef } from 'react'
import { createPortal } from 'react-dom'
import QAppearDown from '@CommonComponents/transitions/QAppearDown'
import Menu from '@CommonComponents/Menu'
import { useAuth } from '@Context/auth.js'

import {
  AccountCircle as AccountCircleIcon,
  ExitToApp as ExitToAppIcon,
} from '@material-ui/icons'

function HeaderProfileMenu() {
  const containerRef = createRef()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { logout, user } = useAuth()

  const profileMenu = user.role.endsWith('Admin')
    ? [
        {
          title: 'Déconnexion',
          icon: <ExitToAppIcon />,
          onClick: logout,
        },
      ]
    : [
        {
          title: 'Profil',
          icon: user?.image ? (
            <div
              className="avatar"
              style={{ backgroundImage: 'url(' + user.image + ')' }}
            />
          ) : (
            <AccountCircleIcon />
          ),
          linkTo: '/profile',
        },
        {
          title: 'Déconnexion',
          icon: <ExitToAppIcon />,
          onClick: logout,
        },
      ]

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [containerRef])

  return (
    <div ref={containerRef} className="HeaderProfileMenu">
      <button
        className="HeaderProfileMenu-toggleMenuBtn"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {user?.image ? (
          <div
            className="avatar"
            style={{ backgroundImage: 'url(' + user.image + ')' }}
          />
        ) : (
          <AccountCircleIcon />
        )}
      </button>

      <Profile
        isMenuOpen={isMenuOpen}
        user={user}
        profileMenu={profileMenu}
        setIsMenuOpen={setIsMenuOpen}
      />
    </div>
  )
}

function Profile({ isMenuOpen, user, profileMenu, setIsMenuOpen }) {
  return createPortal(
    <QAppearDown
      className="HeaderProfileMenu-appearDown"
      isActive={isMenuOpen}
      duration=".2s"
    >
      <div className="HeaderProfileMenu-menu">
        <div className="HeaderProfileMenu-user">
          <span>
            {user?.firstName} {user?.lastName}
          </span>
          <span>{user?.email}</span>
        </div>

        <Menu items={profileMenu} onItemClick={() => setIsMenuOpen(false)} />
      </div>
    </QAppearDown>,
    document.getElementById('modal-root')
  )
}

export default HeaderProfileMenu
