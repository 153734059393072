import React from 'react'
import QMuiFormInput from './QMuiFormInput'
import QTag from '../QTag'
import { Controller, useForm } from 'react-hook-form'

/**
 *
 * @param {*} props.register
 * @param {*} props.label
 * @param {*} props.watch
 * @param {*} props.name
 */
function FormInputTags({ tags = [], onChange, label, tagsProps, ...props }) {
  const { register, control, handleSubmit, setValue } = useForm()

  const handleUpdateOneTag = (index, newValue) => {
    const shallowCopy = [...tags]
    shallowCopy[index] = newValue
    onChange(shallowCopy)
  }

  const onSubmit = (props) => {
    if (props.tag.trim().length > 0) {
      const shallowCopy = [...tags]
      shallowCopy.push(props.tag)
      onChange(shallowCopy)
      setValue('tag', null)
    }
  }

  const handleDelete = (index) => {
    const shallowCopy = [...tags]
    shallowCopy.splice(index, 1)
    onChange(shallowCopy)
  }

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(onSubmit)()
      event.preventDefault()
    }
  }

  const onBlur = (event) => {
    handleSubmit(onSubmit)()
  }

  return (
    <div>
      <QMuiFormInput
        label={label}
        control={control}
        register={register}
        name="tag"
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        {...props}
      />
      {tags.length > 0 && (
        <div className="flex px-2 pb-2">
          {tags.map((tag, index) => (
            <QTag
              {...tagsProps}
              key={tag}
              index={index}
              handleUpdate={handleUpdateOneTag}
              handleDelete={handleDelete}
              tag={tag}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default function QMuiFormInputTags({ control, name, ...delegate }) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ onChange, value }) => {
        return <FormInputTags onChange={onChange} tags={value} {...delegate} />
      }}
    />
  )
}
