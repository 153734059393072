import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { AttachFile as AttachFileIcon } from '@material-ui/icons'

import formRules from '@Utils/form-rules'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import QMuiTextarea from '@CommonComponents/Mui/QMuiTextarea'
import QMuiSwitch from '@CommonComponents/Mui/QMuiSwitch'
import QMuiModal from '@CommonComponents/Mui/QMuiModal'
import QFile from '@CommonComponents/QFile'
import QSelect from '@CommonComponents/QSelect'
import {
  fileToBase64,
  formatSymfonyValidationErrors,
  getErrorMsgFromSymfonyResponse,
} from '@Helpers'
import { useSnackbar } from '@Context/snackbar'
import foldersAPI from '@API/admin/folders'
import * as documentsAPI from '@API/admin/documents'

export default function DocumentUploadForm({
  open,
  onClose,
  title,
  folderId,
  requestId,
  triggerReload,
  document = null,
  apiMode,
}) {
  const [docFile, setDocFile] = useState(null)

  const isEditing = document !== null

  const {
    register,
    control,
    handleSubmit,
    errors,
    clearErrors,
    setError,
    setValue,
  } = useForm()

  const [tags, setTags] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function fetchTags() {
      const { data } = await foldersAPI.getDocumentsTags(folderId, apiMode)
      setTags(data)
    }

    if (open) {
      fetchTags()
    }
  }, [open])

  useEffect(() => {
    if (document) {
      setTimeout(() => {
        for (let key in document) {
          setValue(key, document[key])
        }
      })
    }
  }, [document, setValue])

  useEffect(() => {
    if (!open) {
      setDocFile(null)
    }
  }, [open])

  const { addSnackbarMsg } = useSnackbar()

  const onSubmit = async (data) => {
    setLoading(true)
    if (!isEditing) {
      if (docFile === null) {
        addSnackbarMsg({
          severity: 'error',
          content: 'Vous devez choisir un fichier',
        })
        return
      }

      data.fileName = docFile.name
      data.fileContent = await fileToBase64(docFile)
    }

    if (apiMode === 'customer') {
      data.showToCustomer = true
    }

    if (requestId) {
      data.documentRequest = requestId
    }

    try {
      if (!isEditing) {
        await foldersAPI.postDocument(folderId, data, apiMode)
      } else {
        await documentsAPI.patchDocument(document.id, data, apiMode)
      }

      addSnackbarMsg({
        severity: 'success',
        content: `Document ${isEditing ? 'modifié' : 'envoyé'}`,
      })

      triggerReload()
      setLoading(false)
      onClose()
    } catch (error) {
      const resp = error.response

      if (resp.data.message === 'Validation Failed') {
        const formatedErrors = formatSymfonyValidationErrors(resp.data.errors)

        for (let key in formatedErrors) {
          setError(key, formatedErrors[key])
        }

        onError(formatedErrors)
      } else {
        addSnackbarMsg({
          severity: 'error',
          content: getErrorMsgFromSymfonyResponse(error.response),
        })
      }
      setLoading(false)
    }
  }

  const onError = (errors) => {
    for (let key in errors) {
      if (['fileName', 'fileContent'].includes(key)) {
        addSnackbarMsg({
          severity: 'error',
          content: errors[key].message,
        })
      }
    }
  }

  return (
    <QMuiModal open={open} title={title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col px-4">
          <QMuiFormInput
            className="form-field fullWidth"
            label="Nom du document"
            name="name"
            register={register({ ...formRules.isRequired })}
            control={control}
            errorMsg={errors?.name?.message}
            disabled={loading}
          />

          <QMuiTextarea
            className="form-field fullWidth"
            minRows={3}
            label="Description"
            name="description"
            register={register({ ...formRules.isRequired })}
            control={control}
            errorMsg={errors?.description?.message}
            disabled={loading}
          />

          <QSelect
            className="form-field fullWidth"
            label="Créer une catégorie de document"
            name="tags"
            creatable
            options={tags?.map((tag) => ({ label: tag, value: tag }))}
            isMulti
            control={control}
            disabled={loading}
          />

          {apiMode !== 'customer' && (
            <div className="flex">
              <label className="ml-auto">
                Visible par le client
                <QMuiSwitch
                  name="showToCustomer"
                  control={control}
                  disabled={loading}
                />
              </label>
            </div>
          )}

          {!isEditing && (
            <QFile
              color="secondary"
              file={docFile}
              onChange={(e) => {
                clearErrors()
                setDocFile(e)
              }}
              endIcon={<AttachFileIcon />}
            >
              Fichier
            </QFile>
          )}
        </div>

        <div className="flex p-4">
          <QMuiButton
            color="danger"
            className="ml-auto"
            onClick={onClose}
            disabled={loading}
          >
            Annuler
          </QMuiButton>

          <QMuiButton type="submit" className="ml-4" disabled={loading}>
            Envoyer
          </QMuiButton>
        </div>
      </form>
    </QMuiModal>
  )
}
