import React, { useState, useEffect } from 'react'
import { omit } from 'lodash'
import { useWatch } from 'react-hook-form'
import { FormControl, InputBase } from '@material-ui/core'
import classnames from 'classnames'
import QAppearDown from '@CommonComponents/transitions/QAppearDown'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'

const variants = {
  default: 'input',
  loginVariant: classnames('input', 'loginVariant'),
  firstLoginVariant: classnames('input', 'firstLoginVariant'),
}

export default function QMuiFormInput({
  className,
  label,
  name,
  register,
  control,
  defaultValue,
  containerStyle,
  variant = 'default',
  errorMsg,
  onBlur,
  ...props
}) {
  const [isFocus, setIsFocus] = useState(false)
  const [isEmpty, setIsEmpty] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  const hasErrors = typeof errorMsg === 'string'
  const watchValue = useWatch({ control, name })

  useEffect(() => {
    if (watchValue === '') {
      setIsEmpty(true)
    } else {
      setIsEmpty(false)
    }
  }, [watchValue])

  const onInputBlur = () => {
    setIsFocus(false)
    if (typeof onBlur === 'function') {
      onBlur()
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <FormControl
      style={containerStyle}
      className={classnames('form-control', className, {
        isError: hasErrors,
      })}
    >
      {label && (
        <label
          className={classnames('input-label', {
            isFocused: isFocus || !isEmpty,
          })}
        >
          {label}
          {props.type}
        </label>
      )}
      {props.type !== 'password' ? (
        <InputBase
          onFocus={() => setIsFocus(true)}
          onBlur={onInputBlur}
          inputRef={register}
          defaultValue={defaultValue}
          name={name}
          classes={{
            root: classnames(variants[variant], {
              isError: hasErrors,
            }),
          }}
          {...props}
        />
      ) : (
        <InputBase
          onFocus={() => setIsFocus(true)}
          onBlur={onInputBlur}
          inputRef={register}
          defaultValue={defaultValue}
          name={name}
          classes={{
            root: classnames(variants[variant], {
              isError: hasErrors,
            }),
          }}
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                style={{ color: props.color ? props.color : '#ffffff' }}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          {...omit(props, 'type')}
        />
      )}
      <QAppearDown isActive={hasErrors} duration=".3s">
        <span className="input-error-msg">{errorMsg}</span>
      </QAppearDown>
    </FormControl>
  )
}
