import APIClient from '../APIClient'

const USEFUL_INFORMATION_URI = '/api/admin/useful-information'
const QUOTE_WRITING_URI = '/api/admin/quote-writing'
const NOTES_URI = '/api/admin/notes-categories'
const DOMAINS_URI = '/api/admin/domains'

/**
 *
 * @param {*} payload
 * @param {string} payload.title
 * @param {string} payload.content
 */
export const setUsefulInformation = async (payload) => {
  return await APIClient.post(USEFUL_INFORMATION_URI, payload)
}

/**
 */
export const getUsefulInformationList = async (apiMode) => {
  return await APIClient.get(`/api/${apiMode}/useful-information`)
}

/**
 * @param {number} id
 * @param {*} payload
 * @param {string} payload.title
 * @param {string} payload.content
 */
export const patchUsefulInformation = async (id, payload) => {
  return await APIClient.patch(USEFUL_INFORMATION_URI + `/${id}`, payload)
}

/**
 */
export const getQuoteWritingList = async () => {
  return await APIClient.get(QUOTE_WRITING_URI)
}

/**
 * @param {number} id
 * @param {*} payload
 * @param {string} payload.title
 * @param {string} payload.content
 */
export const patchQuoteWriting = async (id, payload) => {
  return await APIClient.patch(QUOTE_WRITING_URI + `/${id}`, payload)
}

export const getAllNotes = async () => {
  return await APIClient.get(NOTES_URI)
}

export const deleleOneNote = async (id) => {
  return await APIClient.delete(NOTES_URI + `/${id}`)
}

export const addNote = async (payload) => {
  return await APIClient.post(NOTES_URI, payload)
}

export const patchNote = async (noteId, form) => {
  return await APIClient.patch(NOTES_URI + `/${noteId}`, form)
}

export const getAllDomains = async () => {
  return await APIClient.get(DOMAINS_URI)
}
