import React, { useState, useEffect } from 'react'
import { FormControl } from '@material-ui/core'
import ChronologiesAPI from '@API/admin/parameters/chronologies'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import DomainsAPI from '@API/admin/parameters/domains'
import QMuiButtonConfirmation from '@CommonComponents/Mui/QMuiButtonConfirmation'
import QSelect from '@CommonComponents/QSelect'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'
import { useSnackbar } from '@Context/snackbar'
import { getErrorMsgFromSymfonyResponse } from '@Helpers'

export default function ChronologyEditHeader({ chronology, apiMode }) {
  const { register, control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      domain: 1,
      subdomain: 1,
    },
  })
  const [domains, setDomains] = useState([])
  const [domainSelected, setDomainSelected] = useState({})
  const watchDomain = watch('domain')
  const watchName = watch('name')
  const history = useHistory()
  const { addSnackbarMsg } = useSnackbar()

  useEffect(() => {
    if ('id' in chronology) {
      setValue('domain', chronology.subdomain.domain.id)
      setValue('subdomain', chronology.subdomain.id)
      setValue('name', chronology.name)
    }
  }, [chronology, setValue])

  useEffect(() => {
    const domain = domains.find(({ id }) => id === watchDomain)
    if (domain !== -1 && domain !== undefined) setDomainSelected(domain)
  }, [domains, watchDomain])

  useEffect(() => {
    async function onMount() {
      const response = await DomainsAPI.getAllDomainsAtOnce(apiMode)
      setDomains(response.data)
    }
    onMount()
  }, [apiMode])

  const onSubmit = async ({ domain, ...data }) => {
    try {
      await ChronologiesAPI.patchChronologie(chronology.id, data) // TODO add loader and alert for UX
      addSnackbarMsg({
        severity: 'success',
        content: 'Chronologie modifiée',
      })
      history.push('/admin/parameters/chrono')
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <header className="ChronologyEdit-head">
        <div className="flex align-center flex-wrap">
          <FormControl classes={{ root: 'MuiFormControl-root' }}>
            <QSelect
              name="domain"
              label="Domaine"
              control={control}
              options={domains.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
            />
          </FormControl>
          <FormControl classes={{ root: 'MuiFormControl-root' }}>
            <QSelect
              name="subdomain"
              label="Sous-domaine"
              control={control}
              options={
                'id' in domainSelected
                  ? domainSelected.subdomains.map(({ id, name }) => ({
                      label: name,
                      value: id,
                    }))
                  : []
              }
            />
          </FormControl>
          <QMuiFormInput
            label="Nom"
            watch={watchName}
            register={register}
            control={control}
            name="name"
            style={{ width: 300 }}
          />
        </div>
        <div className="ml-auto mb-1">
          <QMuiButtonConfirmation
            btnText="Enregistrer"
            confirmation="Êtes-vous sûr de vouloir enregistrer ?"
            type="submit"
            variant="contained"
            color="primary"
          >
            Enregistrer
          </QMuiButtonConfirmation>
        </div>
      </header>
    </form>
  )
}
