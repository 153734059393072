import logo from '@Assets/img/qualy-avocats/logo-white.png'
import menuArrow from '@Assets/img/qualy-avocats/arrow-menu.svg'

export default {
  title: 'Qualy avocats',
  logoWhite: logo,
  headerSvgGradient: {
    left: '#0378a0',
    right: '#0a90be',
  },
  menuArrow: menuArrow,
  signinLink: 'https://www.qualy-avocats.fr/',
  cssVars: {
    '--color-alpha': '#0378a0',
    '--color-alpha-light': '#0490be',
    '--color-btn-alpha': 'linear-gradient(to right, #0378a0, #0a90be)',
    '--color-btn-alpha-dark': 'linear-gradient(to right, #037096, #0989b4)',
    '--login-bg': '#0880aa',
    '--header-bg': 'linear-gradient(to right, #0378a0, #0a90be)',
  },
}
