import { Link } from 'react-router-dom'

function Breadcrumb({ items }) {
  return (
    <ul className="Breadcrumb">
      {items.map((item, idx) => (
        <li key={idx} style={{ '--scale': 1 + idx / (items.length * 2) }}>
          {item.link ? (
            <Link to={item.link} className="Breadcrumb-item">
              {item.label}
            </Link>
          ) : (
            <span className="Breadcrumb-item">{item.label}</span>
          )}
        </li>
      ))}
    </ul>
  )
}

export default Breadcrumb
