import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { addNote } from '@API/admin/parameters'
import QMuiInput from '@CommonComponents/Mui/QMuiInput'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import QMuiModal from '@CommonComponents/Mui/QMuiModal'
import { SketchPicker } from 'react-color'

import { useSnackbar } from '@Context/snackbar'
import {
  formatSymfonyValidationErrors,
  getErrorMsgFromSymfonyResponse,
} from '@Helpers'

export default function CompanyUploadForm({ open, onSuccess, onClose }) {
  const { control, register, handleSubmit } = useForm()
  const [color, setColor] = useState('#000')
  const [showPicker, setShowPicker] = useState(false)
  const [loading, setLoading] = useState(false)
  const { addSnackbarMsg } = useSnackbar()

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      const response = await addNote({
        name: data.name,
        color,
      })
      setLoading(false)
      onSuccess(response.data)
    } catch (error) {
      setLoading(false)
      const resp = error.response
      if (resp.data.message === 'Validation Failed') {
      } else {
        addSnackbarMsg({
          severity: 'error',
          content: getErrorMsgFromSymfonyResponse(error.response),
        })
      }
    }
  }

  const handleChangeColor = (color) => {
    setColor(color.hex)
  }

  return (
    <QMuiModal open={open} title="Ajouter un type de note">
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset style={{ position: 'relative' }}>
          <QMuiInput
            name="name"
            placeholder="Saisir nom"
            className="form-input isFullWidth mb-2"
            inputRef={register}
            control={control}
            disabled={loading}
          />
          <div
            style={{
              position: 'absolute',
              top: 24,
              right: 24,
              width: 20,
              height: 20,
              borderRadius: 10,
              backgroundColor: color,
              cursor: 'pointer',
            }}
            onClick={() => setShowPicker(!showPicker)}
          />
        </fieldset>
        {showPicker && (
          <div className="flex align-items-center justify-center p-4">
            <SketchPicker color={color} onChangeComplete={handleChangeColor} />
          </div>
        )}
        <div className="flex p-4">
          <QMuiButton
            color="danger"
            className="ml-auto"
            onClick={onClose}
            disabled={loading}
          >
            Annuler
          </QMuiButton>
          <QMuiButton type="submit" className="ml-4" disabled={loading}>
            Envoyer
          </QMuiButton>
        </div>
      </form>
    </QMuiModal>
  )
}
