import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import particulierImg from '@Assets/img/particulier.png'
import proImg from '@Assets/img/professionnel.png'
import QMuiLinkButton from '@CommonComponents/Mui/QMuiLinkButton'
import QMuiInput from '@CommonComponents/Mui/QMuiInput'
import { InputAdornment } from '@material-ui/core'
import { ReactComponent as RightArrow } from '@Assets/svg/arrow-right.svg'
import { debounce } from 'lodash'
import SearchIcon from '@Components/icons/SearchIcon'
import Pagination from '@material-ui/lab/Pagination'
import * as expertAPI from '@API/expert/expert'

import QTable from '@Components/common/QTable'

export default function ExpertFolders() {
  const [search, setSearch] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [folders, setFolders] = useState([])
  const [sortParams, setSortParams] = useState(null)
  const [pageCount, setPageCount] = useState(1)

  const pageNum = parseInt(
    new URLSearchParams(useLocation().search).get('p') || 1
  )

  const history = useHistory()

  useEffect(() => {
    const fetchFolders = async () => {
      setIsLoading(true)
      const { data } = await expertAPI.getFolders(pageNum, search, sortParams)

      setFolders(data.results)
      setPageCount(Math.ceil(data.meta.total_items / data.meta.items_per_page))
      setIsLoading(false)
    }
    fetchFolders()
  }, [pageNum, search, sortParams])

  const debounceSearch = debounce(
    (e) => setSearch(e.target.value !== '' ? e.target.value : null),
    800
  )

  const columns = [
    {
      headerName: ' ',
      field: 'type',
      width: 50,
      renderCell: ({ value }) => {
        const isPro = value === 'professionnel'
        return (
          <img
            src={isPro ? proImg : particulierImg}
            alt={isPro ? 'Professionnel' : 'Particulier'}
            style={{ minWidth: '3rem', minHeight: '3rem' }}
          />
        )
      },
      style: {
        width: '5rem',
      },
    },
    {
      headerName: 'N°',
      field: 'folderNumber',
      width: 100,
    },
    {
      headerName: 'Nom du dossier',
      field: 'name',
      sortField: 'folder.name',
      sortable: true,
      minWidth: 150,
      renderCell: ({ value }) => (
        <span className="two-line-elipsis">{value}</span>
      ),
    },
    {
      headerName: 'Domaine',
      field: 'subdomain',
      sortField: 'subdomain.name',
      sortable: true,
      minWidth: 150,
      renderCell: ({ value }) => (
        <>
          <strong>{value.name}</strong>
          <br />
          <span>{value.domain.name}</span>
        </>
      ),
    },
    {
      headerName: 'Avocat référent',
      field: 'referent.name',
      width: 150,
      sortable: true,
    },
    {
      headerName: 'Avocats experts',
      field: 'experts',
      sortable: false,
      width: 130,
      renderCell: ({ value }) => {
        if (value.length === 0) {
          return ''
        }

        if (value.length === 1) {
          return `${value[0].firstName} ${value[0].lastName}`
        }

        return (
          <div>
            <Tooltip
              title={value
                .map((e) => `${e.firstName} ${e.lastName}`)
                .join(', ')}
              arrow
              classes={{ tooltip: 'tooltip' }}
            >
              <span className="badge">{value.length}</span>
            </Tooltip>
          </div>
        )
      },
    },
    {
      headerName: 'Localisation',
      field: 'localisation',
      sortable: false,
      width: 130,
    },
    {
      headerName: '',
      field: ' ',
      width: 100,
      style: {
        textAlign: 'right',
      },
      renderCell: (params) => (
        <QMuiLinkButton
          to={`/folders/${params.row.id}`}
          variant="text"
          color="transparent"
        >
          <RightArrow width={20} />
        </QMuiLinkButton>
      ),
    },
  ]

  return (
    <div className="page-container">
      <h1 className="page-title">Mes dossiers</h1>

      <header className="flex justify-end mb-4">
        <QMuiInput
          onChange={debounceSearch}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon style={{ width: 24, height: 24 }} />
            </InputAdornment>
          }
        />
      </header>

      <QTable
        className="QTable-folders"
        columns={columns}
        rows={folders}
        isLoading={isLoading}
        sort={sortParams}
        setSort={setSortParams}
        onRowClick={(row) => {
          history.push('/folders/' + row.id)
        }}
      />

      <div className="flex">
        <Pagination
          className="ml-auto mb-6"
          page={pageNum}
          count={pageCount}
          onChange={(event, page) => {
            history.push({
              search: `?p=${page}`,
            })
          }}
        />
      </div>
    </div>
  )
}
