import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import QMuiModal from '@CommonComponents/Mui/QMuiModal'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'
import { DialogActions, DialogContent, FormControl } from '@material-ui/core'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import DomainAPI from '@API/admin/parameters/domains'
import formRules from '@Utils/form-rules'
import {
  formatSymfonyValidationErrors,
  getErrorMsgFromSymfonyResponse,
} from '@Helpers'
import { useSnackbar } from '@Context/snackbar'

export default function AddDomainModalForm({
  handleClose,
  open,
  handleNewDomain,
}) {
  const { register, control, handleSubmit, reset, errors, setError } = useForm()
  const { addSnackbarMsg } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const onSubmit = async (form) => {
    try {
      setLoading(true)
      const { data } = await DomainAPI.addDomain(form.name)
      reset()
      handleNewDomain(data)
      setLoading(false)
      handleClose()
    } catch (error) {
      const resp = error.response

      if (resp.data.message === 'Validation Failed') {
        const formatedErrors = formatSymfonyValidationErrors(resp.data.errors)

        for (let key in formatedErrors) {
          setError(key, formatedErrors[key])
        }
      } else {
        addSnackbarMsg({
          severity: 'error',
          content: getErrorMsgFromSymfonyResponse(error.response),
        })
      }
      setLoading(false)
    }
  }

  return (
    <QMuiModal open={open} title="Ajouter un domaine" onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent
          style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}
        >
          <FormControl>
            <QMuiFormInput
              name="name"
              label="Nom"
              register={register({ ...formRules.isRequired })}
              control={control}
              errorMsg={errors?.name?.message}
              disabled={loading}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <QMuiButton color="danger" onClick={handleClose} disabled={loading}>
            Annuler
          </QMuiButton>
          <QMuiButton color="primary" type="submit" disabled={loading}>
            Ajouter
          </QMuiButton>
        </DialogActions>
      </form>
    </QMuiModal>
  )
}
