import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Header from '@Components/Header'
import MainMenu from '@Components/MainMenu'
import { motion } from 'framer-motion'
import CustomerProfile from './CustomerProfile'
import CustomerMessages from './CustomerMessages'
import CustomerFolders from './CustomerFolders'
import CustomerAccounting from './CustomerAccounting'
import CustomerFaq from './CustomerFaq'
import Folder from '@Components/folder/Folder'

export default function CustomerHome() {
  return (
    <motion.div exit={{ opacity: 0 }}>
      <Header />

      <div className="CustomerHome">
        <MainMenu />
        <div className="main-wrapper">
          <Switch>
            <Route path="/folders" exact>
              <CustomerFolders />
            </Route>
            <Route path="/folders/:folderId(\d+)">
              <Folder apiMode="customer" />
            </Route>
            <Route path="/messages" exact>
              <CustomerMessages />
            </Route>
            <Route path="/profile" exact>
              <CustomerProfile />
            </Route>
            <Route path="/accounting" exact>
              <CustomerAccounting />
            </Route>
            <Route path="/faq" exact>
              <CustomerFaq />
            </Route>
            <Route path="/" exact>
              <Redirect to={{ pathname: '/folders' }} />
            </Route>
            <Route>
              <div className="CustomerHome-404">
                <h1>404</h1>
                <p>Cette page n'existe pas.</p>
              </div>
            </Route>
          </Switch>
        </div>
      </div>
    </motion.div>
  )
}
