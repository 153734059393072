import QMuiInput from '@CommonComponents/Mui/QMuiInput'
import { debounce } from 'lodash'
import SearchIcon from '@Components/icons/SearchIcon'
import { InputAdornment } from '@material-ui/core'

export default function DiscussionForm({ onSearch }) {
  const debounceSearch = debounce((e) => {
    onSearch(e.target.value !== '' ? e.target.value : null)
  }, 800)

  return (
    <div className="discussions-form">
      <QMuiInput
        onChange={debounceSearch}
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon style={{ width: 24, height: 24 }} />
          </InputAdornment>
        }
      />
    </div>
  )
}
