import APIClient from '../APIClient'
import FileSaver from 'file-saver'
import Mime from 'mime'

const getAll = async (page, search, sortParams) => {
  return await APIClient.get('/api/admin/folders', {
    params: { page, search, ...sortParams },
  })
}

const getOne = async (folderId, apiMode) => {
  return await APIClient.get(`/api/${apiMode}/folders/${folderId}`)
}

const getNotes = async (folderId, apiMode) => {
  return await APIClient.get(`/api/${apiMode}/folders/${folderId}/notes`)
}

const postNote = async (folderId, data, apiMode) => {
  return await APIClient.post(`/api/${apiMode}/folders/${folderId}/notes`, data)
}

const patchNote = async (noteId, data, apiMode) => {
  return await APIClient.patch(`/api/${apiMode}/notes/${noteId}`, data)
}

const deleteNote = async (noteId, apiMode) => {
  return await APIClient.delete(`/api/${apiMode}/notes/${noteId}`)
}

const getAllNotesCategories = async (apiMode) => {
  return await APIClient.get(`/api/${apiMode}/notes-categories/all`)
}

const patch = async (folderId, data) => {
  return await APIClient.patch(`/api/admin/folders/${folderId}`, data)
}

const getSynthesisForOne = async (folderId, apiMode) => {
  return await APIClient.get(`/api/${apiMode}/folders/${folderId}/syntheses`)
}

const postSynthesis = async (folderId, data, apiMode) => {
  return await APIClient.post(
    `/api/${apiMode}/folders/${folderId}/syntheses`,
    data
  )
}

const patchSynthesis = async (synthesisId, data, apiMode) => {
  return await APIClient.patch(`/api/${apiMode}/syntheses/${synthesisId}`, data)
}

const deleteSynthesis = async (synthesisId, apiMode) => {
  return await APIClient.delete(`/api/${apiMode}/syntheses/${synthesisId}`)
}

const getChronology = async (folderId, apiMode) => {
  return await APIClient.get(`/api/${apiMode}/folders/${folderId}/chronologies`)
}

const postChronology = async (folderId, data, apiMode) => {
  return await APIClient.post(
    `/api/${apiMode}/folders/${folderId}/chronologies`,
    data
  )
}

const getDocuments = async (folderId, type, search, apiMode) => {
  return APIClient.get(`/api/${apiMode}/folders/${folderId}/documents`, {
    params: { type, search },
  })
}

const postDocument = async (folderId, data, apiMode) => {
  return APIClient.post(`/api/${apiMode}/folders/${folderId}/documents`, data)
}

const getDocumentsTags = async (folderId, apiMode) => {
  return APIClient.get(`/api/${apiMode}/folders/${folderId}/documents/tags`)
}

const getDocumentRequests = async (folderId, targetType, apiMode) => {
  return APIClient.get(
    `/api/${apiMode}/folders/${folderId}/documents-requests`,
    {
      params: { targetType },
    }
  )
}

const postDocumentRequest = async (folderId, data, apiMode) => {
  return APIClient.post(
    `/api/${apiMode}/folders/${folderId}/documents-requests`,
    data
  )
}

const patchValidateDocumentRequest = async (docReqId, apiMode) => {
  return APIClient.patch(
    `/api/${apiMode}/documents-requests/${docReqId}/validate`
  )
}

const patchRemindDocumentRequest = async (docReqId, apiMode) => {
  return APIClient.patch(
    `/api/${apiMode}/documents-requests/${docReqId}/remind`
  )
}

const deleteDocumentRequest = async (docReqId, apiMode) => {
  return APIClient.delete(`/api/${apiMode}/documents-requests/${docReqId}`)
}

const getWithToken = async (token) => {
  return APIClient.get(`/api/public/folders/token/${token}`)
}

const downloadQuoteWithToken = async (token) => {
  const resp = await APIClient.get(`/api/public/folders/token/${token}/quote`, {
    responseType: 'blob',
  })

  const fileName = 'devis'

  FileSaver.saveAs(
    resp.data,
    `${fileName}.${Mime.getExtension(resp.headers['content-type'])}`
  )
}

const validateWithToken = async (token) => {
  return APIClient.patch(`/api/public/folders/token/${token}/validate`)
}

const refuseWithToken = async (token) => {
  return APIClient.patch(`/api/public/folders/token/${token}/refuse`)
}

const FoldersApi = {
  getAll,
  getOne,
  patch,
  getNotes,
  postNote,
  patchNote,
  deleteNote,
  getAllNotesCategories,
  getSynthesisForOne,
  postSynthesis,
  patchSynthesis,
  deleteSynthesis,
  getChronology,
  postChronology,
  getDocuments,
  postDocument,
  getDocumentsTags,
  getDocumentRequests,
  postDocumentRequest,
  patchValidateDocumentRequest,
  patchRemindDocumentRequest,
  deleteDocumentRequest,
  getWithToken,
  downloadQuoteWithToken,
  validateWithToken,
  refuseWithToken,
}

export default FoldersApi
