import SimplyEditor from '@Components/SimplyEditor'
import React, { useEffect, useState } from 'react'
import * as APIParameters from '@API/admin/parameters'
import { motion } from 'framer-motion'
import { useSnackbar } from '@Context/snackbar'
import { getErrorMsgFromSymfonyResponse } from '@Helpers'

export default function QuoteWriting() {
  const [quoteWriting, setQuoteWriting] = useState(null)
  const { addSnackbarMsg } = useSnackbar()

  // mounted
  useEffect(() => {
    async function asyncData() {
      try {
        const { data = {} } = await APIParameters.getQuoteWritingList()
        if (data.results.length > 0) {
          setQuoteWriting(data.results[0])
        }
      } catch (e) {
        // TODO HANDLE ERROR
      }
    }
    asyncData()
  }, [])

  const onSubmit = async (content) => {
    try {
      await APIParameters.patchQuoteWriting(quoteWriting.id, content)
      addSnackbarMsg({
        severity: 'success',
        content: 'Les informations ont bien été sauvegardés',
      })
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2, ease: 'easeInOut' }}
    >
      <h1 className="page-title">Explications pour la rédaction d'un devis</h1>
      <SimplyEditor initialPayload={quoteWriting} onSubmit={onSubmit} />
    </motion.div>
  )
}
