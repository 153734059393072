import { Link } from 'react-router-dom'
import classnames from 'classnames'
import HeaderProfileMenu from './HeaderProfileMenu'
import variant from '@Variants'
import SvgWaveHeader from '@Components/icons/WaveHeader'

function Header({ isMenuToggle = false, isMenuOpen, onMenuOpen }) {
  return (
    <div className="Header">
      <SvgWaveHeader className="Header-wave" />

      <div className="Header-container">
        {isMenuToggle && (
          <button
            className={classnames('Header-menuBtn burger-button', {
              'is-active is-opened': isMenuOpen,
            })}
            onClick={onMenuOpen}
          >
            <i></i>
          </button>
        )}

        <Link to="/" className="Header-logo">
          <img src={variant.logoWhite} alt="Logo" />
        </Link>

        <HeaderProfileMenu />
      </div>
    </div>
  )
}

export default Header
