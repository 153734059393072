import React, { useState, useEffect } from 'react'
import ChatForm from '@Components/ChatForm'
import ChatMessage from '@Components/ChatMessage'
import InfiniteScroll from '@Components/InfiniteScroll'
import { getMessages } from '@API/messages'

const PER_PAGE = 10

function Chat({
  title,
  folder,
  discussion,
  type,
  mode,
  placeholder,
  apiMode,
  permissions,
  toggleRefresh,
}) {
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [hasMore, setHasMore] = useState(false)
  const [page, setPage] = useState(1)
  const [reload, setReload] = useState(true)
  const [slideTo, setSlideTo] = useState(null)

  // May be folder.id when used in LawyerCollaboration or discussion.id elsewhere
  const [currentEntityId, setCurrentEntityId] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const lastMsgId = items.length > 0 ? items[items.length - 1].id : Infinity

      let isNewDiscussion = !currentEntityId

      if (!isNewDiscussion) {
        if (discussion) {
          isNewDiscussion = currentEntityId !== discussion.id
        } else if (folder) {
          isNewDiscussion = currentEntityId !== folder.id
        }
      }

      setCurrentEntityId(discussion?.id || folder.id)

      if (isNewDiscussion) {
        setLoading(true)
        setItems([])
        setPage(1)
        setReload(true)
      }

      const resp = await getMessages(
        type,
        folder?.id,
        folder?.customer?.id,
        discussion?.id,
        apiMode,
        { page: 1 }
      )

      setTotalItems(resp.data.meta.total_items)

      if (isNewDiscussion) {
        setItems(resp.data.results.reverse())
        setLoading(false)
      } else if (resp.data.results[0]?.id !== lastMsgId) {
        const messages = resp.data.results.filter((i) => i.id > lastMsgId)
        const newItems = [...items, ...messages.reverse()]
        setItems(newItems)
        setSlideTo(newItems.length)
      }
    }

    if (discussion || folder?.id) {
      fetchData()
    }
  }, [discussion?.id, discussion?.lastMessage?.id, toggleRefresh])

  useEffect(() => {
    if (PER_PAGE * page < totalItems) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }
  }, [page, totalItems])

  const loadItems = async (currentPage) => {
    setLoading(true)
    setPage(currentPage)
    setReload(false)

    const resp = await getMessages(
      type,
      folder?.id,
      folder?.customer?.id,
      discussion?.id,
      apiMode,
      { page: currentPage }
    )

    const firstMsgId = items.length > 0 ? items[0].id : Infinity
    const messages = resp.data.results.filter((i) => i.id < firstMsgId)
    setItems((current) => [...messages.reverse(), ...current])
    setLoading(false)
  }

  const addItem = (item) => {
    const itemLength = items.length
    setTotalItems(totalItems + 1)
    setItems((current) => [...current, item])
    setSlideTo(itemLength)
  }

  return (
    <div className={`chat chat-${type}`}>
      <h2 className="chat-title">{title}</h2>
      <div className="chat-wrapper">
        <div className="chat-inner">
          <InfiniteScroll
            loading={loading}
            inverse={true}
            hasMore={hasMore}
            fetch={loadItems}
            spaceBetween={15}
            reload={reload}
            page={page}
            setPage={setPage}
            slideTo={slideTo}
          >
            {items.map((item, index) => {
              return (
                <ChatMessage
                  key={item.id}
                  content={item.content}
                  createdBy={item.createdBy}
                  createdAt={item.createdAt}
                  mode={mode}
                  folder={discussion?.folder}
                />
              )
            })}
          </InfiniteScroll>
        </div>
        {permissions === 'rw' && (
          <ChatForm
            type={type}
            apiMode={apiMode}
            folder={folder}
            discussion={discussion}
            placeholder={placeholder}
            onAdd={addItem}
          />
        )}
      </div>
    </div>
  )
}

export default Chat
