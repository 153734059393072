import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import QAppearDown from '@CommonComponents/transitions/QAppearDown'

function QMsg({ variant = 'Primary', className, children }) {
  const style = classnames('QMsg', `is${variant}`, className)

  return (
    <QAppearDown
      isActive={children !== null && children !== undefined}
      duration=".3s"
    >
      <span className={style}>{children}</span>
    </QAppearDown>
  )
}

QMsg.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}

export default QMsg
