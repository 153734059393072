import React from 'react'

export default function Folders() {
  return (
    <svg
      className="folder"
      xmlns="http://www.w3.org/2000/svg"
      width="20.093"
      height="17.063"
    >
      <g dataname="Group 63">
        <path
          dataname="Path 300"
          d="M11.888 5.232L8.928.494A.531.531 0 008.481.25h-7.7A.531.531 0 00.25.78v15.5a.531.531 0 00.531.531h18.532a.531.531 0 00.531-.531V3.79a.531.531 0 00-.531-.531h-6.4a.531.531 0 00.009 1.061h5.862v11.432H1.312V4.34h7.29a.454.454 0 00.009-.905h-7.3V1.312h6.878l2.752 4.439a.567.567 0 00.779.146.56.56 0 00.168-.665z"
          fill="#005a79"
          stroke="#005a79"
          strokeWidth=".5"
        />
      </g>
    </svg>
  )
}
