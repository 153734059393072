import { DataGrid } from '@material-ui/data-grid'
import Pagination from '@material-ui/lab/Pagination'
import { useState, useRef } from 'react'
import { getCSSHeight } from '@Helpers'
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'

const localeText = {
  columnMenuLabel: 'Menu',
  columnMenuShowColumns: 'Montrer les colonnes',
  columnMenuFilter: 'Filtrer',
  columnMenuHideColumn: 'Cacher',
  columnMenuUnsort: 'Enlever le tri',
  columnMenuSortAsc: 'Trier par ordre croissant',
  columnMenuSortDesc: 'Trier par ordre décroissant',
  footerPaginationRowsPerPage: 'Lignes par page :',
}

function formatRows(columns, rows) {
  return rows.map((row) => {
    const realRow = {
      id: row.id,
    }

    columns.forEach((col) => {
      realRow[col.field] = get(row, col.field, null)
    })

    return realRow
  })
}

function formatSortParams(sortModelPrefix, sortModel) {
  const sort = sortModel?.[0]

  if (!sort) {
    return null
  }

  let fieldPath = sort.field.split('.')
  if (fieldPath.length === 1) {
    fieldPath = [sortModelPrefix, ...fieldPath]
  }

  const field = fieldPath.reverse().slice(0, 2).reverse().join('.')

  return {
    sort: field,
    direction: sort.sort,
  }
}

function QMuiDataGrid({
  containerStyle,
  page,
  handlePaginationChange,
  count,
  columns,
  rows,
  sortModelPrefix,
  noResultMsg,
  onSortParams = () => {},
  loading,
  onCellClick,
  ...props
}) {
  const [height, setHeight] = useState(0)
  const gridRef = useRef()
  const history = useHistory()

  const onPageChange = () => {
    window.requestAnimationFrame(() => {
      setHeight(getCSSHeight(gridRef.current))
    })
  }

  const formatedRows = formatRows(columns, rows)

  const onSortModelChange = ({ sortModel }) => {
    onSortParams(formatSortParams(sortModelPrefix, sortModel))
  }

  return (
    <div className="QMuiDataGrid">
      <DataGrid
        {...props}
        columns={columns}
        rows={formatedRows}
        ref={gridRef}
        autoHeight={true}
        disableColumnMenu={true}
        loading={loading}
        hideFooterSelectedRowCount={true}
        hideFooterRowCount={true}
        hideFooterPagination={true}
        localeText={localeText}
        disableExtendRowFullWidth={true}
        onPageChange={onPageChange}
        onSortModelChange={onSortModelChange}
        onCellClick={onCellClick}
      />

      {!loading && formatedRows.length === 0 && (
        <span className="QMuiDataGrid-noResults">{noResultMsg}</span>
      )}

      {page && (
        <Pagination
          className="ml-auto mb-6"
          page={page}
          count={count}
          onChange={handlePaginationChange}
        />
      )}
    </div>
  )
}

export default QMuiDataGrid
