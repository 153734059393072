import { useState, useEffect } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import {
  KeyboardTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { AccessTime as AccessTimeIcon } from '@material-ui/icons'
import LuxonAdapter from '@date-io/luxon'
import classnames from 'classnames'

class CustomLuxonAdapter extends LuxonAdapter {
  getDatePickerHeaderText(date) {
    return this.format(date, 'cccc d MMM')
  }
}

export default function QMuiTimePicker({
  name,
  label,
  type = 'date',
  className,
  control,
  rules,
  ...props
}) {
  const [isFocus, setIsFocus] = useState(false)
  const [isEmpty, setIsEmpty] = useState(true)

  const commonProps = {
    inputVariant: 'outlined',
    ampm: false,
    okLabel: 'Ok',
    cancelLabel: 'Annuler',
    invalidDateMessage: 'Date invalide',
    onFocus: () => setIsFocus(true),
    onBlur: () => setIsFocus(false),
  }

  const watchValue = useWatch({ control, name })

  useEffect(() => {
    if (watchValue === null) {
      if (!isEmpty) {
        setIsEmpty(true)
      }
    } else {
      if (isEmpty) {
        setIsEmpty(false)
      }
    }
  }, [watchValue, isEmpty])

  return (
    <div
      className={classnames('QMuiTimePicker', className, {
        isTime: type === 'time',
      })}
    >
      {label && (
        <label
          className={classnames('input-label', {
            isFocused: isFocus || !isEmpty,
          })}
        >
          {label}
        </label>
      )}

      <MuiPickersUtilsProvider utils={CustomLuxonAdapter} locale="fr">
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={null}
          render={({ onChange, value }) => {
            return type === 'date' ? (
              <KeyboardDatePicker
                value={value}
                onChange={onChange}
                format="dd/MM/yyyy"
                InputAdornmentProps={{ position: 'start' }}
                {...commonProps}
                {...props}
              />
            ) : (
              <KeyboardTimePicker
                value={value}
                onChange={onChange}
                keyboardIcon={<AccessTimeIcon />}
                InputAdornmentProps={{ position: 'start' }}
                {...commonProps}
                {...props}
              />
            )
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}
