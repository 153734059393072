import { useState, useEffect } from 'react'

export function useMatchMedia(query) {
  const mediaQuery = window.matchMedia(query)
  const [isMatching, setIsMatching] = useState(() => mediaQuery.matches)

  useEffect(() => {
    const listener = (event) => setIsMatching(event.matches)

    mediaQuery.addListener(listener)

    return () => mediaQuery.removeListener(listener)
  })

  return isMatching
}

export function useToggle(initial) {
  const [value, setValue] = useState(initial)

  return [value, () => setValue(!value)]
}
