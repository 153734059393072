import { useState, useEffect } from 'react'
import classnames from 'classnames'

import EditFileIcon from '@Components/icons/EditFile'

export default function DocumentTags({ tags, onSelect }) {
  const [selected, setSelected] = useState([])

  const onClick = (tag) => {
    if (selected.includes(tag)) {
      setSelected(selected.filter((t) => t !== tag))
    } else {
      setSelected([...selected, tag])
    }
  }

  useEffect(() => {
    onSelect(selected)
  }, [selected, onSelect])

  return (
    <ul className="DocumentTags">
      {tags.map((tag) => (
        <li key={tag}>
          <button
            className={classnames('DocumentTags-tag', {
              isSelected: selected.includes(tag),
            })}
            onClick={() => onClick(tag)}
          >
            <EditFileIcon />
            <span className="my-auto">{tag}</span>
          </button>
        </li>
      ))}
    </ul>
  )
}
