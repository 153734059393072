import React, { createContext, useContext, useReducer, useEffect } from 'react'
import axios from 'axios'
import jwt_decode from 'jwt-decode'

const initialState = {
  isReady: false,
  token: null,
  user: null,
}

const AuthContext = createContext(initialState)

async function fetchUser(token) {
  const { roles } = jwt_decode(token)

  const mode = roles.includes('ROLE_EXPERT')
    ? 'expert'
    : roles.includes('ROLE_SUPER_ADMIN') || roles.includes('ROLE_ADMIN')
    ? 'admin'
    : 'customer'

  const url = `${process.env.REACT_APP_API_URL}/api/${mode}/profile`

  return axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => data)
    .catch(() => null)
}

function useProvideAuth() {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'set_ready':
        return { ...state, isReady: true }
      case 'login':
        return { ...state, token: action.token, user: action.user }
      case 'set_user':
        return { ...state, user: action.user }
      case 'logout':
        return { ...state, token: null, user: null }
      default:
        throw new Error()
    }
  }, initialState)

  useEffect(() => {
    async function init() {
      try {
        const token = localStorage.getItem('auth_token')

        if (token) {
          const user = await fetchUser(token)

          if (user) {
            dispatch({ type: 'login', token, user })
          }
        }
      } catch {
        localStorage.removeItem('auth_token')
      }

      dispatch({ type: 'set_ready' })
    }

    init()
  }, [])

  async function login(token) {
    const user = await fetchUser(token)
    if (user) {
      dispatch({ type: 'login', token, user })
      localStorage.setItem('auth_token', token)
    }
  }

  function logout() {
    dispatch({ type: 'logout' })
    localStorage.removeItem('auth_token')
  }

  function setUser(user) {
    dispatch({ type: 'set_user', user })
  }

  return {
    isReady: state.isReady,
    isLogged: state.token !== null,
    token: state.token,
    user: state.user,
    login,
    logout,
    setUser,
  }
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export function useAuth() {
  return useContext(AuthContext)
}
