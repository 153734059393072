import APIClient from './APIClient'

// GET TOKEN
const postLogin = async (credentials) => {
  return APIClient.post('/api/login_check', credentials)
}

const getAdminProfile = async () => {
  return APIClient.get('/api/admin/profile')
}

const getExpertProfile = async () => {
  return APIClient.get('/api/expert/profile')
}

const postInitPassword = async (data) => {
  return APIClient.post('/api/security/initialize-password', data)
}

const forgotPassword = async (email) => {
  return APIClient.post('/api/security/forgot-password', {
    email,
  })
}

const resetPassword = async (token, plainPassword) => {
  return APIClient.post('/api/security/reset-password', {
    token,
    plainPassword,
  })
}

const getAdministrators = async (page, sortParams, search) => {
  return await APIClient.get('/api/admin/administrators', {
    params: { page, search, ...sortParams },
  })
}

const getAdministratorById = async (userId) => {
  return APIClient.get(`/api/admin/administrators/${userId}`)
}

const postAdministrator = async (data) => {
  return APIClient.post('/api/admin/administrators', data)
}

const patchAdministrator = async (id, data) => {
  return APIClient.patch(`/api/admin/administrators/${id}`, data)
}

const deleteAdministrator = async (id) => {
  return APIClient.delete(`/api/admin/administrators/${id}`)
}

const UserApi = {
  postLogin,
  getAdminProfile,
  getExpertProfile,
  postInitPassword,
  forgotPassword,
  resetPassword,
  getAdministrators,
  getAdministratorById,
  postAdministrator,
  patchAdministrator,
  deleteAdministrator,
}

export default UserApi
