import React from 'react'

export default function Parameters() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.486" height="19.397">
      <path
        dataname="Path 9541"
        d="M17.236 12.08a.517.517 0 00-.026-.16V3.236c0-1.96-4.266-2.986-8.48-2.986S.25 1.276.25 3.236v12.926c0 1.96 4.266 2.986 8.48 2.986s8.48-1.026 8.48-2.986V12.34a1.326 1.326 0 00.026-.259zM1.276 4.739c1.517.978 4.5 1.482 7.454 1.482a22.9 22.9 0 004.436-.408.513.513 0 10-.2-1.006 21.872 21.872 0 01-4.233.388 19.289 19.289 0 01-5.482-.7c-1.362-.423-1.972-.937-1.972-1.258s.61-.835 1.972-1.258a21.777 21.777 0 0110.963 0c1.362.423 1.972.937 1.972 1.258v4.31c0 .322-.61.836-1.972 1.258a21.776 21.776 0 01-10.963 0c-1.362-.423-1.972-.937-1.972-1.258zm12.936 12.677a21.777 21.777 0 01-10.962 0c-1.362-.423-1.972-.937-1.972-1.258V9.046c1.517.978 4.5 1.482 7.454 1.482s5.937-.5 7.454-1.482v3.152c-.128.32-.744.764-1.946 1.137a21.788 21.788 0 01-10.963 0 .513.513 0 10-.3.98 20.338 20.338 0 005.786.747c2.939 0 5.9-.5 7.428-1.466v2.562c0 .321-.61.836-1.972 1.258zm0 0"
        fill="#005a79"
        stroke="#005a79"
        strokeWidth=".5"
      />
    </svg>
  )
}
