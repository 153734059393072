import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import QSelect from '@CommonComponents/QSelect'
import { FormControl } from '@material-ui/core'
import formRules from '@Utils/form-rules'
import QMuiButtonConfirmation from '@CommonComponents/Mui/QMuiButtonConfirmation'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'
import QMuiTextarea from '@CommonComponents/Mui/QMuiTextarea'
import { Add, Edit } from '@material-ui/icons'
import FoldersAPI from '@API/admin/folders'
import { isEmpty } from 'lodash'
import { Editor } from '@tinymce/tinymce-react'

export default function FolderNoteForm({
  folder,
  note,
  categories,
  onAdd,
  onEdit,
  apiMode,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [submittedData, setSubmittedData] = useState(null)
  const {
    register,
    reset,
    control,
    handleSubmit,
    errors,
    formState: { isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      title: note?.title,
      description: note?.description,
      noteCategory: note?.noteCategory?.id,
    },
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ ...submittedData })
    }
  }, [isSubmitSuccessful, submittedData, reset])

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)
      let response
      if (!isEmpty(note)) {
        response = await FoldersAPI.patchNote(note.id, data, apiMode)
        onEdit(response.data)
      } else {
        response = await FoldersAPI.postNote(folder.id, data, apiMode)
        onAdd(response.data)
      }
      setSubmittedData({
        title: null,
        description: null,
        noteCategory: null,
      })
      setIsLoading(false)
    } catch (errors) {
      console.log(errors)
    }
  }

  const onError = (errors) => {
    console.log(errors)
  }
  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <FormControl style={{ width: '100%', marginBottom: 20 }}>
        <QSelect
          placeholder="Catégorie"
          name="noteCategory"
          control={control}
          disabled={isLoading}
          register={register({ ...formRules.isRequired })}
          errorMsg={errors?.noteCategory?.message}
          options={categories.map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
        />
      </FormControl>
      <QMuiFormInput
        className="form-field"
        placeholder="Titre ..."
        name="title"
        register={register({ ...formRules.isRequired })}
        control={control}
        disabled={isLoading}
        errorMsg={errors?.title?.message}
        containerStyle={{ width: '100%', margin: 0, marginBottom: 20 }}
      />
      <Controller
        name="description"
        control={control}
        rules={{ ...formRules.isRequired }}
        disabled={isLoading}
        errorMsg={errors?.description?.message}
        render={({ onChange, value }) => (
          <Editor
            init={{
              height: 400,
              branding: false,
              language: 'fr_FR',
              language_url: '/langs/fr_FR.js',
            }}
            value={value}
            onEditorChange={(content) => onChange(content)}
          />
        )}
      />
      <div className="flex justify-end mt-3">
        {!isEmpty(note) ? (
          <QMuiButtonConfirmation
            btnText="Modifier"
            confirmation="Êtes-vous sûr de vouloir modifier la note ?"
            type="submit"
          >
            Modifier
            <Edit className="ml-2" />
          </QMuiButtonConfirmation>
        ) : (
          <QMuiButtonConfirmation
            btnText="Ajouter"
            confirmation="Êtes-vous sûr de vouloir créer une note ?"
            type="submit"
          >
            Ajouter
            <Add className="ml-2" />
          </QMuiButtonConfirmation>
        )}
      </div>
    </form>
  )
}
