import APIClient from '../../APIClient'

const getAllStepTypes = async (sortParams) => {
  const options = {}

  if (sortParams) {
    options.params = { ...sortParams }
  }

  return await APIClient.get('/api/admin/steps-types', options)
}

/**
 * @param {number} id
 * @param {*} payload
 * @param {string} payload.name
 * @param {string} payload.fileName
 * @param {string} payload.fileContent
 */
const postStepType = async (payload) => {
  return await APIClient.post('/api/admin/steps-types', payload)
}

const getStepType = async (stepId) => {
  return await APIClient.get(`/api/admin/steps-types/${stepId}`)
}

const deleteStepType = async (stepId) => {
  return await APIClient.delete(`/api/admin/steps-types/${stepId}`)
}

/**
 * @param {number} id
 * @param {*} payload
 * @param {string} payload.name
 * @param {string} payload.fileName
 * @param {string} payload.fileContent
 */
const patchStepType = async (stepId, payload) => {
  return await APIClient.patch(`/api/admin/steps-types/${stepId}`, payload)
}

const StepTypesAPI = {
  getAllStepTypes,
  postStepType,
  getStepType,
  deleteStepType,
  patchStepType,
}

export default StepTypesAPI
