import { Close, Edit } from '@material-ui/icons'
import QMuiButtonConfirmation from '@CommonComponents/Mui/QMuiButtonConfirmation'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import { format } from 'date-fns'
import DOMPurify from 'dompurify'
export default function FolderNoteItem({ note, onDelete, onShowEditForm }) {
  return (
    <div className="notes-item">
      <div className="notes-item-header">
        <h3 className="notes-item-title">{note.title}</h3>
        <div className="notes-item-category">
          <div
            style={{ backgroundColor: note.noteCategory.color }}
            className="notes-item-category-badge"
          />
          <div>Sélection: {note.noteCategory.name}</div>
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(note.description),
        }}
      />
      <div className="notes-item-date">
        {format(new Date(note.updatedAt), "dd/MM/yyyy à H'h'mm")}
      </div>
      <div className="notes-item-actions">
        <QMuiButton
          onClick={() => onShowEditForm(note)}
          isIcon={true}
          color="primary"
        >
          <Edit />
        </QMuiButton>
        <QMuiButtonConfirmation
          confirmation="Etes vous sur de vouloir supprimer la note ?"
          handleConfirmation={() => onDelete(note.id)}
          isIcon={true}
          color="primary"
        >
          <Close />
        </QMuiButtonConfirmation>
      </div>
    </div>
  )
}
