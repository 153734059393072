import React, { useState, useEffect } from 'react'

import Chat from '@Components/Chat'
import FolderNote from '@Components/FolderNote'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import { useMatchMedia } from '@Utils/hooks'

export default function LawyerCollaboration({ folder, apiMode }) {
  const [toggleRefresh, setToggleRefresh] = useState(true)

  const isMobile = useMatchMedia('(max-width: 800px)')
  const [mobileView, setMobileView] = useState('chat')

  useEffect(() => {
    const intervalId = setInterval(async () => {
      setToggleRefresh(!toggleRefresh)
    }, 10000)

    return () => clearInterval(intervalId)
  }, [toggleRefresh, setToggleRefresh])

  return (
    <div className="LawyerCollaboration">
      {isMobile && (
        <div className="mx-auto mb-5">
          <QMuiButton
            size="small"
            color="secondary"
            variant={mobileView === 'chat' ? 'contained' : 'outlined'}
            onClick={() => setMobileView('chat')}
            className="mr-4"
          >
            Collaboration
          </QMuiButton>

          <QMuiButton
            size="small"
            color="primary"
            variant={mobileView === 'chat' ? 'contained' : 'outlined'}
            onClick={() => setMobileView('notes')}
          >
            Notes
          </QMuiButton>
        </div>
      )}

      {(!isMobile || mobileView === 'chat') && (
        <Chat
          title="Collaboration entre avocats"
          folder={folder}
          type="collaboration"
          mode="chat"
          apiMode={apiMode}
          permissions={apiMode === 'expert' ? 'rw' : 'r'}
          toggleRefresh={toggleRefresh}
        />
      )}

      {(!isMobile || mobileView === 'notes') && (
        <FolderNote
          title="Notes du dossier"
          folder={folder}
          apiMode={apiMode}
        />
      )}
    </div>
  )
}
