import * as React from 'react'

function SvgWave(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 150" {...props}>
      <path
        fill="currentColor"
        d="M-0.57,65.42 C150.00,150.00 347.62,17.07 500.56,81.22 L500.00,150.00 L0.00,150.00 Z"
      />
    </svg>
  )
}

export default SvgWave
