import APIClient from '../APIClient'

export const getFolders = async (page, search, sortParams) => {
  return await APIClient.get('/api/expert/folders', {
    params: { page, search, ...sortParams },
  })
}

export const getDomains = async () => {
  return await APIClient.get('/api/expert/domains/all')
}

export const patchProfile = async (data) => {
  return await APIClient.patch('/api/expert/profile', data)
}
