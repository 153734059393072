import React from 'react'
import { Controller } from 'react-hook-form'
import { AttachFile as AttachFileIcon } from '@material-ui/icons'
import QFile from '@CommonComponents/QFile'

export default function QMuiImageForm({ control, name }) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ onChange, value }) => (
        <QFile
          color="secondary"
          file={value}
          onChange={onChange}
          endIcon={<AttachFileIcon />}
        >
          Votre avatar
        </QFile>
      )}
    />
  )
}
