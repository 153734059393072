import LawyerAPI from '@API/admin/experts'
import QMuiDataGrid from '@CommonComponents/Mui/QMuiDataGrid'
import React, { useState, useEffect } from 'react'
import { InputAdornment } from '@material-ui/core'
import QMuiInput from '@CommonComponents/Mui/QMuiInput'
import { debounce } from 'lodash'
import Tooltip from '@material-ui/core/Tooltip'
import { ReactComponent as RightArrow } from '@Assets/svg/arrow-right.svg'
import QMuiLinkButton from '@CommonComponents/Mui/QMuiLinkButton'
import Breadcrumb from '@CommonComponents/Breadcrumb'
import SearchIcon from '@Components/icons/SearchIcon'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import particulierImg from '@Assets/img/particulier.png'
import proImg from '@Assets/img/professionnel.png'
import { DateTime } from 'luxon'

export default function LawyerFolderList() {
  const [isLoading, setIsLoading] = useState(true)
  const [lawyer, setLawyer] = useState(null)
  const [folders, setFolders] = useState([])
  const [search, setSearch] = useState('')
  const [sortParams, setSortParams] = useState(null)
  const { lawyerId } = useParams()
  const [pageCount, setPageCount] = useState(1)

  const history = useHistory()

  const columns = [
    {
      headerName: ' ',
      field: 'type',
      width: 50,
      renderCell: ({ value }) => {
        const isPro = value === 'professionnel'
        return (
          <img
            src={isPro ? proImg : particulierImg}
            alt={isPro ? 'Professionnel' : 'Particulier'}
            style={{ minWidth: '3rem', minHeight: '3rem' }}
          />
        )
      },
    },
    {
      field: 'id',
      headerName: 'N°',
      width: 50,
    },
    {
      field: 'name',
      headerName: 'Nom du dossier',
      width: 220,
    },
    {
      field: 'subdomain.domain.name',
      headerName: "Domaine d'activité",
      width: 190,
    },
    {
      field: 'subdomain.name',
      headerName: 'Sous domaine ',
      width: 190,
    },
    {
      headerName: 'Client',
      field: 'customer.name',
      width: 170,
    },
    {
      field: 'experts',
      headerName: 'Avocats',
      flex: 1,
      renderCell: ({ row: { experts } }) => {
        if (experts.length === 0) return ''

        if (experts.length === 1) return experts[0].lastName

        return (
          <div>
            <Tooltip
              title={experts.map((expert) => expert.lastName).join(', ')}
              arrow
              classes={{ tooltip: 'tooltip' }}
            >
              <span className="badge">{experts.length}</span>
            </Tooltip>
          </div>
        )
      },
    },
    {
      headerName: 'Localisation',
      field: 'localisation',
      sortable: false,
      flex: 1,
    },
    {
      headerName: 'Création',
      field: 'createdAt',
      width: 120,
      valueFormatter: ({ value }) => DateTime.fromISO(value).toLocaleString(),
    },
    {
      headerName: 'Modification',
      field: 'updatedAt',
      width: 120,
      valueFormatter: ({ value }) => DateTime.fromISO(value).toLocaleString(),
    },
    {
      headerName: '',
      field: 'action',
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <QMuiLinkButton
            to={`/admin/folders/${row.id}`}
            variant="text"
            color="transparent"
          >
            <RightArrow width={20} />
          </QMuiLinkButton>
        )
      },
    },
  ]

  const debounceSearch = debounce(async (e) => setSearch(e.target.value), 800)

  const pageNum = parseInt(
    new URLSearchParams(useLocation().search).get('p') || 1
  )

  useEffect(() => {
    async function fetchData() {
      const { data } = await LawyerAPI.getFolderForOnExpert(
        lawyerId,
        pageNum,
        search,
        sortParams
      )

      const count = Math.ceil(data.meta.total_items / data.meta.items_per_page)

      const folders = data.results.map((folder) => {
        const address =
          folder.type === 'professionnel'
            ? folder?.company?.address
            : folder?.customer?.address
        return {
          ...folder,
          localisation: address
            ? `${address.city} (${address.postalCode})`
            : '',
        }
      })

      setPageCount(count)
      setFolders(folders)

      const { data: lawyerData } = await LawyerAPI.getOne(lawyerId)
      setLawyer(lawyerData)
      setIsLoading(false)
    }
    fetchData()
  }, [lawyerId, pageNum, search, sortParams])

  const showFolder = (params) => {
    if (params.field !== 'action') {
      history.push(`/admin/folders/${params.row.id}`)
    }
  }

  return (
    <div className="page-container">
      <Breadcrumb
        items={[
          { label: 'Avocats', link: '/admin/lawyers' },
          {
            label: lawyer?.lastName,
          },
        ]}
      />

      <h1 className="page-title">Liste des dossiers de {lawyer?.lastName}</h1>

      <header className="flex align-items justify-between mb-4">
        <QMuiLinkButton to={`/admin/lawyers/${lawyer?.id}`}>
          Fiche avocat
        </QMuiLinkButton>
        <QMuiInput
          style={{ marginRight: 10 }}
          onChange={debounceSearch}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon style={{ width: 24, height: 24 }} />
            </InputAdornment>
          }
        />
      </header>

      <QMuiDataGrid
        sortParamsMode="server"
        sortModelPrefix="user"
        onSortParams={setSortParams}
        columns={columns}
        rows={folders}
        loading={isLoading}
        noResultMsg="Aucun dossier"
        page={pageNum}
        count={pageCount}
        type="folder"
        apiMode="admin"
        onCellClick={(params) => showFolder(params)}
        handlePaginationChange={(page) =>
          history.push({
            search: `?p=${page}`,
          })
        }
      />
    </div>
  )
}
