import Menu from '@CommonComponents/Menu'
import AskMenuIcon from './icons/AskMenu'
import Messages from './icons/menu/Messages'
import Calendar from './icons/menu/Calendar'
import Accounting from './icons/menu/Accounting'
import Folders from './icons/menu/Folders'
import Lawyers from './icons/menu/Lawyers'

export default function MainMenu({ isOpen }) {
  const filesMenu = [
    {
      icon: <Folders />,
      title: 'Mes dossiers',
      linkTo: '/folders',
    },
    {
      icon: <Messages />,
      title: 'Mes messages',
      linkTo: '/messages',
    },
    {
      icon: <Accounting />,
      title: 'Ma compta',
      linkTo: '/accounting',
    },
    {
      icon: <Lawyers />,
      title: 'FAQ',
      linkTo: '/faq',
    },
  ]

  return (
    <div className="MainMenu">
      <div className="MainMenu-static">
        <Menu itemStyle="MainMenu-item" items={filesMenu} />
      </div>
    </div>
  )
}
