// TODO rename component for editor form ?
import React, { useEffect } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import QMuiButtonConfirmation from '@CommonComponents/Mui/QMuiButtonConfirmation'
import { useForm, Controller } from 'react-hook-form'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'
import { Box } from '@material-ui/core'

export default function SimplyEditor({ onSubmit, initialPayload }) {
  const { register, handleSubmit, watch, control, setValue } = useForm()
  const watchTitle = watch('title')

  // Set default value
  useEffect(() => {
    if (initialPayload && initialPayload.content) {
      setValue('content', initialPayload.content)
      setValue('title', initialPayload.title)
    }
  }, [initialPayload, setValue])

  return (
    <div className="SimplyEditor">
      <form className="SimplyEditor-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="SimplyEditor-container">
          <div className="SimplyEditor-editor">
            <QMuiFormInput
              watch={watchTitle}
              className="SimplyEditor-title"
              register={register}
              control={control}
              label="Titre"
              name="title"
            />
            <div>
              <label className="SimplyEditor-label">Contenu</label>
              <Controller
                name="content"
                control={control}
                render={({ onChange, value }) => {
                  return (
                    <Editor
                      init={{
                        height: 400,
                        branding: false,
                        language: 'fr_FR',
                        language_url: '/langs/fr_FR.js',
                      }}
                      {...{ value }}
                      onEditorChange={(content) => onChange(content)}
                    />
                  )
                }}
              />
            </div>
          </div>
        </div>
        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <QMuiButtonConfirmation
            btnText="Sauvegarder"
            confirmation="Êtes-vous sûr de vouloir sauvegarder ?"
            type="submit"
          >
            Sauvegarder
          </QMuiButtonConfirmation>
        </Box>
      </form>
    </div>
  )
}
