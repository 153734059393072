import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { AttachFile as AttachFileIcon } from '@material-ui/icons'

import formRules from '@Utils/form-rules'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import QMuiTextarea from '@CommonComponents/Mui/QMuiTextarea'
import QMuiModal from '@CommonComponents/Mui/QMuiModal'
import QFile from '@CommonComponents/QFile'
import {
  fileToBase64,
  formatSymfonyValidationErrors,
  getErrorMsgFromSymfonyResponse,
} from '@Helpers'
import { postDocument } from '@API/documents'
import { useSnackbar } from '@Context/snackbar'

export default function DocumentAddForm({
  open,
  onClose,
  onAdd,
  apiMode,
  mode,
  id,
}) {
  const {
    register,
    control,
    handleSubmit,
    errors,
    clearErrors,
    setError,
  } = useForm()

  const [loading, setLoading] = useState(false)
  const [docFile, setDocFile] = useState(null)

  const { addSnackbarMsg } = useSnackbar()

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      if (docFile === null) {
        addSnackbarMsg({
          severity: 'error',
          content: 'Vous devez choisir un fichier',
        })
        setLoading(false)
        return
      }

      data.fileName = docFile.name
      data.fileContent = await fileToBase64(docFile)
      const response = await postDocument(apiMode, data, id, mode)
      setLoading(false)
      onAdd(response.data)
    } catch (error) {
      const resp = error.response

      if (resp.data.message === 'Validation Failed') {
        const formatedErrors = formatSymfonyValidationErrors(resp.data.errors)

        for (let key in formatedErrors) {
          setError(key, formatedErrors[key])
        }

        onError(formatedErrors)
      } else {
        addSnackbarMsg({
          severity: 'error',
          content: getErrorMsgFromSymfonyResponse(error.response),
        })
      }
      setLoading(false)
    }
  }

  const onError = (errors) => {
    for (let key in errors) {
      if (['fileName', 'fileContent'].includes(key)) {
        addSnackbarMsg({
          severity: 'error',
          content: errors[key].message,
        })
      }
    }
  }

  return (
    <QMuiModal open={open} title="Envoyer un document">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="flex flex-col px-4">
          <QMuiFormInput
            className="form-field fullWidth"
            label="Nom du document"
            name="name"
            register={register({ ...formRules.isRequired })}
            control={control}
            errorMsg={errors?.name?.message}
            disabled={loading}
          />

          <QMuiTextarea
            className="form-field fullWidth"
            rowsMin={3}
            label="Description"
            name="description"
            register={register({ ...formRules.isRequired })}
            control={control}
            errorMsg={errors?.description?.message}
            disabled={loading}
          />

          <QFile
            color="secondary"
            file={docFile}
            onChange={(e) => {
              clearErrors()
              setDocFile(e)
            }}
            endIcon={<AttachFileIcon />}
          >
            Fichier
          </QFile>
        </div>

        <div className="flex p-4">
          <QMuiButton
            color="danger"
            className="ml-auto"
            onClick={onClose}
            disabled={loading}
          >
            Annuler
          </QMuiButton>

          <QMuiButton type="submit" className="ml-4" disabled={loading}>
            Envoyer
          </QMuiButton>
        </div>
      </form>
    </QMuiModal>
  )
}
