import LawyerAPI from '@API/admin/experts'
import QMuiDataGrid from '@CommonComponents/Mui/QMuiDataGrid'
import { useLocation, useHistory } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import SearchIcon from '@Components/icons/SearchIcon'
import { debounce } from 'lodash'
import { InputAdornment } from '@material-ui/core'
import QMuiInput from '@CommonComponents/Mui/QMuiInput'
import { ReactComponent as RightArrow } from '@Assets/svg/arrow-right.svg'
import { Add as AddIcon } from '@material-ui/icons'
import QMuiLinkButton from '@CommonComponents/Mui/QMuiLinkButton'
import Breadcrumb from '@CommonComponents/Breadcrumb'
import Tooltip from '@material-ui/core/Tooltip'

export default function LawyerList() {
  const [isLoading, setIsLoading] = useState(true)
  const [lawyerList, setLawyerList] = useState([])
  const [search, setSearch] = useState(null)
  const [sortParams, setSortParams] = useState(null)
  const [pageCount, setPageCount] = useState(1)

  const history = useHistory()

  const pageNum = parseInt(
    new URLSearchParams(useLocation().search).get('p') || 1
  )

  const columns = [
    {
      headerName: 'N°Avocat',
      field: 'lawyerNumber',
      flex: 1,
    },
    {
      headerName: 'Nom',
      field: 'lastName',
      flex: 1,
    },
    {
      headerName: 'Barreau',
      field: 'professionalInformation.bar',
      flex: 1,
    },
    {
      headerName: 'Spécialités',
      field: 'domains',
      flex: 1,
      renderCell: ({ row }) => {
        if (row.domains.length === 0) return ''

        if (row.domains.length === 1) return row.domains[0].name

        return (
          <div>
            <Tooltip
              title={row.domains.join(', ')}
              arrow
              classes={{ tooltip: 'tooltip' }}
            >
              <span className="badge">{row.domains.length}</span>
            </Tooltip>
          </div>
        )
      },
    },
    {
      headerName: 'Sous domaines',
      field: 'professionalInformation.subdomains',
      renderCell: ({ row }) => {
        const subdomains = row['professionalInformation.subdomains'] || []
        if (subdomains.length === 0) return ''

        if (subdomains.length === 1) return subdomains[0].name

        return (
          <div>
            <Tooltip
              title={subdomains.map((d) => d.name).join(', ')}
              arrow
              classes={{ tooltip: 'tooltip' }}
            >
              <span className="badge">{subdomains.length}</span>
            </Tooltip>
          </div>
        )
      },
      flex: 1,
    },
    {
      headerName: "Annees d'experiences",
      field: 'professionalExperience.nbYears',
      flex: 1,
      sortable: false,
    },
    {
      headerName: "Secteur d'intervention",
      field: 'professionalInformation.interventionSectors',
      sortable: false,
      renderCell: ({ row }) => {
        const interventionSectors =
          row.professionalInformations?.interventionSectors || []

        if (interventionSectors.length === 0) return ''

        if (interventionSectors.length === 1) return interventionSectors[0]

        return (
          <div>
            <Tooltip
              title={interventionSectors.join(', ')}
              arrow
              classes={{ tooltip: 'tooltip' }}
            >
              <span className="badge">{interventionSectors.length}</span>
            </Tooltip>
          </div>
        )
      },
      flex: 1,
    },
    {
      headerName: '',
      field: 'action',
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <QMuiLinkButton
            to={`/admin/lawyers/${row.id}/folders`}
            variant="text"
            color="transparent"
          >
            <RightArrow width={20} />
          </QMuiLinkButton>
        )
      },
    },
  ]

  const debounceSearch = debounce(async (e) => setSearch(e.target.value), 800)

  useEffect(() => {
    async function getList() {
      const { data } = await LawyerAPI.getAll(pageNum, search, sortParams)

      const count = Math.ceil(data.meta.total_items / data.meta.items_per_page)

      setPageCount(count)
      setLawyerList(
        data.results.map((lawyer) => {
          const domains = []

          if (lawyer.professionalInformation) {
            lawyer.professionalInformation.subdomains.forEach((subdomain) => {
              if (!domains.includes(subdomain.domain.name)) {
                domains.push(subdomain.domain.name)
              }
            })
          }

          return {
            ...lawyer,
            domains,
          }
        })
      )
      setIsLoading(false)
    }

    getList()
  }, [pageNum, sortParams, search])

  const showLawyer = (params) => {
    if (params.field !== 'action') {
      history.push(`/admin/lawyers/${params.row.id}/folders`)
    }
  }

  return (
    <div className="page-container">
      <Breadcrumb items={[{ label: 'Avocats' }]} />

      <h1 className="page-title">Liste avocats</h1>

      <header className="flex align-items justify-between mb-4">
        <QMuiLinkButton to="/admin/lawyers/new" endIcon={<AddIcon />}>
          Ajouter un avocat
        </QMuiLinkButton>
        <QMuiInput
          style={{ marginRight: 10 }}
          onChange={debounceSearch}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon style={{ width: 24, height: 24 }} />
            </InputAdornment>
          }
        />
      </header>
      <QMuiDataGrid
        sortParamsMode="server"
        sortModelPrefix="user"
        onSortParams={setSortParams}
        columns={columns}
        rows={lawyerList}
        loading={isLoading}
        noResultMsg="Aucun avocats"
        onCellClick={(params) => showLawyer(params)}
        page={pageNum}
        count={pageCount}
        handlePaginationChange={(e, page) => history.push(`?p=${page}`)}
      />
    </div>
  )
}
