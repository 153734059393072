import APIClient from '../APIClient'

const EXPERTS_BASE_URI = '/api/admin/experts'

const getAll = async (page, search, sortParams) => {
  return await APIClient.get(EXPERTS_BASE_URI, {
    params: { page, search, ...sortParams },
  })
}

const getOne = async (expertId) =>
  await APIClient.get(EXPERTS_BASE_URI + `/${expertId}`)

const patchExpert = async (expertId, data) => {
  return await APIClient.patch(EXPERTS_BASE_URI + `/${expertId}`, data)
}

const postExpert = async (data) => {
  return await APIClient.post(EXPERTS_BASE_URI, data)
}

const getFolderForOnExpert = async (expertId, page, search, sortParams) =>
  await APIClient.get(EXPERTS_BASE_URI + `/${expertId}/folders`, {
    params: { page, search, ...sortParams },
  })

const getAllMinimal = async () => {
  return await APIClient.get('/api/admin/experts/minimal')
}

const ExpertsApi = {
  getAll,
  getAllMinimal,
  getOne,
  getFolderForOnExpert,
  patchExpert,
  postExpert,
}

export default ExpertsApi
