import { useContext, createContext, useReducer } from 'react'

export const initialState = {
  currentMsgId: 0,
  messages: [],
}

const SnackbarContext = createContext(initialState)

/**
 * Component for provide Snackbar Context
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export function ProvideSnackbar({ children }) {
  const snackbar = useProvideSnackbar()
  return (
    <SnackbarContext.Provider value={snackbar}>
      {children}
    </SnackbarContext.Provider>
  )
}

export function useProvideSnackbar() {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'ADD_MSG':
        const nextMsgId = state.currentMsgId + 1
        return {
          currentMsgId: nextMsgId,
          messages: [
            ...state.messages,
            {
              id: nextMsgId,
              ...action.message,
            },
          ],
        }
      case 'REMOVE_MSG':
        return {
          ...state,
          messages: state.messages.filter((msg) => msg.id !== action.msgId),
        }
      default:
        throw new Error()
    }
  }, initialState)

  const addSnackbarMsg = async ({ severity, content }) => {
    dispatch({
      type: 'ADD_MSG',
      message: {
        severity,
        content,
      },
    })
  }

  const removeSnackbarMsg = async (msgId) => {
    dispatch({
      type: 'REMOVE_MSG',
      msgId,
    })
  }

  return {
    snackbarMsgs: state.messages,
    addSnackbarMsg,
    removeSnackbarMsg,
  }
}

/**
 * Hook for get store, dispatch function and helper for know if we are authenticate
 */
export const useSnackbar = () => {
  return useContext(SnackbarContext)
}
