import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import QSelect from '@CommonComponents/QSelect'
import formRules from '@Utils/form-rules'
import QTag from '@CommonComponents/QTag'
import { useWatch } from 'react-hook-form'
import { FormControl } from '@material-ui/core'

export default function SubdomainForm({
  name,
  control,
  errors,
  domains,
  ...delegate
}) {
  console.log(errors)
  const [selectedDomain, setSelectedDomain] = useState({})

  const { control: controlDomain, getValues, register, watch } = useForm()

  const watchDomain = watch('domain')

  const watchSubdomains = useWatch({ control, name })

  const getSubdomainName = (id) => {
    for (let domain of domains) {
      const found = domain.subdomains.find((s) => s.id === id)

      if (found) {
        return found.name
      }
    }
  }

  useEffect(() => {
    if (typeof watchDomain === 'number') {
      const domain = domains.find(({ id }) => id === watchDomain)
      setSelectedDomain(domain)
    }
  }, [watchDomain, domains])

  const handleClick = (onChange, subdomains) => {
    const shallowCopy = [...subdomains]
    const newValue = getValues('professionalInformation.subdomains')

    if (newValue) {
      const exist = shallowCopy.findIndex((id) => id === newValue)

      if (exist === -1) {
        shallowCopy.push(newValue)
        onChange(shallowCopy)
      }
    }
  }

  const handleDelete = (onChange, value, index) => {
    const shallowCopy = [...value]
    shallowCopy.splice(index, 1)
    onChange(shallowCopy)
  }

  return (
    <Controller
      control={control}
      defaultValue={[]}
      name={name}
      {...delegate}
      render={({ onChange, value }) => (
        <div>
          <h3 className="mt-4 mb-0">Sous domaines</h3>

          <div className="flex flex-wrap align-items-center mb-4">
            <FormControl className="form-field">
              <QSelect
                name="domain"
                label="Domaine"
                control={controlDomain}
                options={domains.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              />
            </FormControl>

            <FormControl className="form-field">
              <QSelect
                name="professionalInformation.subdomains"
                label="Sous domaine"
                control={controlDomain}
                rules={{ ...formRules.isRequired }}
                errorMsg={errors?.professionalInformation?.subdomains?.message}
                isDisabled={!('id' in selectedDomain)}
                options={
                  'subdomains' in selectedDomain
                    ? selectedDomain.subdomains.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))
                    : []
                }
              />
            </FormControl>

            <QMuiButton
              className="ml-auto"
              onClick={() => handleClick(onChange, value)}
            >
              Ajouter
            </QMuiButton>
          </div>

          {domains.length > 0 &&
            watchSubdomains?.map((subdomainId) => (
              <QTag
                key={subdomainId}
                containerClasses="my-1"
                tag={getSubdomainName(subdomainId)}
                disabled={true}
                handleDelete={(index) => handleDelete(onChange, value, index)}
              />
            ))}
        </div>
      )}
    />
  )
}
