import React, { useEffect, useState } from 'react'
import FolderNoteForm from '@Components/FolderNoteForm'
import FolderNoteItem from '@Components/FolderNoteItem'
import FolderNoteFilter from '@Components/FolderNoteFilter'
import { useSnackbar } from '@Context/snackbar'
import { remove } from 'lodash'
import { Loop as LoopIcon } from '@material-ui/icons'
import FoldersAPI from '@API/admin/folders'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import { Add, Remove } from '@material-ui/icons'
import { findIndex, orderBy } from 'lodash'

export default function FolderNote({ title, folder, apiMode }) {
  const [showForm, setShowForm] = useState(false)
  const [categories, setCategories] = useState([])
  const [notes, setNotes] = useState([])
  const [currentNote, setCurrentNote] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCategId, setSelectedCategId] = useState()

  const { addSnackbarMsg } = useSnackbar()

  useEffect(() => {
    const fetchCategories = async () => {
      const resp = await FoldersAPI.getAllNotesCategories(apiMode)
      setCategories(resp.data)
    }
    const fetchNotes = async () => {
      try {
        const response = await FoldersAPI.getNotes(folder.id, apiMode)
        setNotes(orderBy(response.data, ['createdAt'], ['desc']))
      } catch (error) {
        console.log(error)
      }
    }
    if (folder) {
      setIsLoading(true)
      fetchCategories()
      fetchNotes()
      setIsLoading(false)
    }
  }, [folder, apiMode])

  const handleOnAdd = (note) => {
    setNotes((notes) => [note, ...notes])
    setShowForm(false)
    addSnackbarMsg({
      severity: 'success',
      content: 'La note a ete ajoutée',
    })
  }

  const handleOnEdit = (note) => {
    const index = findIndex(notes, { id: note.id })
    notes.splice(index, 1)
    setNotes((notes) => [note, ...notes])
    setShowForm(false)
    addSnackbarMsg({
      severity: 'success',
      content: 'La note a ete modifiée',
    })
  }

  const handleOnDelete = async (id) => {
    try {
      await FoldersAPI.deleteNote(id, apiMode)
      setNotes(
        remove(notes, (n) => {
          return n.id !== id
        })
      )
      addSnackbarMsg({
        severity: 'success',
        content: 'La note a ete supprimée',
      })
    } catch (errors) {
      console.log(errors)
    }
  }

  const handleShowForm = () => {
    setCurrentNote({})
    setShowForm(!showForm)
  }

  const handleShowEditForm = (note) => {
    setShowForm(true)
    setCurrentNote(note)
  }

  return (
    <div className="notes">
      <div className="notes-header">
        <h2 className="notes-title">{title}</h2>
        <div className="flex align-items-center">
          <FolderNoteFilter
            categories={categories}
            onFilter={(id) => setSelectedCategId(id)}
            apiMode={apiMode}
          />
          <QMuiButton
            variant="outlined"
            className="isSecondary"
            isIcon
            onClick={() => handleShowForm()}
          >
            {showForm ? <Remove /> : <Add />}
          </QMuiButton>
        </div>
      </div>
      {showForm && (
        <div className="notes-form">
          <FolderNoteForm
            note={currentNote}
            folder={folder}
            categories={categories}
            onAdd={handleOnAdd}
            onEdit={handleOnEdit}
            apiMode={apiMode}
          />
        </div>
      )}
      <div className="notes-inner">
        {notes.length > 0 ? (
          notes
            .filter(
              (note) =>
                !selectedCategId ||
                selectedCategId === -1 ||
                note.noteCategory.id === selectedCategId
            )
            .map((note, index) => (
              <FolderNoteItem
                key={index}
                note={note}
                onDelete={handleOnDelete}
                onShowEditForm={handleShowEditForm}
              />
            ))
        ) : (
          <div className="text-bold text-italic">
            Aucune notes pour ce dossier
          </div>
        )}
        {isLoading && (
          <div className="notes-loading">
            <LoopIcon />
          </div>
        )}
      </div>
    </div>
  )
}
