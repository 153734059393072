import React, { useState } from 'react'
import UserForm from '@Components/forms/UserForm'
import FolderForm from '@Components/forms/FolderForm'

export default function Request() {
  const [customer, setCustomer] = useState({})
  const [step, setStep] = useState(1)

  const onEditCustomer = (customer) => {
    setCustomer(customer)
    setStep(2)
  }

  return (
    <div className="page-container page-thin-container">
      <div className="form-steps">
        <div className="form-steps-content">
          <div className={'form-step-content' + (step === 1 ? ' active' : '')}>
            <UserForm apiMode="admin" onEdit={onEditCustomer} />
          </div>
          <div className={'form-step-content' + (step === 2 ? ' active' : '')}>
            <FolderForm clientId={customer.id} />
          </div>
        </div>
      </div>
    </div>
  )
}
