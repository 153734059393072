import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from '@Context/auth.js'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import formRules from '@Utils/form-rules'
import { postMessage } from '@API/messages'
import { AccountCircle as AccountCircleIcon } from '@material-ui/icons'
import { Send as SendIcon } from '@material-ui/icons'

export default function ChatForm({
  folder,
  discussion,
  type,
  apiMode,
  placeholder,
  onAdd,
}) {
  const { user } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [submittedData, setSubmittedData] = useState(null)
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      message: null,
    },
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ ...submittedData })
    }
  }, [isSubmitSuccessful, submittedData, reset])

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)
      const content = data.message.trim()

      const message = await postMessage(
        {
          content,
          customerId: type === 'customer' ? folder?.customer?.id : null,
          folderId: ['folder', 'collaboration'].includes(type)
            ? folder?.id
            : null,
          discussionId: ['discussion'].includes(type) ? discussion?.id : null,
        },
        type,
        apiMode
      )

      onAdd(message.data)
      setSubmittedData({
        message: null,
      })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  const onError = (errors) => {
    console.log(errors)
  }

  return (
    <div className="chat-form flex align-items-center">
      {user?.image ? (
        <div
          className="chat-form-avatar"
          style={{ backgroundImage: `url(${user.image})` }}
        />
      ) : (
        <AccountCircleIcon className="chat-form-avatar" />
      )}
      <form
        className="flex align-items-center flex-grow"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <QMuiFormInput
          className="form-input"
          name="message"
          control={control}
          placeholder={placeholder ? placeholder : 'Saisir votre message'}
          register={register({ ...formRules.isRequired })}
          disabled={isLoading}
        />
        <QMuiButton
          className="chat-form-submit"
          color="primary"
          type="submit"
          disabled={isLoading}
        >
          <span>Envoyer</span>
          <SendIcon className="ml-2" />
        </QMuiButton>
      </form>
    </div>
  )
}
