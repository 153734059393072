import { get } from 'lodash'
import classnames from 'classnames'
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from '@material-ui/icons'
import CircularProgress from '@material-ui/core/CircularProgress'

export default function QTable({
  className,
  columns,
  rows,
  onRowClick = () => {},
  isLoading = false,
  noResultMsg = 'Aucun résultat',
  sort = null,
  setSort = () => {},
}) {
  const minWidth = columns
    .map(({ minWidth, width }) => minWidth || width || 0)
    .reduce((a, b) => a + b)

  const onHeaderClick = (column) => {
    if (column.sortable) {
      if (sort?.sort === (column.sortField || column.field)) {
        if (sort.direction === 'asc') {
          setSort({ sort: column.sortField || column.field, direction: 'desc' })
        } else {
          setSort(null)
        }
      } else {
        setSort({ sort: column.sortField || column.field, direction: 'asc' })
      }
    }
  }

  return (
    <div className={classnames('QTable', className)}>
      <div className="QTable-container">
        <table>
          <thead>
            <tr>
              {columns.map((column) => (
                <th
                  key={column.headerName}
                  style={{
                    ...column.style,
                    ...{ minWidth: column.minWidth, width: column.width },
                    cursor: column.sortable ? 'pointer' : 'normal',
                  }}
                  onClick={() => onHeaderClick(column)}
                >
                  <div className="QTable-cell flex align-items-center">
                    <span>{column.headerName}</span>
                    {sort?.sort === (column.sortField || column.field) && (
                      <span className="QTable-sortIcon">
                        {sort?.direction === 'asc' ? (
                          <ArrowUpwardIcon
                            onClick={() =>
                              setSort({
                                field: column.field,
                                direction: 'desc',
                              })
                            }
                          />
                        ) : (
                          <ArrowDownwardIcon onClick={() => setSort(null)} />
                        )}
                      </span>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          {!isLoading && rows.length > 0 && (
            <tbody>
              {rows.map((row) => (
                <tr
                  key={row.id}
                  onClick={() => {
                    onRowClick(row)
                  }}
                >
                  {columns.map((column) => (
                    <td
                      key={column.headerName}
                      style={{
                        ...column.style,
                        ...{ minWidth: column.minWidth, width: column.width },
                      }}
                    >
                      <div className="QTable-cell">
                        {getCellContent(row, column)}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </table>

        {(isLoading || rows.length === 0) && (
          <div className="QTable-loading">
            {isLoading ? <CircularProgress /> : <span>{noResultMsg}</span>}
          </div>
        )}
      </div>
    </div>
  )
}

function getCellContent(row, column) {
  let cell = get(row, column.field)

  if (typeof column.renderCell === 'function') {
    cell = column.renderCell({ value: cell, row })
  }

  return cell
}
