import React from 'react'
import { InputBase } from '@material-ui/core'
import classnames from 'classnames'

export default function QMuiInput({ className, classNameInput, ...props }) {
  return (
    <InputBase
      classes={{
        root: classnames('input', className),
        input: classNameInput,
      }}
      {...props}
    />
  )
}
