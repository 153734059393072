import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './scss/styles.scss'
import { ProvideAuth } from '@Context/auth.js'
import { ProvideSnackbar } from '@Context/snackbar.js'
import { StylesProvider } from '@material-ui/core/styles'

ReactDOM.render(
  <React.StrictMode>
    <ProvideAuth>
      <ProvideSnackbar>
        <StylesProvider injectFirst>
          <Router>
            <App />
          </Router>
        </StylesProvider>
      </ProvideSnackbar>
    </ProvideAuth>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
