import React from 'react'

export default function Customers() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.539" height="18.856">
      <path
        data-name="Path 9564"
        d="M10.431 9.155l-.055-.047a5.278 5.278 0 10-7.231.014l-.038.033C1.294 10.69.333 11.688-.002 15.982a2.1 2.1 0 001.245 1.823 15.054 15.054 0 0011.053 0 2.1 2.1 0 001.241-1.882c-.33-4.235-1.291-5.233-3.106-6.768zM2.983 5.278a3.77 3.77 0 113.77 3.77 3.774 3.774 0 01-3.768-3.77zM1.504 16.041c.3-3.8 1.059-4.45 2.576-5.735l.348-.3c.106.053 2.116.1 2.226.149l-.656 1.715 3.016 3.456-1.657 2.026c-1.667-.057-4.467-.342-5.361-.857-.144-.081-.488-.302-.489-.454zm10.036.458a9.906 9.906 0 01-4.183.858l1.657-2.026-3.016-3.456.656-1.715c.117-.048 2.33-.1 2.443-.159l.359.3c1.517 1.286 2.279 1.93 2.575 5.677 0 .215-.341.436-.488.521z"
        fill="#005a79"
      />
    </svg>
  )
}
