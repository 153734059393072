import React, { useState, useEffect } from 'react'
import { useAuth } from '@Context/auth'
import * as expertAPI from '@API/expert/expert'
import ExpertProfileForm from '@Components/forms/ExpertProfileForm'
import { useSnackbar } from '@Context/snackbar'
import DocumentsGestion from '@Components/DocumentsGestion'

export default function ExpertProfile() {
  const { user, setUser } = useAuth()
  const [domains, setDomains] = useState([])

  const { addSnackbarMsg } = useSnackbar()

  useEffect(() => {
    const fetchDomains = async () => {
      const { data } = await expertAPI.getDomains()
      setDomains(data)
    }

    fetchDomains()
  }, [setDomains])

  return (
    <div className="page-container page-thin-container">
      <h1 className="page-title">Votre profil</h1>

      <ExpertProfileForm
        expert={user}
        domains={domains}
        isCreation={false}
        apiMode="expert"
      />
      <section className="page-section">
        <DocumentsGestion
          title="Documents liés à la page gestion"
          apiMode="expert"
          mode="experts"
          id={user.id}
        />
      </section>
    </div>
  )
}
