import React, { useState, useEffect } from 'react'
import { useWatch } from 'react-hook-form'
import { FormControl, TextareaAutosize } from '@material-ui/core'
import classnames from 'classnames'
import QAppearDown from '@CommonComponents/transitions/QAppearDown'

export default function QMuiTextarea({
  className,
  label,
  name,
  register,
  defaultValue,
  errorMsg,
  control,
  ...props
}) {
  const [isFocus, setIsFocus] = useState(false)
  const [isEmpty, setIsEmpty] = useState(true)
  const watchValue = useWatch({ control, name })

  useEffect(() => {
    if (watchValue === '') {
      if (!isEmpty) {
        setIsEmpty(true)
      }
    } else {
      if (isEmpty) {
        setIsEmpty(false)
      }
    }
  }, [watchValue, isEmpty])

  const labelClasses = classnames('input-label', {
    isFocused: isFocus || !isEmpty,
  })

  const hasErrors = typeof errorMsg === 'string'

  return (
    <FormControl
      className={classnames('QMuiTextarea', className, {
        isError: hasErrors,
      })}
    >
      <label className={labelClasses}>{label}</label>
      <TextareaAutosize
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        ref={register}
        defaultValue={defaultValue}
        name={name}
        classes={{
          root: 'input',
        }}
        {...props}
      />
      <QAppearDown isActive={hasErrors} duration=".3s">
        <span className="input-error-msg">{errorMsg}</span>
      </QAppearDown>
    </FormControl>
  )
}
