import { getAllFoldersForCustomerId } from '@API/admin/customers'
import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import QMuiDataGrid from '@CommonComponents/Mui/QMuiDataGrid'
import QMuiLinkButton from '@CommonComponents/Mui/QMuiLinkButton'
import QMuiInput from '@CommonComponents/Mui/QMuiInput'
import Breadcrumb from '@CommonComponents/Breadcrumb'
import { InputAdornment } from '@material-ui/core'
import SearchIcon from '@Components/icons/SearchIcon'
import { debounce } from 'lodash'
import Tooltip from '@material-ui/core/Tooltip'
import { getCustomerById } from '@API/admin/customers'
import { DateTime } from 'luxon'

import { ReactComponent as RightArrow } from '@Assets/svg/arrow-right.svg'
import particulierImg from '@Assets/img/particulier.png'
import proImg from '@Assets/img/professionnel.png'

export default function ClientFolderList({ apiMode }) {
  const [folders, setFolders] = useState([])
  const { clientId } = useParams()
  const [client, setClient] = useState()
  const [sortParams, setSortParams] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState(null)
  const [pageCount, setPageCount] = useState(1)

  const history = useHistory()

  const pageNum = parseInt(
    new URLSearchParams(useLocation().search).get('p') || 1
  )

  useEffect(() => {
    async function onMount() {
      const { data } = await getCustomerById(clientId, apiMode)
      setClient(data)
    }
    onMount()
  }, [clientId, apiMode])

  useEffect(() => {
    async function onMount() {
      const { data } = await getAllFoldersForCustomerId(
        clientId,
        pageNum,
        search,
        sortParams
      )

      const folders = data.results.map((folder) => {
        const address =
          folder.type === 'professionnel'
            ? folder?.company?.address
            : client?.address
        return {
          ...folder,
          localisation: address
            ? `${address.city} (${address.postalCode})`
            : '',
        }
      })

      const count = Math.ceil(data.meta.total_items / data.meta.items_per_page)

      setPageCount(count)
      setFolders(folders)
      setIsLoading(false)
    }
    onMount()
  }, [clientId, sortParams, pageNum, search, client?.address])

  const columns = [
    {
      headerName: ' ',
      field: 'type',
      width: 50,
      renderCell: ({ value }) => {
        const isPro = value === 'professionnel'
        return (
          <img
            src={isPro ? proImg : particulierImg}
            alt={isPro ? 'Professionnel' : 'Particulier'}
            style={{ minWidth: '3rem', minHeight: '3rem' }}
          />
        )
      },
    },
    {
      headerName: 'N°',
      field: 'id',
      width: 50,
    },
    {
      headerName: 'Nom du dossier',
      field: 'name',
      width: 220,
    },
    {
      headerName: "Domaine d'activité",
      field: 'subdomain.domain.name',
      width: 190,
    },
    {
      headerName: 'Sous domaine',
      field: 'subdomain.name',
      width: 190,
    },
    {
      headerName: 'Avocat référent',
      field: 'referent.name',
      width: 170,
    },
    {
      headerName: 'Avocats experts',
      field: 'experts',
      flex: 1,
      renderCell: ({ value }) => {
        if (value.length === 0) {
          return ''
        }

        if (value.length === 1) {
          return `${value[0].firstName} ${value[0].lastName}`
        }

        return (
          <div>
            <Tooltip
              title={value
                .map((e) => `${e.firstName} ${e.lastName}`)
                .join(', ')}
              arrow
              classes={{ tooltip: 'tooltip' }}
            >
              <span className="badge">{value.length}</span>
            </Tooltip>
          </div>
        )
      },
    },
    {
      headerName: 'Localisation',
      field: 'localisation',
      sortable: false,
      flex: 1,
    },
    {
      headerName: 'Création',
      field: 'createdAt',
      width: 120,
      valueFormatter: ({ value }) => DateTime.fromISO(value).toLocaleString(),
    },
    {
      headerName: 'Modification',
      field: 'updatedAt',
      width: 120,
      valueFormatter: ({ value }) => DateTime.fromISO(value).toLocaleString(),
    },
    {
      headerName: '',
      field: 'action',
      sortable: false,
      renderCell: (params) => (
        <QMuiLinkButton
          to={`/admin/folders/${params.row.id}`}
          variant="text"
          color="transparent"
        >
          <RightArrow width={20} />
        </QMuiLinkButton>
      ),
      width: 100,
    },
  ]

  const debounceSearch = debounce(
    (e) => setSearch(e.target.value !== '' ? e.target.value : null),
    800
  )

  const showFolder = (params) => {
    if (params.field !== 'action') {
      history.push(`/admin/folders/${params.row.id}`)
    }
  }

  return (
    <div className="page-container">
      <Breadcrumb
        items={[
          { label: 'Clients', link: '/admin/clients' },
          { label: client ? `${client.firstName} ${client.lastName}` : '' },
        ]}
      />

      <h1 className="page-title">
        Liste des dossiers
        {client && ` de ${client.firstName} ${client.lastName}`}
      </h1>

      <header className="flex align-items justify-between mb-4">
        <div>
          <QMuiLinkButton
            to={`/admin/clients/${clientId}/folders/new`}
            className="mr-4"
          >
            Ajouter un dossier
          </QMuiLinkButton>
          <QMuiLinkButton to={`/admin/clients/${clientId}`}>
            Fiche client
          </QMuiLinkButton>
        </div>
        <QMuiInput
          onChange={debounceSearch}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon style={{ width: 24, height: 24 }} />
            </InputAdornment>
          }
        />
      </header>

      <QMuiDataGrid
        sortParamsMode="server"
        sortModelPrefix="user"
        onSortParams={setSortParams}
        rows={folders}
        columns={columns}
        loading={isLoading}
        noResultMsg="Aucun dossier pour ce client"
        page={pageNum}
        count={pageCount}
        type="folder"
        apiMode="admin"
        onCellClick={(params) => showFolder(params)}
        handlePaginationChange={(page) =>
          history.push({
            search: `?p=${page}`,
          })
        }
      />
    </div>
  )
}
