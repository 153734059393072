import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import formRules from '@Utils/form-rules'
import DomainsAPI from '@API/admin/parameters/domains'
import ChronologiesAPI from '@API/admin/parameters/chronologies'
import QSelect from '@CommonComponents/QSelect'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import FoldersApi from '@API/admin/folders'
import { useSnackbar } from '@Context/snackbar'
import {
  formatSymfonyValidationErrors,
  getErrorMsgFromSymfonyResponse,
} from '@Helpers'

export default function FolderChronologyForm({
  folder,
  setChronology,
  apiMode,
}) {
  const [domains, setDomains] = useState([])
  const [chronologies, setChronologies] = useState([])
  const [selectedDomain, setSelectedDomain] = useState(null)
  const { control, watch, errors, setError, handleSubmit } = useForm()

  const watchDomain = watch('domain')
  const watchSubdomain = watch('subdomain')

  const { addSnackbarMsg } = useSnackbar()

  useEffect(() => {
    async function fetchDomains() {
      const { data } = await DomainsAPI.getAllDomainsAtOnce(apiMode)
      setDomains(data)
    }

    fetchDomains()
  }, [apiMode])

  useEffect(() => {
    if (typeof watchDomain === 'number') {
      const domain = domains.find(({ id }) => id === watchDomain)
      setSelectedDomain(domain)
    }
  }, [watchDomain, domains])

  useEffect(() => {
    async function fetchChronologies(subdomainId) {
      const { data } = await ChronologiesAPI.getSubdomainChronologies(
        subdomainId,
        apiMode
      )
      setChronologies(data)
    }

    if (typeof watchSubdomain === 'number') {
      fetchChronologies(watchSubdomain)
    }
  }, [watchSubdomain, apiMode])

  const onSubmit = async (data) => {
    try {
      const resp = await FoldersApi.postChronology(
        folder.id,
        {
          chronology: data.chronology,
        },
        apiMode
      )
      setChronology(resp.data)

      addSnackbarMsg({
        severity: 'success',
        content: 'Chronologie enregistrée',
      })
    } catch (error) {
      const resp = error.response

      if (resp.data.message === 'Validation Failed') {
        const formatedErrors = formatSymfonyValidationErrors(resp.data.errors)

        for (let key in formatedErrors) {
          setError(key, formatedErrors[key])
        }
      } else {
        addSnackbarMsg({
          severity: 'error',
          content: getErrorMsgFromSymfonyResponse(error.response),
        })
      }
    }
  }

  return (
    <form className="FolderChronologyForm" onSubmit={handleSubmit(onSubmit)}>
      <h2>Ajout d'un modèle de chronologie</h2>

      <QSelect
        className="mb-6"
        name="domain"
        label="Domaine"
        control={control}
        defaultValue={folder?.subdomain?.domain?.id}
        errorMsg={errors?.domain?.message}
        rules={{ ...formRules.isRequired }}
        options={domains.map(({ id, name }) => ({
          value: id,
          label: name,
        }))}
      />

      <QSelect
        className="mb-6"
        name="subdomain"
        label="Sous-domaine"
        isDisabled={selectedDomain === null}
        control={control}
        rules={{ ...formRules.isRequired }}
        defaultValue={folder?.subdomain?.id}
        errorMsg={errors?.subdomain?.message}
        options={
          selectedDomain
            ? selectedDomain.subdomains.map(({ id, name }) => ({
                value: id,
                label: name,
              }))
            : []
        }
      />

      <QSelect
        className="mb-6"
        name="chronology"
        label="Titre de la chronologie"
        control={control}
        rules={{ ...formRules.isRequired }}
        errorMsg={errors?.chronology?.message}
        noOptionsMessage="Aucune chronologie pour ce sous-domaine"
        options={chronologies.map(({ id, name }) => ({
          value: id,
          label: name,
        }))}
      />

      <div className="flex justify-end">
        <QMuiButton type="submit">Choisir cette chronologie</QMuiButton>
      </div>
    </form>
  )
}
