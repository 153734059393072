import React from 'react'

export default function AskMenuIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28.762" height="23.492">
      <g>
        <path
          d="M11.771 5.185L8.839.491a.526.526 0 00-.442-.242H.775A.526.526 0 00.25.774V16.12a.525.525 0 00.525.525h18.346a.525.525 0 00.525-.525V3.754a.525.525 0 00-.525-.525h-6.338a.526.526 0 00.009 1.051h5.8v11.317H1.301v-11.3h7.217a.45.45 0 00.009-.9H1.301v-2.1H8.11l2.724 4.4a.561.561 0 00.772.144.554.554 0 00.165-.656z"
          fill="#005A79"
          stroke="#005A79"
          strokeWidth=".5"
        />
        <path
          d="M14.308 18.532a7.261 7.261 0 01-2.574-1.778 8.41 8.41 0 01-.408-.476 7.208 7.208 0 01.767-9.815 7.283 7.283 0 115.79 12.56"
          fill="#F0F0F0"
          stroke="#F0F0F0"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M19.783 10.625h1.091a1.222 1.222 0 011.222 1.222h0a1.222 1.222 0 01-1.222 1.223h-2.265v2.114a1.292 1.292 0 01-1.292 1.292h0a1.292 1.292 0 01-1.292-1.292V13.07h-2.244a1.223 1.223 0 01-1.223-1.223h0a1.222 1.222 0 011.223-1.222h2.244V8.506a1.293 1.293 0 011.292-1.293h0a1.292 1.292 0 011.292 1.293v.9"
          fill="#F0F0F0"
          stroke="#005A79"
          strokeLinecap="round"
          strokeMiterlimit="10"
        />
        <path
          d="M11.095 14.728a6.882 6.882 0 112.149 2.637"
          fill="none"
          stroke="#005A79"
          strokeLinecap="round"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  )
}
