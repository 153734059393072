import React from 'react'

export default function EditFile() {
  return (
    <svg
      width="39"
      height="30"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="682 804 39 30"
    >
      <svg
        width="39"
        height="30"
        xmlns="http://www.w3.org/2000/svg"
        fill="#005a79"
        strokeMiterlimit="100"
        strokeLinecap="round"
        stroke="#005a79"
        x="682"
        y="804"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="39" height="30">
          <path
            paintOrder="stroke fill markers"
            fillRule="evenodd"
            d="M37.811 1.31a.547.547 0 00-.029-.033c-.61-.61-1.286-.917-1.998-.913-1.11.006-1.905.778-1.966.842l-6.081 6.079a.469.469 0 00-.12.45l-.362.362-.165-.164-.639.638V.236H.598v29.299h25.853V16.587l4.647-4.646-.165-.166.361-.362a.469.469 0 00.452-.12l5.991-5.992c.038-.036.908-.877.928-2.048.01-.696-.274-1.347-.854-1.944zM25.515 17.522v11.076H1.534V1.171h23.981v8.335l-5.22 5.221.374.375-.126.73H5.648a.468.468 0 100 .937h14.733l-.395 2.29 3.955-.685.361.361 1.213-1.212.468-.468-.468.468zm-4.314 1.073l-.868.163-.06.011.161-.94.203.203.437-2.526 2.463 2.463-2.527.435.19.191zm3.101-1.184l-2.685-2.685 5.473-5.471 2.685 2.685-5.473 5.471zm6.135-5.802L27.42 8.594l.309-.31 3.016 3.016-.31.31zm6.648-6.978l-5.67 5.67-2.687-2.686 5.75-5.75.004-.002c.005-.006.57-.56 1.307-.564.457-.001.897.209 1.322.628.008.01.016.02.022.025.4.407.602.837.596 1.279-.01.776-.631 1.387-.644 1.4z"
          />
        </svg>
      </svg>

      <svg
        width="17"
        height="2"
        xmlns="http://www.w3.org/2000/svg"
        fill="#005a79"
        strokeMiterlimit="100"
        strokeLinecap="round"
        stroke="#005a79"
        x="687"
        y="813"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="2">
          <path
            paintOrder="stroke fill markers"
            fillRule="evenodd"
            d="M.648 1.27h15.753a.468.468 0 000-.936H.648a.467.467 0 100 .936z"
          />
        </svg>
      </svg>

      <svg
        width="17"
        height="2"
        xmlns="http://www.w3.org/2000/svg"
        fill="#005a79"
        strokeMiterlimit="100"
        strokeLinecap="round"
        stroke="#005a79"
        x="687"
        y="826"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="2">
          <path
            paintOrder="stroke fill markers"
            fillRule="evenodd"
            d="M16.401.23H.648a.467.467 0 100 .934h15.753a.467.467 0 100-.935z"
          />
        </svg>
      </svg>

      <svg
        width="6"
        height="6"
        xmlns="http://www.w3.org/2000/svg"
        fill="#005a79"
        strokeMiterlimit="100"
        strokeLinecap="round"
        stroke="#005a79"
        x="709"
        y="805"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6">
          <path
            paintOrder="stroke fill markers"
            fillRule="evenodd"
            d="M.718 5.085c.16.16.42.16.58 0L5.612.773a.41.41 0 000-.58L5.606.186a.409.409 0 00-.58 0L.711 4.497a.412.412 0 000 .581l.006.007z"
          />
        </svg>
      </svg>
    </svg>
  )
}
