import React, { useState, useEffect } from 'react'
import QMuiInput from './Mui/QMuiInput'
import { InputAdornment } from '@material-ui/core'
import { Close } from '@material-ui/icons'

export default function QTag({
  tag,
  index,
  handleUpdate,
  handleDelete,
  containerClasses,
  ...props
}) {
  const [name, setName] = useState(null)

  useEffect(() => {
    setName(tag)
  }, [tag])

  return (
    <div className={containerClasses} style={{ marginRight: 4 }}>
      <QMuiInput
        value={name}
        onChange={(e) => setName(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') handleUpdate(index, e.target.value)
        }}
        className="QTag"
        classNameInput="QTag-input"
        endAdornment={
          <InputAdornment
            style={{ cursor: 'pointer' }}
            onClick={() => handleDelete(index)}
            position="end"
          >
            <Close style={{ width: 24, height: 24 }} />
          </InputAdornment>
        }
        {...props}
      />
    </div>
  )
}
