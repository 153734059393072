import { useState, useEffect, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import ExpertsAPI from '@API/admin/experts'
import formRules from '@Utils/form-rules'
import QSelect from '@CommonComponents/QSelect'
import FolderAPI from '@API/admin/folders'
import { useSnackbar } from '@Context/snackbar'
import {
  formatSymfonyValidationErrors,
  getErrorMsgFromSymfonyResponse,
} from '@Helpers'

export default function ExpertsForm({
  className,
  folder,
  submitForm,
  onDone,
  refreshFolder,
}) {
  const [isInitialized, setIsIntialized] = useState(false)
  const [experts, setExperts] = useState([])
  const { control, errors, setValue, setError, handleSubmit } = useForm()

  const { addSnackbarMsg } = useSnackbar()

  useEffect(() => {
    async function fetchExperts() {
      const { data } = await ExpertsAPI.getAllMinimal()
      setExperts(data)
    }

    if (!isInitialized) {
      fetchExperts()
      setIsIntialized(true)
    }
  }, [isInitialized])

  useEffect(() => {
    setValue('referent', folder.referent.id)
    setValue(
      'experts',
      folder.experts.map((expert) => expert.id)
    )
  }, [folder, setValue])

  const onSubmit = useCallback(
    async (data) => {
      try {
        await FolderAPI.patch(folder.id, data)
        addSnackbarMsg({
          severity: 'success',
          content: 'Dossier modifié',
        })
        onDone()
        refreshFolder()
      } catch (error) {
        const resp = error.response

        if (resp.data.message === 'Validation Failed') {
          const formatedErrors = formatSymfonyValidationErrors(resp.data.errors)

          for (let key in formatedErrors) {
            setError(key, formatedErrors[key])
          }
        } else {
          addSnackbarMsg({
            severity: 'error',
            content: getErrorMsgFromSymfonyResponse(error.response),
          })
        }
      }
    },
    [folder, addSnackbarMsg, onDone, setError, refreshFolder]
  )

  useEffect(() => {
    if (submitForm) {
      handleSubmit(onSubmit)()
    }
  }, [submitForm, handleSubmit, onSubmit])

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <QSelect
        className="mb-6"
        name="referent"
        label="Référent"
        control={control}
        rules={{ ...formRules.isRequired }}
        errorMsg={errors?.referent?.message}
        options={experts.map(({ id, firstName, lastName }) => ({
          label: `${firstName} ${lastName}`,
          value: id,
        }))}
      />

      <QSelect
        name="experts"
        label="Experts"
        isMulti
        control={control}
        rules={{
          validate: (val) =>
            (val && val.length > 0) ||
            'Vous devez sélectionner au moins un expert',
        }}
        errorMsg={errors?.experts?.message}
        options={experts.map(({ id, firstName, lastName }) => ({
          label: `${firstName} ${lastName}`,
          value: id,
        }))}
      />
    </form>
  )
}
