import React, { useState, useRef, useEffect } from 'react'
import { Loop as LoopIcon } from '@material-ui/icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Mousewheel, Scrollbar } from 'swiper/core'

SwiperCore.use([Mousewheel, Scrollbar])

const InfiniteScroll = ({
  children,
  loading,
  hasMore,
  inverse,
  fetch,
  spaceBetween,
  reload,
  slideTo,
  page,
  setPage,
}) => {
  const swiperRef = useRef(null)

  useEffect(() => {
    const fetchPage = async () => {
      const scrollHeight = swiperRef.current.swiper.$el[0].scrollHeight
      await fetch(page)
      const newScrollHeight = swiperRef.current.swiper.$el[0].scrollHeight
      if (inverse) {
        swiperRef.current.swiper.setProgress(1 - scrollHeight / newScrollHeight)
      }
    }
    if (page > 1) {
      fetchPage(page)
    }
  }, [page])

  useEffect(() => {
    if (reload) {
      setPage(1)
    }
  }, [reload])

  const onObserverUpdate = (swiper) => {
    if (page === 1 && inverse) {
      swiper.setProgress(1)
    }
  }

  const onReachBeginning = () => {
    if (hasMore && !loading && inverse) {
      setPage((prevPage) => prevPage + 1)
    }
  }

  const onReachEnd = () => {
    if (hasMore && !loading && !inverse) {
      setPage((prevPage) => prevPage + 1)
    }
  }

  useEffect(() => {
    if (slideTo) {
      swiperRef.current.swiper.slideTo(slideTo)
    }
  }, [slideTo])

  return (
    <div className="infinite-scroll">
      {loading && inverse && (
        <div className="infinite-scroll-loading">
          <LoopIcon />
        </div>
      )}
      <Swiper
        ref={swiperRef}
        onObserverUpdate={onObserverUpdate}
        onReachBeginning={onReachBeginning}
        onReachEnd={onReachEnd}
        observer={true}
        observeParents={true}
        spaceBetween={spaceBetween}
        direction={'vertical'}
        slidesPerView={'auto'}
        mousewheel={true}
        freeMode={true}
        scrollbar={{
          hide: true,
        }}
      >
        {children.map((child) => (
          <SwiperSlide key={child.key}>{child}</SwiperSlide>
        ))}
      </Swiper>
      {loading && !inverse && (
        <div className="infinite-scroll-loading">
          <LoopIcon />
        </div>
      )}
    </div>
  )
}

export default InfiniteScroll
