import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useForm } from 'react-hook-form'
import QFile from '@CommonComponents/QFile'
import formRules from '@Utils/form-rules'
import { AttachFile as AttachFileIcon } from '@material-ui/icons'
import {
  fileToBase64,
  formatSymfonyValidationErrors,
  getErrorMsgFromSymfonyResponse,
} from '@Helpers'
import { useSnackbar } from '@Context/snackbar'
import QMuiButtonConfirmation from '@CommonComponents/Mui/QMuiButtonConfirmation'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'
import StepTypesAPI from '@API/admin/parameters/step-types'
import QMuiSwitch from '@CommonComponents/Mui/QMuiSwitch'

export default function StepType() {
  const [isInitialized, setIsInitialized] = useState(false)
  const [stepType, setStepType] = useState({ companies: [] })
  const [imageFile, setAvatarFile] = useState(null)
  const history = useHistory()

  const {
    register,
    control,
    handleSubmit,
    errors,
    setValue,
    setError,
  } = useForm()

  const params = useParams()

  const { addSnackbarMsg } = useSnackbar()

  const isEditing = !!params.stepTypeId

  useEffect(() => {
    const fetchStepType = async () => {
      if (isEditing) {
        const { data: stepType } = await StepTypesAPI.getStepType(
          params.stepTypeId
        )
        setStepType(stepType)

        for (let key in stepType) {
          setValue(key, stepType[key])
        }
      }
      setIsInitialized(true)
    }

    if (!isInitialized) {
      fetchStepType()
    }
  }, [isInitialized, stepType, setValue, params, isEditing])

  const onSubmit = async (data) => {
    if (imageFile != null) {
      data.fileName = imageFile.name
      data.fileContent = await fileToBase64(imageFile)
    }

    try {
      let resp = null
      if (!isEditing) {
        resp = await StepTypesAPI.postStepType(data)
      } else {
        resp = await StepTypesAPI.patchStepType(params.stepTypeId, data)
      }

      setStepType(resp.data)

      addSnackbarMsg({
        severity: 'success',
        content: `Type d'étape ${isEditing ? 'modifié' : 'créé'}`,
      })
      history.push('/admin/parameters/step-types')
    } catch (error) {
      const resp = error.response

      if (resp.data.message === 'Validation Failed') {
        const formatedErrors = formatSymfonyValidationErrors(resp.data.errors)

        for (let key in formatedErrors) {
          setError(key, formatedErrors[key])
        }

        onError(formatedErrors)
      } else {
        addSnackbarMsg({
          severity: 'error',
          content: getErrorMsgFromSymfonyResponse(error.response),
        })
      }
    }
  }

  const onError = (errors) => {
    for (let key in errors) {
      if (['fileName', 'fileContent'].includes(key)) {
        addSnackbarMsg({
          severity: 'error',
          content: errors[key].message,
        })
      }
    }
  }

  return (
    <motion.div
      className="page-thin-container"
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="page-title">
        {isEditing ? 'Modifier' : 'Ajouter'} un type d'étape
      </h1>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <section className="page-section">
          <div className="card">
            <div className="flex align-center p-4">
              {stepType.image && (
                <img
                  className="profile-pic"
                  src={stepType.image}
                  alt="Profile"
                />
              )}

              <QFile
                color="secondary"
                file={imageFile}
                onChange={setAvatarFile}
              >
                <span>Image</span>
                <AttachFileIcon />
              </QFile>
            </div>

            <QMuiFormInput
              className="form-input isFullWidth"
              label="Nom"
              name="name"
              register={register({ ...formRules.isRequired })}
              control={control}
              errorMsg={errors?.name?.message}
            />

            <div className="flex justify-end p-2">
              <label>
                Évènement&nbsp;:
                <QMuiSwitch name="isMeeting" control={control} />
              </label>
            </div>
          </div>
        </section>

        <div className="flex justify-end">
          <QMuiButtonConfirmation
            btnText="Enregistrer"
            confirmation="Êtes-vous sûr de vouloir enregistrer ?"
            type="submit"
          >
            Enregistrer
          </QMuiButtonConfirmation>
        </div>
      </form>
    </motion.div>
  )
}
