import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import UserApi from '@/api/user'
import { debounce } from 'lodash'
import { useSnackbar } from '@Context/snackbar'
import { getErrorMsgFromSymfonyResponse } from '@Helpers'
import QMuiInput from '@Components/common/Mui/QMuiInput'
import QMuiLinkButton from '@Components/common/Mui/QMuiLinkButton'
import QMuiButtonConfirmation from '@Components/common/Mui/QMuiButtonConfirmation'
import QMuiDataGrid from '@Components/common/Mui/QMuiDataGrid'
import Plus from '@Components/icons/Plus'
import { InputAdornment } from '@material-ui/core'
import SearchIcon from '@Components/icons/SearchIcon'
import Update from '@Components/icons/Update'

export default function UserList() {
  const [users, setUsers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [sortParams, setSortParams] = useState(null)
  const [search, setSearch] = useState('')
  const [pageCount, setPageCount] = useState(1)

  const { addSnackbarMsg } = useSnackbar()
  const history = useHistory()

  const pageNum = parseInt(
    new URLSearchParams(useLocation().search).get('p') || 1
  )

  const showUser = (params) => {
    if (params.field !== 'action') {
      history.push(`/admin/users/${params.id}`)
    }
  }

  const deleteUser = async (id) => {
    try {
      await UserApi.deleteAdministrator(id)
      setUsers(users.filter((user) => user.id !== id))
      addSnackbarMsg({
        severity: 'error',
        content: 'Utilisateur supprimé',
      })
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await UserApi.getAdministrators(
        pageNum,
        sortParams,
        search
      )

      const count = Math.ceil(data.meta.total_items / data.meta.items_per_page)

      setPageCount(count)
      setUsers(data.results)
      setIsLoading(false)
    }

    fetchData()
  }, [pageNum, sortParams, search])

  const columns = [
    {
      headerName: 'Photo',
      field: 'image',
      width: 120,
      sortable: false,
      renderCell: (params) =>
        params.row.image ? (
          <img className="profile-pic" src={params.row.image} alt="Profile" />
        ) : null,
    },
    {
      headerName: 'Nom',
      field: 'firstName',
      flex: 1,
    },
    {
      headerName: 'Prénom',
      field: 'lastName',
      flex: 1,
    },
    {
      headerName: 'Statut',
      field: 'role',
      width: 120,
      sortable: false,
    },
    {
      headerName: 'Email',
      field: 'email',
      flex: 1,
    },
    {
      headerName: 'Téléphone',
      field: 'phone',
      flex: 1,
    },
    {
      headerName: '',
      disableClickEventBubbling: false,
      field: 'action',
      width: 270,
      renderCell: (params) => (
        <div>
          <QMuiLinkButton
            to={`/admin/users/${params.row.id}`}
            size="small"
            className="mr-3"
          >
            Modifier
            <Update width={13} style={{ marginLeft: 5 }} />
          </QMuiLinkButton>
          <QMuiButtonConfirmation
            color="danger"
            size="small"
            handleConfirmation={() => deleteUser(params.row.id)}
            confirmation="Voulez-vous vraiment supprimer cet utilisateur ?"
          >
            Supprimer
          </QMuiButtonConfirmation>
        </div>
      ),
    },
  ]

  const debounceSearch = debounce(async (e) => setSearch(e.target.value), 800)

  return (
    <div className="page-container">
      <h1 className="page-title">Gestion des utilisateurs</h1>

      <header className="flex justify-end">
        <QMuiInput
          style={{ marginRight: 10 }}
          onChange={debounceSearch}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon style={{ width: 24, height: 24 }} />
            </InputAdornment>
          }
        />

        <QMuiLinkButton to="/admin/users/new">
          Ajout nouvel utilisateur
          <Plus width={15} style={{ marginLeft: 12 }} />
        </QMuiLinkButton>
      </header>

      <QMuiDataGrid
        sortParamsMode="server"
        sortModelPrefix="user"
        onSortParams={setSortParams}
        rows={users}
        columns={columns}
        rowHeight={80}
        loading={isLoading}
        noResultMsg="Aucun utilisateur"
        page={pageNum}
        count={pageCount}
        onCellClick={(params) => showUser(params)}
        handlePaginationChange={(page) =>
          history.push({
            search: `?p=${page}`,
          })
        }
      />
    </div>
  )
}
