import React, { useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import Header from '@Components/Header'
import AdminMenu from '@Components/AdminMenu.jsx'
import { motion } from 'framer-motion'

import UserList from '@Pages/admin/UserList'
import UserEdit from '@Pages/admin/UserEdit'
import FolderEdit from '@Pages/admin/FolderEdit'
import ClientFolderList from '@Pages/admin/ClientFolderList'
import LawyerFolderList from '@Pages/admin/LawyerFolderList'
import Lawyer from '@Pages/admin/Lawyer'
import LawyerList from '@Pages/admin/LawyerList'
import Client from '@Pages/admin/Client'
import Request from '@Pages/admin/Request'
import Messages from '@Pages/admin/Messages'
import ClientList from '@Pages/admin/ClientList'
import FolderList from '@Pages/admin/FolderList'
import Folder from '@Components/folder/Folder'
import Parameters from '@Pages/admin/parameters/Parameters'

export default function Admin() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <motion.div exit={{ opacity: 0 }}>
      <Header
        isMenuToggle
        isMenuOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(!isMenuOpen)}
      />

      <div className="flex">
        <AdminMenu isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} />

        <div className="Admin-container">
          <Switch>
            <Route exact path="/admin/users">
              <UserList />
            </Route>

            <Route exact path="/admin/users/new">
              <UserEdit />
            </Route>

            <Route exact path="/admin/users/:userId">
              <UserEdit />
            </Route>

            <Route exact path="/admin/lawyers">
              <LawyerList />
            </Route>

            <Route exact path="/admin/lawyers/new">
              <Lawyer />
            </Route>

            <Route exact path="/admin/lawyers/:lawyerId">
              <Lawyer />
            </Route>

            <Route exact path="/admin/lawyers/:lawyerId/folders">
              <LawyerFolderList />
            </Route>

            <Route exact path="/admin/clients">
              <ClientList />
            </Route>

            <Route exact path="/admin/clients/new">
              <Client apiMode="admin" />
            </Route>

            <Route exact path="/admin/clients/:clientId">
              <Client apiMode="admin" />
            </Route>

            <Route exact path="/admin/clients/:clientId/folders">
              <ClientFolderList apiMode="admin" />
            </Route>

            <Route exact path="/admin/clients/:clientId/folders/new">
              <FolderEdit />
            </Route>

            <Route exact path="/admin/clients/:clientId/folders/:folderId">
              <FolderEdit />
            </Route>

            <Route exact path="/admin/folders">
              <FolderList />
            </Route>

            <Route path="/admin/folders/:folderId">
              <Folder apiMode="admin" />
            </Route>

            <Route path="/admin/parameters">
              <Parameters />
            </Route>

            <Route exact path="/admin/requests">
              <Request />
            </Route>

            <Route exact path="/admin/messages">
              <Messages />
            </Route>
          </Switch>
        </div>
      </div>
    </motion.div>
  )
}
