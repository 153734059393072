import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { makeStyles } from '@material-ui/core/styles'
import SchemaItem from '@Components/SchemaItem'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import ChronologiesAPI from '@API/admin/parameters/chronologies'
import classnames from 'classnames'
import { useSnackbar } from 'src/context/snackbar'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginLeft: 50,
    '& .MuiTimelineItem-missingOppositeContent:before': {
      flex: 0,
      padding: '0',
    },
  },
  dotTimeline: {
    backgroundColor: 'orange',
    width: 10,
    height: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    borderRadius: 50,
    color: 'white',
  },
  separator: {
    background: 'orange',
  },
  schema: {
    padding: 10,
    background: 'orange',
  },
}))

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  padding: '0 10px',
  height: 100,
  display: 'flex',
  alignItems: 'center',
  borderRadius: '10px 0 0 10px',
  userSelect: 'none',
  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({})

export default function ChronoTimeline({
  chronology,
  onClickStep,
  onDragError,
  onDragSuccess,
  stepSelected,
  apiMode,
}) {
  const classes = useStyles()
  const [items, setItems] = useState([])
  const [draggingItemIndex, setDraggingItemIndex] = useState(null)

  const { addSnackbarMsg } = useSnackbar()

  useEffect(() => {
    const steps = chronology?.steps || []
    const copySteps = [...steps]
    copySteps.sort((a, b) => a.rank - b.rank)
    setItems(copySteps)
  }, [chronology])

  const onDragEnd = async (result) => {
    setDraggingItemIndex(null)
    const { destination, source } = result

    if (!destination) return

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return

    const sourceItem = items[source.index]
    const destinationItem = items[destination.index]

    const copyItems = Array.from(items)
    copyItems.splice(source.index, 1)
    copyItems.splice(destination.index, 0, sourceItem)
    setItems(copyItems)

    try {
      await ChronologiesAPI.patchStepChronologie(
        sourceItem.id,
        {
          rank: destinationItem.rank,
        },
        apiMode
      )
      onDragSuccess(sourceItem.id)
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: 'Une erreur est survenue',
      })

      const rollbackCopyItems = Array.from(copyItems)
      rollbackCopyItems.splice(destination.index, 1)
      rollbackCopyItems.splice(source.index, 0, sourceItem)
      setItems(rollbackCopyItems)
      onDragError(sourceItem.id)
    }
  }

  const onDragStart = ({ source }) => {
    setDraggingItemIndex(source.index)
  }

  return (
    <div className={classes.root}>
      <div className="ChronoTimeline-stepNums">
        {items.map((item, index) => (
          <div
            key={item.id}
            className={classnames('ChronoTimeline-stepNum', {
              isLastStep:
                index === items.length - 1 ||
                (draggingItemIndex === items.length - 2 &&
                  index === items.length - 2),
              pending: item.startedAt,
            })}
          >
            {index + 1}
          </div>
        ))}
      </div>
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {items.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={`${item.rank}`}
                  isDragDisabled={apiMode === 'customer'}
                  index={index}
                >
                  {(provided, snapshot) => {
                    const child = (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          ),
                        }}
                      >
                        <SchemaItem
                          onClick={onClickStep}
                          step={item}
                          className={
                            stepSelected?.id === item.id
                              ? 'SchemaItem active'
                              : 'SchemaItem'
                          }
                        />
                      </div>
                    )

                    return snapshot.isDragging
                      ? createPortal(
                          child,
                          document.getElementById('modal-root')
                        )
                      : child
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
