import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from '@Context/auth.js'
import { FormControl } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import LawyerAPI from '@API/admin/experts'
import { patchProfile } from '@API/expert/expert'

import { searchCities } from '@API/address'

import QSelect from '@CommonComponents/QSelect'
import QMuiImageForm from '@CommonComponents/Mui/QMuiImageForm'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'
import QMuiFormInputTags from '@CommonComponents/Mui/QMuiFormInputTags'
import QMuiTextarea from '@CommonComponents/Mui/QMuiTextarea'
import QMuiButtonConfirmation from '@CommonComponents/Mui/QMuiButtonConfirmation'
import SubdomainForm from '@Components/forms/SubdomainForm'
import formRules from '@Utils/form-rules'
import {
  getAllPaths,
  fileToBase64,
  formatSymfonyValidationErrors,
  getErrorMsgFromSymfonyResponse,
} from '@Helpers'
import { useSnackbar } from '@Context/snackbar'

export default function ExpertProfileForm({
  expert,
  domains,
  isCreation,
  apiMode,
}) {
  const { setUser } = useAuth()
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    setError,
    control,
    clearErrors,
  } = useForm({
    defaultValues: {
      'professionalExperience.studies': [],
    },
  })
  const [interventionSectors, setInterventionSectors] = useState([])
  const { addSnackbarMsg } = useSnackbar()
  const history = useHistory()

  useEffect(() => {
    getAllPaths(expert).forEach((path) => {
      let value = get(expert, path)
      if (path === 'professionalInformation.subdomains') {
        value = value.map((subdomain) => subdomain.id)
      }
      if (path === 'professionalInformation.interventionSectors') {
        setInterventionSectors(
          value.map((is) => {
            return {
              label: is,
              value: is,
            }
          })
        )
      }
      setValue(path, value)
    })
  }, [expert, setValue])

  const onSubmit = async ({ confirmPassword, imageFile, ...data }) => {
    if (imageFile) {
      data.fileName = imageFile.name
      data.fileContent = await fileToBase64(imageFile)
    }
    let response
    try {
      if (!isCreation) {
        if (apiMode === 'admin') {
          response = await LawyerAPI.patchExpert(expert.id, data)
        }
        if (apiMode === 'expert') {
          response = await patchProfile(data)
          setUser(response.data)
        }
      } else {
        response = await LawyerAPI.postExpert(data)
      }

      if (apiMode === 'admin') {
        addSnackbarMsg({
          severity: 'success',
          content: `Avocat ${isCreation ? 'créé' : 'modifié'}`,
        })
      } else {
        addSnackbarMsg({
          severity: 'success',
          content: 'Profil modifié',
        })
      }

      history.push(`/admin/lawyers/${response.data.id}/folders`)
    } catch (error) {
      const resp = error.response

      if (resp.data.message === 'Validation Failed') {
        const formatedErrors = formatSymfonyValidationErrors(resp.data.errors)

        for (let key in formatedErrors) {
          setError(key, formatedErrors[key])
        }

        onError(formatedErrors)
      } else {
        addSnackbarMsg({
          severity: 'error',
          content: getErrorMsgFromSymfonyResponse(error.response),
        })
      }
    }
  }

  const onError = (errors) => {
    for (let key in errors) {
      if (['fileName', 'fileContent'].includes(key)) {
        clearErrors(key)
        setError('imageFile', errors[key])
        addSnackbarMsg({
          severity: 'error',
          content: errors[key].message,
        })
      }
    }
  }

  const getCities = async (q) => {
    const data = []
    if (q.length >= 2) {
      const response = await searchCities(q)
      response?.data?.features?.forEach((f) => {
        data.push({
          label: f.properties.city,
          value: f.properties.city,
        })
      })
    }
    return data
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <section className="page-section">
        <h2>Informations personnelles</h2>
        <div className="card">
          <div className="flex align-center p-4">
            {expert?.image && (
              <img src={expert.image} className="profile-pic" alt="Profile" />
            )}

            <QMuiImageForm control={control} name="imageFile" />
          </div>

          <fieldset>
            <QSelect
              className="form-field"
              name="civility"
              label="Civilité*"
              control={control}
              options={[
                { label: 'Mr', value: 'm' },
                { label: 'Mme', value: 'mme' },
              ]}
              errorMsg={errors?.civility?.message}
            />

            <QMuiFormInput
              className="form-field"
              label="Nom*"
              name="lastName"
              register={register({ ...formRules.isRequired })}
              control={control}
              errorMsg={errors?.lastName?.message}
            />

            <QMuiFormInput
              className="form-field"
              label="Prénom*"
              name="firstName"
              register={register({ ...formRules.isRequired })}
              control={control}
              errorMsg={errors?.firstName?.message}
            />

            <QMuiFormInput
              className="form-field"
              label="Téléphone*"
              name="phone"
              register={register({ ...formRules.isRequired })}
              control={control}
              errorMsg={errors?.phone?.message}
            />

            <QMuiFormInput
              className="form-field"
              label="Email*"
              name="email"
              register={register({ ...formRules.isRequired })}
              control={control}
              errorMsg={errors?.email?.message}
            />
          </fieldset>
        </div>
      </section>

      <section className="page-section">
        <h2>Informations bancaires</h2>

        <div className="card">
          <fieldset>
            <QMuiFormInput
              className="form-field"
              label="Nom"
              name="bankingInformation.lastName"
              register={register}
              control={control}
              errorMsg={errors?.bankingInformation?.lastName?.message}
            />

            <QMuiFormInput
              className="form-field"
              label="Prénom"
              name="bankingInformation.firstName"
              register={register}
              control={control}
              errorMsg={errors?.bankingInformation?.firstName?.message}
            />

            <QMuiFormInput
              className="form-field"
              label="IBAN"
              name="bankingInformation.iban"
              register={register}
              control={control}
              errorMsg={errors?.bankingInformation?.iban?.message}
            />

            <QMuiFormInput
              className="form-field"
              label="BIC"
              name="bankingInformation.bic"
              register={register}
              control={control}
              errorMsg={errors?.bankingInformation?.bic?.message}
            />
          </fieldset>
        </div>
      </section>

      <section className="page-section">
        <h2>Informations professionnelles</h2>

        <div className="card">
          <fieldset>
            <QMuiFormInput
              className="form-field"
              label="Raison sociale"
              name="professionalInformation.socialReason"
              register={register}
              control={control}
              errorMsg={errors?.professionalInformation?.socialReason?.message}
            />

            <QMuiFormInput
              className="form-field"
              label="SIREN*"
              name="professionalInformation.siren"
              register={register({ ...formRules.isRequired })}
              control={control}
              errorMsg={errors?.professionalInformation?.siren?.message}
            />

            <QMuiFormInput
              className="form-field"
              label="Adresse*"
              name="professionalInformation.address.street"
              register={register({ ...formRules.isRequired })}
              control={control}
              errorMsg={
                errors?.professionalInformation?.address?.street?.message
              }
            />

            <QMuiFormInput
              className="form-field"
              label="Code postal*"
              name="professionalInformation.address.postalCode"
              register={register({ ...formRules.isRequired })}
              control={control}
              errorMsg={
                errors?.professionalInformation?.address?.postalCode?.message
              }
            />

            <QMuiFormInput
              className="form-field"
              label="Ville*"
              name="professionalInformation.address.city"
              register={register({ ...formRules.isRequired })}
              control={control}
              errorMsg={errors?.professionalInformation?.address?.city?.message}
            />

            <QMuiFormInput
              className="form-field"
              label="Numéro CNB*"
              name="professionalInformation.cnbNumber"
              register={register({ ...formRules.isRequired })}
              control={control}
              errorMsg={errors?.professionalInformation?.cnbNumber?.message}
            />

            <QMuiFormInput
              className="form-field"
              label="Barreau*"
              name="professionalInformation.bar"
              register={register({ ...formRules.isRequired })}
              control={control}
              errorMsg={errors?.professionalInformation?.bar?.message}
            />
            <FormControl className="form-field">
              <QSelect
                async
                label="Villes d'intervention"
                name="professionalInformation.interventionSectors"
                errorMsg={
                  errors?.professionalInformation?.interventionSectors?.message
                }
                control={control}
                value={interventionSectors}
                defaultValue={interventionSectors}
                onChange={setInterventionSectors}
                loadOptions={getCities}
                isMulti
              />
            </FormControl>

            <SubdomainForm
              domains={domains}
              errors={errors}
              control={control}
              name="professionalInformation.subdomains"
            />
          </fieldset>
        </div>
      </section>

      <section className="page-section">
        <h2>Presentation avocat rubrique "Equipe"</h2>

        <div className="card p-3">
          <QMuiTextarea
            rowsMin={3}
            label="Description"
            name="presentation"
            register={register()}
            control={control}
            errorMsg={errors?.description?.message}
          />
        </div>
      </section>

      <section className="page-section">
        <h2>Experience professionnelle</h2>

        <div className="card">
          <fieldset>
            <QMuiFormInput
              label="Serment"
              className="form-input isFullWidth mb-2"
              control={control}
              register={register()}
              name="professionalExperience.oath"
            />

            <QMuiFormInputTags
              label="Parcours universitaires"
              className="form-input isFullWidth mb-2"
              control={control}
              name="professionalExperience.studies"
            />

            <QMuiFormInputTags
              label="Experiences professionnelles"
              className="form-input isFullWidth mb-2"
              control={control}
              name="professionalExperience.experiences"
            />

            <QMuiFormInputTags
              label="Langues"
              className="form-input isFullWidth"
              control={control}
              name="professionalExperience.languages"
            />
          </fieldset>
        </div>
      </section>

      <div className="flex justify-end">
        <QMuiButtonConfirmation
          btnText="Enregistrer"
          confirmation="Êtes-vous sûr de vouloir enregistrer ?"
          type="submit"
        >
          Enregistrer
        </QMuiButtonConfirmation>
      </div>
    </form>
  )
}
