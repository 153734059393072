import React from 'react'

export default function Messages() {
  return (
    <svg
      className="message"
      xmlns="http://www.w3.org/2000/svg"
      width="20.092"
      height="14.049"
    >
      <g
        transform="translate(-34.553 -472.391)"
        fill="none"
        stroke="#005a79"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      >
        <rect
          width="18.592"
          height="12.547"
          rx="1"
          transform="translate(35.303 473.143)"
        />
        <path d="M51.177 475.572l-1.93 1.724-3.242 2.9a2.11 2.11 0 01-2.812 0l-7.018-6.743" />
        <path d="M46.971 479.417l6.18 5.844-1.043.411h-15.3l-.445-.411 3.59-3.809.277-.247" />
      </g>
    </svg>
  )
}
