import React from 'react'
import { useAuth } from '@Context/auth'
import CustomerProfileForm from '@Components/forms/CustomerProfileForm'
import DocumentsGestion from '@Components/DocumentsGestion'

export default function CustomerProfile() {
  const { user } = useAuth()
  return (
    <div className="page-container page-thin-container">
      <h1 className="page-title">Votre profil</h1>
      <CustomerProfileForm customer={user} />
      <section className="page-section">
        <DocumentsGestion
          title="Documents liés à la page gestion"
          apiMode="customer"
          mode="customers"
          id={user.id}
        />
      </section>
    </div>
  )
}
