import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { SketchPicker } from 'react-color'
import classNames from 'classnames'

const WrapperColorPicker = ({
  control,
  name = 'color',
  className,
  onChangeComplete,
}) => {
  return (
    <Controller
      {...{ name, control }}
      render={({ onChange, value }) => (
        <QColorPicker
          color={value}
          className={className}
          onChange={onChange}
          onChangeComplete={onChangeComplete}
        />
      )}
    />
  )
}

const QColorPicker = ({ color, onChange, onChangeComplete, className }) => {
  const [pickerIsShow, togglePicker] = useState(false)

  const containerClasses = classNames('QColorPicker', className)

  const handleClose = () => {
    togglePicker(false)
  }

  return (
    <div className={containerClasses}>
      <div
        className="QColorPicker-swatch"
        onClick={() => togglePicker(!pickerIsShow)}
        style={{ backgroundColor: color }}
      />

      {pickerIsShow ? (
        <div className="QColorPicker-popover">
          <div className="QColorPicker-cover" onClick={handleClose} />
          <SketchPicker
            color={color}
            onChange={({ hex }) => onChange(hex)}
            onChangeComplete={onChangeComplete}
          />
        </div>
      ) : null}
    </div>
  )
}

export default WrapperColorPicker
