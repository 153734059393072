import React, { useEffect, useState } from 'react'
import { useSnackbar } from '@Context/snackbar'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import QMuiButtonConfirmation from '@CommonComponents/Mui/QMuiButtonConfirmation'
import DocumentAddForm from '@Components/forms/DocumentAddForm'
import {
  Add as AddIcon,
  InsertDriveFile as FileIcon,
  GetApp as DownloadIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'
import {
  getDocuments,
  getDocumentBlob,
  downloadDocument,
  deleteDocument,
} from '@API/documents'
import { getErrorMsgFromSymfonyResponse } from '@Helpers'

export default function DocumentsGestion({ title, apiMode, id, mode }) {
  const { addSnackbarMsg } = useSnackbar()
  const [documents, setDocuments] = useState([])
  const [showAddModal, setShowAddModal] = useState(false)

  useEffect(() => {
    const fetchDocuments = async () => {
      const response = await getDocuments(apiMode, id, mode)
      setDocuments(response.data.results)
    }
    fetchDocuments()
  }, [setDocuments])

  const showDoc = async (id) => {
    try {
      const response = await getDocumentBlob(apiMode, id)
      const blobUrl = URL.createObjectURL(response.data)
      window.open(blobUrl)
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  const downloadDoc = async (doc) => {
    try {
      await downloadDocument(apiMode, doc.id, doc.name)
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  const onClose = () => {
    setShowAddModal(false)
  }

  const onAdd = (document) => {
    setDocuments((current) => [document, ...current])
    setShowAddModal(false)
  }

  const onDelete = async (id) => {
    try {
      const response = await deleteDocument(apiMode, id)
      setDocuments((current) =>
        current.filter((c) => {
          return c.id !== id
        })
      )
      addSnackbarMsg({
        severity: 'success',
        content: response.data.message,
      })
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  return (
    <div>
      <div className="mb-4 flex flex-row justify-between">
        <h2 className="my-0">{title}</h2>
        <div className="flex align-items-center">
          <QMuiButton
            onClick={() => setShowAddModal(true)}
            endIcon={<AddIcon />}
          >
            Ajouter
          </QMuiButton>
        </div>
      </div>
      {documents.length > 0 && (
        <ul className="documents-list p-0">
          {documents?.map((d) => (
            <li key={d.id} className="flex align-items-center justify-between">
              <div className="flex align-items-center">
                <QMuiButton
                  variant="outlined"
                  className="isSecondary"
                  isIcon
                  onClick={() => showDoc(d.id)}
                >
                  <FileIcon />
                </QMuiButton>
                <div className="ml-3">
                  <h4 className="document-title mb-0">{d.name}</h4>
                  <div className="document-description">{d.description}</div>
                </div>
              </div>
              <div>
                <QMuiButton
                  variant="outlined"
                  className="isPrimary"
                  isIcon
                  onClick={() => downloadDoc(d)}
                >
                  <DownloadIcon />
                </QMuiButton>
                {apiMode === 'admin' && (
                  <QMuiButtonConfirmation
                    variant="outlined"
                    className="isDanger"
                    isIcon
                    confirmation="Etes vous sur de vouloir supprimer ce document ?"
                    handleConfirmation={() => onDelete(d.id)}
                  >
                    <DeleteIcon />
                  </QMuiButtonConfirmation>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
      <DocumentAddForm
        open={showAddModal}
        onClose={onClose}
        onAdd={onAdd}
        apiMode={apiMode}
        mode={mode}
        id={id}
      />
    </div>
  )
}
