import React, { useEffect, useRef } from 'react'
import QMuiInput from '@CommonComponents/Mui/QMuiInput'
import { useForm } from 'react-hook-form'
import QColorPicker from '@CommonComponents/QColorPicker'
import { patchNote } from '@API/admin/parameters'
import { useSnackbar } from 'src/context/snackbar'
import { debounce } from 'lodash'
import { Close } from '@material-ui/icons'
import QMuiButtonConfirmation from '@CommonComponents/Mui/QMuiButtonConfirmation'

/**
 * @param {*} data
 * @param {*} data.name
 * @param {*} data.color
 */
export default function NoteItem({ onDelete, data }) {
  const formRef = useRef(null)
  const inputRef = useRef(null)
  const alertContext = useSnackbar()

  const {
    register,
    control,
    handleSubmit,
    getValues,
    watch,
    formState,
  } = useForm({
    defaultValues: {
      color: data.color,
    },
  })
  const colorWatch = watch('color')

  useEffect(() => {
    const debounceHandleColorChange = debounce(() => {
      if (formState.isDirty) {
        inputRef.current.click()
      }
    }, 800)
    debounceHandleColorChange()
    return () => debounceHandleColorChange.cancel()
  }, [colorWatch, formState.isDirty])

  const onSubmit = async (form) => {
    const response = await patchNote(data.id, form)

    if (response.status === 200) {
      alertContext.addSnackbarMsg({
        severity: 'success',
        content: 'La note a ete modifiée',
      })
    }
  }

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)} className="NoteItem">
      <QColorPicker color={getValues('color')} {...{ control }} />
      <QMuiInput
        name="name"
        ref={register}
        defaultValue={data.name}
        className="NoteItem-name"
        noStyle
      />
      <QMuiButtonConfirmation
        confirmation="Etes vous sur de vouloir supprimer la note ?"
        handleConfirmation={() => onDelete(data.id)}
        color="iconDanger"
      >
        <Close />
      </QMuiButtonConfirmation>
      <input ref={inputRef} type="submit" style={{ display: 'none' }} />
    </form>
  )
}
