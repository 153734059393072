import qualyAvocats from './qualy-avocats'
import lkAvocats from './lk-avocats'

const variantKey = process.env.REACT_APP_VARIANT || 'qualy-avocats'

const variants = {
  'qualy-avocats': qualyAvocats,
  'lk-avocats': lkAvocats,
}

export default variants[variantKey]
