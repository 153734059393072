import React from 'react'

export default function Lawyers() {
  return (
    <svg
      className="lawyer"
      xmlns="http://www.w3.org/2000/svg"
      width="13.539"
      height="18.857"
    >
      <g data-name="Group 3097" fill="#005a79">
        <path
          data-name="Path 3740"
          d="M10.431 9.155l-.055-.047a5.278 5.278 0 10-7.231.014l-.038.033C1.294 10.69.333 11.688-.002 15.982a2.1 2.1 0 001.245 1.823 15.054 15.054 0 0011.053 0 2.1 2.1 0 001.241-1.882c-.33-4.235-1.291-5.233-3.106-6.768zM2.983 5.278a3.77 3.77 0 113.77 3.77 3.774 3.774 0 01-3.768-3.77zM1.504 16.041c.3-3.8 1.059-4.45 2.576-5.735l.348-.3c.106.053.213.1.323.149l1.247 1.715-1.505 3.463 1.657 2.025a9.875 9.875 0 01-4.151-.857c-.147-.087-.491-.308-.492-.46zm10.036.458a9.906 9.906 0 01-4.183.858l1.657-2.026-1.508-3.456 1.247-1.715c.117-.048.231-.1.343-.159l.359.3c1.517 1.286 2.279 1.93 2.575 5.677.001.215-.34.436-.487.521z"
        />
        <path
          data-name="Path 3741"
          d="M6.771 3.392a1.872 1.872 0 011.869 1.87.377.377 0 00.754 0 2.627 2.627 0 00-2.623-2.624.377.377 0 100 .754z"
        />
        <path
          data-name="Path 3742"
          d="M9.186 11.725a.377.377 0 00-.338.675c1.3.649 1.3 2.744 1.3 3.432a.377.377 0 10.754 0c0-.803 0-3.248-1.716-4.107z"
        />
      </g>
    </svg>
  )
}
