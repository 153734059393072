import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { getCustomerById } from '@API/admin/customers'
import FolderSynthesis from '@Components/FolderSynthesis'

export default function Synthesis({ folder, apiMode }) {
  const [customer, setCustomer] = useState(null)

  useEffect(() => {
    async function fetchCustomer() {
      const { data } = await getCustomerById(folder.customer.id, apiMode)
      setCustomer(data)
    }
    if (folder) {
      fetchCustomer()
    }
  }, [folder, apiMode])

  return (
    <div className="Synthesis">
      <div className="Synthesis-container my-7">
        <section className="Synthesis-infos">
          <h2>Informations dossier</h2>
          <dl>
            <div>
              <dt>Objet de la demande</dt>
              <dd>{folder?.name}</dd>
            </div>
            <div>
              <dt>Contentieux / Conseil</dt>
              <dd></dd>
            </div>
            <div>
              <dt>Domaine</dt>
              <dd>{folder?.subdomain?.domain?.name}</dd>
            </div>
            <div>
              <dt>Sous domaine</dt>
              <dd>{folder?.subdomain?.name}</dd>
            </div>
            <div>
              <dt>Date</dt>
              <dd>
                {folder?.createdAt &&
                  DateTime.fromISO(folder?.createdAt).toLocaleString()}
              </dd>
            </div>
          </dl>
        </section>

        <section className="Synthesis-infos">
          <h2>Informations client</h2>
          <dl>
            <div>
              <dt>Nom Prénom</dt>
              <dd>
                {customer?.lastName} {customer?.firstName}
              </dd>
            </div>
            <div>
              <dt>Email</dt>
              <dd>{customer?.email}</dd>
            </div>
            <div>
              <dt>Téléphone</dt>
              <dd>{customer?.phone}</dd>
            </div>
            <div>
              <dt>Adresse</dt>
              <dd>
                {customer?.address?.street}
                <br />
                {customer?.address.postalCode}&nbsp;{customer?.address?.city}
              </dd>
            </div>
            {folder?.company && (
              <div>
                <dt>Entreprise</dt>
                <dd>{folder?.company?.name}</dd>
              </div>
            )}
          </dl>
        </section>
      </div>

      <FolderSynthesis folder={folder} apiMode={apiMode} />
    </div>
  )
}
