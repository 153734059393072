import QMuiInput from '@CommonComponents/Mui/QMuiInput'
import React, { useRef, useState } from 'react'
import NoteItem from './NoteItem'
import collapseIcon from '@Assets/img/collapse.png'
import QColorPicker from '@CommonComponents/QColorPicker'
import { useForm } from 'react-hook-form'

export default function NoteCategoryInput({ onDelete, notes, onAdd }) {
  const formRef = useRef(null)
  const [bodyShow, collapseBody] = useState(true)
  const { setValue, control, register, handleSubmit } = useForm({
    defaultValues: {
      color: '#000',
    },
  })

  const handleKeyPress = (key) => {
    if (key.value === 'Enter') {
      formRef.current.submit()
    }
  }

  const onSubmit = (form) => {
    onAdd(form)
    setValue('name', '')
    setValue('color', '#000')
  }

  return (
    <div className="NoteCategoryInput">
      <header className="flex align-center justify-between">
        <h4>Selection</h4>
        <img
          onClick={() => collapseBody(!bodyShow)}
          className="NoteCategoryInput-icon"
          src={collapseIcon}
          alt="icon"
        />
      </header>
      {bodyShow ? (
        <div>
          <div>
            <ul className="is-unstyled mt-2">
              {notes.map((note) => (
                <li key={note.id}>
                  <NoteItem onDelete={onDelete} data={note} />
                </li>
              ))}
            </ul>
          </div>
          <div className="NoteCategoryInput-addSection">
            <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
              <QMuiInput
                onKeyPress={handleKeyPress}
                name="name"
                inputRef={register}
                control={control}
                placeholder="Ajouter une selection..."
                className="NoteCategoryInput-addInput"
              />
              <div className="NoteCategoryInput-colorPicker">
                <QColorPicker control={control} name="color" />
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </div>
  )
}
