import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import QSelect from '@CommonComponents/QSelect'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import QMuiButtonConfirmation from '@CommonComponents/Mui/QMuiButtonConfirmation'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'
import QMuiTextarea from '@CommonComponents/Mui/QMuiTextarea'
import QFile from '@CommonComponents/QFile'
import { getCustomerById, postFolder } from '@API/admin/customers'
import formRules from '@Utils/form-rules'
import DomainsAPI from '@API/admin/parameters/domains'
import ExpertsAPI from '@API/admin/experts'
import { useSnackbar } from '@Context/snackbar'
import { AttachFile as AttachFileIcon } from '@material-ui/icons'
import {
  fileToBase64,
  formatSymfonyValidationErrors,
  getErrorMsgFromSymfonyResponse,
} from '@Helpers'

export default function FolderForm({ clientId }) {
  const history = useHistory()
  const [isInitialized, setIsInitialized] = useState(false)
  const [client, setClient] = useState({ companies: [] })
  const [domains, setDomains] = useState([])
  const [experts, setExperts] = useState([])
  const [documentFile, setDocumentFile] = useState(null)
  const [selectedDomain, setSelectedDomain] = useState({})
  const [isPro, setIsPro] = useState(true)

  const { addSnackbarMsg } = useSnackbar()

  const {
    watch,
    register,
    control,
    handleSubmit,
    setError,
    clearErrors,
    errors,
  } = useForm()

  const watchDomain = watch('domain')

  useEffect(() => {
    async function onMount() {
      const { data } = await getCustomerById(clientId, 'admin')
      setClient(data)
    }
    if (clientId) {
      onMount()
    }
  }, [clientId])

  useEffect(() => {
    if (!isInitialized) {
      function onMount() {
        DomainsAPI.getAllDomainsAtOnce('admin').then((resp) =>
          setDomains(resp.data)
        )
        ExpertsAPI.getAllMinimal('admin').then((resp) => setExperts(resp.data))
      }
      onMount()
      setIsInitialized(true)
    }
  }, [isInitialized, setIsInitialized])

  useEffect(() => {
    if (typeof watchDomain === 'number') {
      const domain = domains.find(({ id }) => id === watchDomain)
      setSelectedDomain(domain)
    }
  }, [watchDomain, domains])

  const onSubmit = async (data) => {
    data.type = isPro ? 'professionnel' : 'particulier'
    delete data.domain

    if (documentFile === null) {
      addSnackbarMsg({
        severity: 'error',
        content: 'Le document est requis',
      })
      return
    }

    data.quote = {
      fileName: documentFile.name,
      fileContent: await fileToBase64(documentFile),
    }

    try {
      const response = await postFolder(clientId, data)
      history.push('/admin/folders/' + response.data.id)
    } catch (error) {
      const resp = error.response
      if (resp.data.message === 'Validation Failed') {
        const formatedErrors = formatSymfonyValidationErrors(resp.data.errors)

        for (let key in formatedErrors) {
          setError(key, formatedErrors[key])
        }

        onError(formatedErrors)
      } else {
        addSnackbarMsg({
          severity: 'error',
          content: getErrorMsgFromSymfonyResponse(error.response),
        })
      }
    }
  }

  const onError = (errors) => {
    if ('quote.fileName' in errors) {
      addSnackbarMsg({
        severity: 'error',
        content: errors['quote.fileName'].message,
      })
    } else if ('quote.fileContent' in errors) {
      addSnackbarMsg({
        severity: 'error',
        content: errors['quote.fileContent'].message,
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <h1 className="page-title">Ajouter un nouveau dossier</h1>
      <section className="page-section flex align-center justify-between">
        <h2>Objet de la demande</h2>
        <div>
          <QMuiButton
            size="small"
            variant={isPro ? 'contained' : 'outlined'}
            onClick={() => setIsPro(true)}
            className="mr-4"
          >
            Professionnel
          </QMuiButton>
          <QMuiButton
            size="small"
            variant={!isPro ? 'contained' : 'outlined'}
            onClick={() => setIsPro(false)}
          >
            Particulier
          </QMuiButton>
        </div>
      </section>

      {isPro && (
        <section className="page-section">
          <h2>Entreprise concernée</h2>

          <QSelect
            name="company"
            label="Entreprise"
            control={control}
            options={client.companies.map((company) => ({
              label: company.name,
              value: company.id,
            }))}
            rules={{ ...formRules.isRequired }}
            errorMsg={errors?.company?.message}
          />
        </section>
      )}

      <section className="page-section">
        <h2>Rédaction de la demande</h2>

        <div className="card">
          <QMuiFormInput
            className="form-input isFullWidth"
            label="Titre"
            name="name"
            register={register({ ...formRules.isRequired })}
            control={control}
            errorMsg={errors?.name?.message}
          />

          <QMuiTextarea
            className="form-input isFullWidth"
            rowsMin={3}
            label="Demande"
            name="content"
            register={register({ ...formRules.isRequired })}
            control={control}
            errorMsg={errors?.content?.message}
          />
        </div>
      </section>

      <section className="page-section">
        <h2>Secteur d'intervention</h2>

        <QSelect
          name="domain"
          label="Domaine"
          style={{ marginBottom: 20 }}
          control={control}
          options={domains.map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
        />

        <QSelect
          name="subdomain"
          label="Sous domaine"
          control={control}
          rules={{ ...formRules.isRequired }}
          errorMsg={errors?.subdomain?.message}
          isDisabled={!('id' in selectedDomain)}
          style={{ marginBottom: 20 }}
          options={
            'subdomains' in selectedDomain
              ? selectedDomain.subdomains.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))
              : []
          }
        />
      </section>

      <section className="page-section">
        <h2>Référent</h2>

        <QSelect
          name="referent"
          label="Référent"
          control={control}
          rules={{ ...formRules.isRequired }}
          errorMsg={errors?.referent?.message}
          options={experts.map(({ id, firstName, lastName }) => ({
            label: `${firstName} ${lastName}`,
            value: id,
          }))}
        />
      </section>

      <section className="page-section">
        <h2>Experts</h2>

        <QSelect
          name="experts"
          label="Experts"
          isMulti
          control={control}
          rules={{
            validate: (val) =>
              (val && val.length > 0) ||
              'Vous devez sélectionner au moins un expert',
          }}
          errorMsg={errors?.experts?.message}
          options={experts.map(({ id, firstName, lastName }) => ({
            label: `${firstName} ${lastName}`,
            value: id,
          }))}
        />
      </section>

      <section className="page-section">
        <h2>Devis</h2>

        <QFile
          color="secondary"
          file={documentFile}
          onChange={(e) => {
            clearErrors()
            setDocumentFile(e)
          }}
          endIcon={<AttachFileIcon />}
        >
          Devis
        </QFile>
      </section>

      <div className="flex justify-end">
        <QMuiButtonConfirmation
          btnText="Envoyer"
          confirmation="Êtes-vous sûr de vouloir envoyer la demande ?"
          type="submit"
          color="secondary"
        >
          Envoyer la demande
        </QMuiButtonConfirmation>
      </div>
    </form>
  )
}
