import APIClient from './APIClient'

export const patchCustomer = async (data) => {
  return await APIClient.patch('/api/customer/profile', data)
}

export const getFolders = async (page, search, sortParams) => {
  return await APIClient.get('/api/customer/folders', {
    params: { page, search, ...sortParams },
  })
}
