import APIClient from '../APIClient'
import FileSaver from 'file-saver'
import Mime from 'mime'

/**
 * @param {string} role
 * @param {number} id
 * @param {*} payload
 * @param {string} payload.name
 * @param {string} payload.description
 * @param {string} payload.fileName
 * @param {string} payload.fileContent
 */
export const postDocument = async (role, customerId, payload) => {
  return await APIClient.post(
    `/api/admin/${role}/${customerId}/documents`,
    payload
  )
}

export const patchDocument = async (documentId, payload, apiMode) => {
  return await APIClient.patch(
    `/api/${apiMode}/documents/${documentId}`,
    payload
  )
}

export const deleteDocument = async (documentId, apiMode) => {
  return await APIClient.delete(`/api/${apiMode}/documents/${documentId}`)
}

export const getDocumentBlob = async (documentId, apiMode) => {
  return APIClient.get(`api/${apiMode}/documents/${documentId}`, {
    responseType: 'blob',
  })
}

export const downloadDocument = async (documentId, fileName, apiMode) => {
  return getDocumentBlob(documentId, apiMode).then((resp) => {
    return new Promise((resolve, reject) => {
      try {
        FileSaver.saveAs(
          resp.data,
          `${fileName}.${Mime.getExtension(resp.headers['content-type'])}`
        )
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  })
}
