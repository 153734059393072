import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import QSelect from '@CommonComponents/QSelect'
import QFile from '@CommonComponents/QFile'
import {
  getCustomerById,
  patchCustomer,
  postCustomer,
} from '@API/admin/customers'
import formRules from '@Utils/form-rules'
import {
  Add as AddIcon,
  AttachFile as AttachFileIcon,
} from '@material-ui/icons'
import {
  fileToBase64,
  formatSymfonyValidationErrors,
  getErrorMsgFromSymfonyResponse,
} from '@Helpers'
import { useSnackbar } from '@Context/snackbar'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import QMuiButtonConfirmation from '@CommonComponents/Mui/QMuiButtonConfirmation'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'
import QMuiTextarea from '@CommonComponents/Mui/QMuiTextarea'
import Breadcrumb from '@CommonComponents/Breadcrumb'
import DocumentsGestion from '@Components/DocumentsGestion'

export default function Client({ apiMode }) {
  const [isInitialized, setIsInitialized] = useState(false)
  const [user, setUser] = useState({ companies: [] })
  const [avatarFile, setAvatarFile] = useState(null)

  const { register, control, handleSubmit, errors, setValue, setError } =
    useForm()

  const params = useParams()
  const history = useHistory()

  const { addSnackbarMsg } = useSnackbar()

  const isCreation = !('clientId' in params)

  useEffect(() => {
    const fetchCustomer = async () => {
      if (!isCreation) {
        const { data: customer } = await getCustomerById(
          params.clientId,
          apiMode
        )
        setUser(customer)

        for (let key in customer) {
          setValue(key, customer[key])
        }
      }
      setIsInitialized(true)
    }

    if (!isInitialized) {
      fetchCustomer()
    }
  }, [isInitialized, user, setValue, params, isCreation, apiMode])

  const onSubmit = async (data) => {
    if (!('companies' in data)) {
      data.companies = []
    }

    if (avatarFile != null) {
      data.fileName = avatarFile.name
      data.fileContent = await fileToBase64(avatarFile)
    }

    try {
      let resp = null
      if (isCreation) {
        resp = await postCustomer(data)
      } else {
        resp = await patchCustomer(params.clientId, data)
      }

      addSnackbarMsg({
        severity: 'success',
        content: isCreation ? 'Client ajouté' : 'Client modifié',
      })

      history.push(`/admin/clients/${resp.data.id}/folders`)
    } catch (error) {
      const resp = error.response

      if (resp.data.message === 'Validation Failed') {
        const formatedErrors = formatSymfonyValidationErrors(resp.data.errors)

        for (let key in formatedErrors) {
          setError(key, formatedErrors[key])
        }

        onError(formatedErrors)
      } else {
        addSnackbarMsg({
          severity: 'error',
          content: getErrorMsgFromSymfonyResponse(error.response),
        })
      }
    }
  }

  const addCompany = () => {
    setUser({
      ...user,
      companies: [...user.companies, {}],
    })
  }

  const deleteCompany = (idx) => {
    const companies = user.companies
    companies.splice(idx, 1)

    setUser({
      ...user,
      companies,
    })
  }

  const onError = (errors) => {
    for (let key in errors) {
      if (['fileName', 'fileContent'].includes(key)) {
        addSnackbarMsg({
          severity: 'error',
          content: errors[key].message,
        })
      }
    }
  }

  return (
    <div className="page-container page-thin-container">
      <Breadcrumb
        items={
          isCreation
            ? [
                { label: 'Clients', link: '/admin/clients' },
                { label: 'Nouveau client' },
              ]
            : [
                { label: 'Clients', link: '/admin/clients' },
                {
                  label: user.firstName
                    ? `${user.firstName} ${user.lastName}`
                    : '',
                  link: user.firstName
                    ? `/admin/clients/${user.id}/folders`
                    : null,
                },
                { label: 'Fiche client' },
              ]
        }
      />

      <h1 className="page-title">Fiche client</h1>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <section className="page-section">
          <h2>État civil</h2>

          <div className="card">
            <div className="flex align-center p-4">
              {user.image && (
                <img className="profile-pic" src={user.image} alt="Profile" />
              )}

              <QFile
                color="secondary"
                file={avatarFile}
                onChange={setAvatarFile}
                endIcon={<AttachFileIcon />}
              >
                Votre avatar
              </QFile>
            </div>

            <fieldset className="flex flex-wrap">
              <QSelect
                className="form-field"
                name="civility"
                label="Civilité"
                control={control}
                options={[
                  { label: 'Mr', value: 'm' },
                  { label: 'Mme', value: 'mme' },
                ]}
                errorMsg={errors?.civility?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="Nom*"
                name="lastName"
                register={register({ ...formRules.isRequired })}
                control={control}
                errorMsg={errors?.lastName?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="Prénom*"
                name="firstName"
                register={register({ ...formRules.isRequired })}
                control={control}
                errorMsg={errors?.firstName?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="Email*"
                name="email"
                register={register({ ...formRules.isRequired })}
                control={control}
                errorMsg={errors?.email?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="Téléphone*"
                name="phone"
                register={register({ ...formRules.isRequired })}
                control={control}
                errorMsg={errors?.phone?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="Adresse*"
                name="address.street"
                register={register({ ...formRules.isRequired })}
                control={control}
                errorMsg={errors?.address?.street?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="Ville*"
                name="address.city"
                register={register({ ...formRules.isRequired })}
                control={control}
                errorMsg={errors?.address?.city?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="Code postal*"
                name="address.postalCode"
                register={register({ ...formRules.isRequired })}
                control={control}
                errorMsg={errors?.address?.postalCode?.message}
              />
            </fieldset>
          </div>
        </section>

        {user.companies.map((company, companyIdx) => (
          <section key={companyIdx} className="page-section">
            <div className="flex align-items-center justify-between">
              <h2>Entreprise {companyIdx + 1}</h2>
              <QMuiButton
                type="button"
                onClick={() => deleteCompany(companyIdx)}
              >
                Supprimer
              </QMuiButton>
            </div>

            <div className="card">
              <fieldset>
                <QMuiFormInput
                  className="form-field"
                  label="Raison sociale*"
                  name={`companies[${companyIdx}].name`}
                  register={register({ ...formRules.isRequired })}
                  control={control}
                  errorMsg={errors?.companies?.[companyIdx]?.name?.message}
                />

                <QMuiFormInput
                  className="form-field"
                  label="Siren*"
                  name={`companies[${companyIdx}].siren`}
                  register={register({ ...formRules.isRequired })}
                  control={control}
                  errorMsg={errors?.companies?.[companyIdx]?.siren?.message}
                />

                <QMuiFormInput
                  className="form-field"
                  label="Secteur"
                  name={`companies[${companyIdx}].sector`}
                  register={register}
                  control={control}
                  errorMsg={errors?.companies?.[companyIdx]?.sector?.message}
                />

                <QMuiFormInput
                  className="form-field"
                  label="Dirigeant"
                  name={`companies[${companyIdx}].leader`}
                  register={register}
                  control={control}
                  errorMsg={errors?.companies?.[companyIdx]?.leader?.message}
                />

                <QMuiFormInput
                  className="form-field"
                  label="Adresse"
                  name={`companies[${companyIdx}].address.street`}
                  register={register}
                  control={control}
                  errorMsg={
                    errors?.companies?.[companyIdx]?.address?.street?.message
                  }
                />

                <QMuiFormInput
                  className="form-field"
                  label="Ville"
                  name={`companies[${companyIdx}].address.city`}
                  register={register}
                  control={control}
                  errorMsg={
                    errors?.companies?.[companyIdx]?.address?.city?.message
                  }
                />

                <QMuiFormInput
                  className="form-field"
                  label="Code postal"
                  name={`companies[${companyIdx}].address.postalCode`}
                  register={register}
                  control={control}
                  errorMsg={
                    errors?.companies?.[companyIdx]?.address?.postalCode
                      ?.message
                  }
                />

                <QMuiTextarea
                  className="form-field"
                  rowsMin={3}
                  label="Description"
                  name={`companies[${companyIdx}].description`}
                  register={register}
                  control={control}
                  errorMsg={
                    errors?.companies?.[companyIdx]?.description?.message
                  }
                />
              </fieldset>
            </div>
          </section>
        ))}

        <div>
          <QMuiButton
            color="secondary"
            onClick={addCompany}
            endIcon={<AddIcon />}
          >
            Ajouter une entreprise
          </QMuiButton>
        </div>

        <section className="page-section">
          <h2>Description du client</h2>

          <div className="card p-3">
            <QMuiTextarea
              rowsMin={3}
              label="Description"
              name="description"
              register={register()}
              control={control}
              errorMsg={errors?.description?.message}
            />
          </div>
        </section>

        <div className="flex justify-end">
          <QMuiButtonConfirmation
            btnText="Enregistrer"
            confirmation="Êtes-vous sûr de vouloir enregistrer ?"
            type="submit"
          >
            Enregistrer
          </QMuiButtonConfirmation>
        </div>
      </form>
      {params?.clientId && (
        <section className="page-section">
          <DocumentsGestion
            title="Documents liés à la page gestion"
            apiMode={apiMode}
            mode="customers"
            id={params.clientId}
          />
        </section>
      )}
    </div>
  )
}
