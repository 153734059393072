import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from '@Context/auth.js'
import { Link } from 'react-router-dom'
import QMsg from '@CommonComponents/QMsg'
import { getErrorMsgFromSymfonyResponse } from '@Helpers'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'
import Password from '@Components/icons/Password'
import { InputAdornment } from '@material-ui/core'
import Email from '@Components/icons/Email'
import variant from '@Variants'
import userAPI from '@API/user'

export default function LoginForm() {
  const { login } = useAuth()

  const [errorMsg, setErrorMsg] = useState(null)

  const { register, handleSubmit, control } = useForm()

  const onSubmit = async ({ username, password }) => {
    setErrorMsg(null)

    try {
      const {
        data: { token },
      } = await userAPI.postLogin({ username, password })
      login(token)
    } catch (error) {
      setErrorMsg(getErrorMsgFromSymfonyResponse(error.response))
    }
  }

  const onError = (errors) => {
    setErrorMsg(null) // Needed to reset the transition
    setErrorMsg(errors[Object.keys(errors)[0]].message)
  }

  return (
    <div>
      <h1 className="Login-title">Connexion</h1>
      <span className="Login-subtitle">
        Votre équipe {variant.title} est là pour vous accompagner.
      </span>
      <span className="Login-subtitle">
        Connectez-vous en toute sécurité sur votre espace dédié.
      </span>
      <form onSubmit={handleSubmit(onSubmit, onError)} className="Login-form">
        <div>
          <QMuiFormInput
            containerStyle={{ width: '100%', marginBottom: 10 }}
            variant="loginVariant"
            register={register}
            control={control}
            name="username"
            type="email"
            placeholder="Votre email"
            startAdornment={
              <InputAdornment position="start">
                <Email width={20} />
              </InputAdornment>
            }
          />
        </div>
        <div>
          <QMuiFormInput
            containerStyle={{ width: '100%', marginBottom: 10 }}
            variant="loginVariant"
            register={register}
            control={control}
            name="password"
            type="password"
            placeholder="Votre mot de passe"
            startAdornment={
              <InputAdornment position="start">
                <Password width={20} />
              </InputAdornment>
            }
          />
        </div>
        <Link
          to="/login?action=forgotten-password"
          className="Login-forgotMdpLink" // TODO change name for generic link ?
        >
          Mot de passe oublié ?
        </Link>
        <QMsg className="Login-validationMsg" variant="Danger">
          {errorMsg}
        </QMsg>
        <QMuiButton
          className="Login-submitBtn"
          type="submit"
          variant="outlined"
          color="white"
          size="large"
        >
          Connectez-vous
        </QMuiButton>
      </form>
      <div className="Login-noAccount">
        <a
          href={variant.signinLink}
          target="_blank"
          rel="noreferrer"
          className="Login-forgotMdpLink"
        >
          <span>Vous n'avez pas de compte {variant.title} ?</span>
          <span>Faites nous votre demande juridique pour en obtenir un</span>
        </a>
      </div>
    </div>
  )
}
