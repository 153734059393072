import PropTypes from 'prop-types'
import { NavLink, useLocation, matchPath } from 'react-router-dom'
import classNames from 'classnames'

function Menu({
  items,
  className,
  itemStyle: _itemStyle,
  onItemClick = () => {},
}) {
  const location = useLocation()

  return (
    <nav className={className}>
      <ul>
        {items.map((item, idx) => {
          const Clickable = item.linkTo ? NavLink : 'button'

          const routeMatches =
            matchPath(item.linkTo, { path: location.pathname, exact: true }) !==
              null || location.pathname.startsWith(item.linkTo)

          const itemStyle = classNames('Menu-item', _itemStyle, {
            isActive: item.isActive || routeMatches,
          })

          return (
            <li key={idx} className={itemStyle}>
              <Clickable
                className="Menu-link"
                to={item.linkTo}
                onClick={() => {
                  item?.onClick && item.onClick()
                  onItemClick()
                }}
              >
                {item.icon && (
                  <span className="Menu-itemIcon">{item.icon}</span>
                )}

                <span>{item.title}</span>

                {item.counter && (
                  <span className="Menu-itemCounter">{item.counter}</span>
                )}
              </Clickable>
              {item.subMenu && <Menu items={item.subMenu} />}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

Menu.propTypes = {
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
}

export default Menu
