import React, { useState, useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import QSelect from '@CommonComponents/QSelect'
import QFile from '@CommonComponents/QFile'
import {
  getCustomerById,
  patchCustomer,
  postCustomer,
  getAllCustomers,
} from '@API/admin/customers'
import formRules from '@Utils/form-rules'
import {
  Add as AddIcon,
  AttachFile as AttachFileIcon,
} from '@material-ui/icons'
import {
  fileToBase64,
  formatSymfonyValidationErrors,
  getErrorMsgFromSymfonyResponse,
} from '@Helpers'
import { useSnackbar } from '@Context/snackbar'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import QMuiButtonConfirmation from '@CommonComponents/Mui/QMuiButtonConfirmation'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'
import QMuiTextarea from '@CommonComponents/Mui/QMuiTextarea'

export default function UserForm({ mode, userId, apiMode, onEdit }) {
  const [user, setUser] = useState({ companies: [] })
  const [customers, setCustomers] = useState([])
  const [avatarFile, setAvatarFile] = useState(null)

  const {
    register,
    control,
    handleSubmit,
    errors,
    setValue,
    setError,
  } = useForm()

  const { addSnackbarMsg } = useSnackbar()

  const customer = useWatch({
    control,
    name: 'customer',
    defaultValue: null,
  })

  useEffect(() => {
    const fetchCustomer = async () => {
      const { data } = await getCustomerById(customer, apiMode)
      setUser(data)

      setValue('civility', data.civility)
      setValue('lastName', data.lastName)
      setValue('firstName', data.firstName)
      setValue('email', data.email)
      setValue('phone', data.phone)
      setValue('address.street', data.address?.street)
      setValue('address.city', data.address?.city)
      setValue('address.postalCode', data.address?.postalCode)

      for (let [idx, company] of data.companies.entries()) {
        setValue(`companies[${idx}].name`, company.name)
        setValue(`companies[${idx}].siren`, company.siren)
        setValue(`companies[${idx}].sector`, company.sector)
        setValue(`companies[${idx}].leader`, company.leader)
        setValue(`companies[${idx}].address.street`, company.address?.street)
        setValue(`companies[${idx}].address.city`, company.address?.city)
        setValue(
          `companies[${idx}].address.postalCode`,
          company.address?.postalCode
        )
        setValue(`companies[${idx}].description`, company.description)
      }
    }
    if (customer) {
      fetchCustomer()
    }
  }, [customer])

  useEffect(() => {
    const fetchCustomers = async () => {
      const response = await getAllCustomers()
      setCustomers(response.data)
    }
    fetchCustomers()
  }, [setCustomers])

  useEffect(() => {
    const fetchCustomer = async () => {
      if (mode === 'edit' && userId) {
        const { data } = await getCustomerById(userId, apiMode)
        setUser(data)
        for (let key in data) {
          setValue(key, data[key])
        }
      }
    }
    fetchCustomer()
  }, [setValue, mode, userId, apiMode])

  const onSubmit = async (data) => {
    let customerId
    if (data.customer) {
      customerId = data.customer
    }
    delete data.customer

    if (!('companies' in data)) {
      data.companies = []
    }

    if (avatarFile != null) {
      data.fileName = avatarFile.name
      data.fileContent = await fileToBase64(avatarFile)
    }

    try {
      let response = null
      if (customerId) {
        response = await patchCustomer(customerId, data)
      } else if (mode === 'edit') {
        response = await patchCustomer(userId, data)
      } else {
        response = await postCustomer(data)
      }
      onEdit(response.data)
    } catch (error) {
      const resp = error.response

      if (resp.data.message === 'Validation Failed') {
        const formatedErrors = formatSymfonyValidationErrors(resp.data.errors)

        for (let key in formatedErrors) {
          setError(key, formatedErrors[key])
        }

        onError(formatedErrors)
      } else {
        addSnackbarMsg({
          severity: 'error',
          content: getErrorMsgFromSymfonyResponse(error.response),
        })
      }
    }
  }

  const addCompany = () => {
    setUser({
      ...user,
      companies: [...user.companies, {}],
    })
  }

  const deleteCompany = (idx) => {
    const companies = user.companies
    companies.splice(idx, 1)

    setUser({
      ...user,
      companies,
    })
  }

  const onError = (errors) => {
    for (let key in errors) {
      if (['fileName', 'fileContent'].includes(key)) {
        addSnackbarMsg({
          severity: 'error',
          content: errors[key].message,
        })
      }
    }
  }

  return (
    <div>
      <section className="page-section">
        <h2>Choix du client</h2>
        <QSelect
          name="customer"
          label="Sélectionnez un client"
          control={control}
          options={customers?.map((customer) => ({
            label: customer.lastName + ' ' + customer.firstName,
            value: customer.id,
          }))}
        />
      </section>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <section className="page-section">
          <h2>État civil</h2>

          <div className="card">
            <div className="flex align-center p-4">
              {user.image && (
                <img className="profile-pic" src={user.image} alt="Profile" />
              )}

              <QFile
                color="secondary"
                file={avatarFile}
                onChange={setAvatarFile}
                endIcon={<AttachFileIcon />}
              >
                Votre avatar
              </QFile>
            </div>

            <fieldset className="flex flex-wrap">
              <QSelect
                className="form-field"
                name="civility"
                label="Civilité*"
                control={control}
                options={[
                  { label: 'Mr', value: 'm' },
                  { label: 'Mme', value: 'mme' },
                ]}
                errorMsg={errors?.civility?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="Nom*"
                name="lastName"
                register={register({ ...formRules.isRequired })}
                control={control}
                errorMsg={errors?.lastName?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="Prénom*"
                name="firstName"
                register={register({ ...formRules.isRequired })}
                control={control}
                errorMsg={errors?.firstName?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="Email*"
                name="email"
                register={register({ ...formRules.isRequired })}
                control={control}
                errorMsg={errors?.email?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="Téléphone*"
                name="phone"
                register={register({ ...formRules.isRequired })}
                control={control}
                errorMsg={errors?.phone?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="Adresse*"
                name="address.street"
                register={register({ ...formRules.isRequired })}
                control={control}
                errorMsg={errors?.address?.street?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="Ville*"
                name="address.city"
                register={register({ ...formRules.isRequired })}
                control={control}
                errorMsg={errors?.address?.city?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="Code postal*"
                name="address.postalCode"
                register={register({ ...formRules.isRequired })}
                control={control}
                errorMsg={errors?.address?.postalCode?.message}
              />
            </fieldset>
          </div>
        </section>

        {user.companies.map((_, companyIdx) => (
          <section key={companyIdx} className="page-section">
            <div className="flex align-items-center justify-between">
              <h2>Entreprise {companyIdx + 1}</h2>
              <QMuiButton
                type="button"
                onClick={() => deleteCompany(companyIdx)}
              >
                Supprimer
              </QMuiButton>
            </div>

            <div className="card">
              <fieldset>
                <QMuiFormInput
                  className="form-field"
                  label="Raison sociale*"
                  name={`companies[${companyIdx}].name`}
                  register={register({ ...formRules.isRequired })}
                  control={control}
                  errorMsg={errors?.companies?.[companyIdx]?.name?.message}
                />

                <QMuiFormInput
                  className="form-field"
                  label="Siren*"
                  name={`companies[${companyIdx}].siren`}
                  register={register({ ...formRules.isRequired })}
                  control={control}
                  errorMsg={errors?.companies?.[companyIdx]?.siren?.message}
                />

                <QMuiFormInput
                  className="form-field"
                  label="Secteur"
                  name={`companies[${companyIdx}].sector`}
                  control={control}
                  errorMsg={errors?.companies?.[companyIdx]?.sector?.message}
                />

                <QMuiFormInput
                  className="form-field"
                  label="Dirigeant"
                  name={`companies[${companyIdx}].leader`}
                  control={control}
                  errorMsg={errors?.companies?.[companyIdx]?.leader?.message}
                />

                <QMuiFormInput
                  className="form-field"
                  label="Adresse"
                  name={`companies[${companyIdx}].address.street`}
                  control={control}
                  errorMsg={
                    errors?.companies?.[companyIdx]?.address?.street?.message
                  }
                />

                <QMuiFormInput
                  className="form-field"
                  label="Ville"
                  name={`companies[${companyIdx}].address.city`}
                  control={control}
                  errorMsg={
                    errors?.companies?.[companyIdx]?.address?.city?.message
                  }
                />

                <QMuiFormInput
                  className="form-field"
                  label="Code postal"
                  name={`companies[${companyIdx}].address.postalCode`}
                  control={control}
                  errorMsg={
                    errors?.companies?.[companyIdx]?.address?.postalCode
                      ?.message
                  }
                />

                <QMuiTextarea
                  className="form-field"
                  rowsMin={3}
                  label="Description"
                  name={`companies[${companyIdx}].description`}
                  control={control}
                  errorMsg={
                    errors?.companies?.[companyIdx]?.description?.message
                  }
                />
              </fieldset>
            </div>
          </section>
        ))}

        <div>
          <QMuiButton
            color="secondary"
            onClick={addCompany}
            endIcon={<AddIcon />}
          >
            Ajouter une entreprise
          </QMuiButton>
        </div>

        <section className="page-section">
          <h2>Description du client</h2>

          <div className="card p-3">
            <QMuiTextarea
              rowsMin={3}
              label="Description"
              name="description"
              register={register()}
              control={control}
              errorMsg={errors?.description?.message}
            />
          </div>
        </section>

        <div className="flex justify-end">
          <QMuiButtonConfirmation
            btnText="Continuer"
            confirmation="Êtes-vous sûr de vouloir continuer ?"
            type="submit"
          >
            Étape suivante
          </QMuiButtonConfirmation>
        </div>
      </form>
    </div>
  )
}
