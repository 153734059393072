import DomainAPI from '@API/admin/parameters/domains'
import React, { useState } from 'react'
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Close as CloseIcon,
} from '@material-ui/icons'
import { Box, FilledInput, InputAdornment, IconButton } from '@material-ui/core'
import QMuiInput from '@CommonComponents/Mui/QMuiInput'

import { useSnackbar } from '@Context/snackbar'
import {
  getErrorMsgFromSymfonyResponse,
  formatSymfonyValidationErrors,
} from '@Helpers'
import QMuiButtonConfirmation from '@CommonComponents/Mui/QMuiButtonConfirmation'

export default function DomainManager({
  domain,
  updateDomain,
  handleDeleteDomain,
}) {
  const [newSubdomainName, setNewSubdomainName] = useState('')
  const { addSnackbarMsg } = useSnackbar()

  const addSubDomain = async () => {
    try {
      const { data } = await DomainAPI.addSubDomain(domain.id, newSubdomainName)
      delete data.domain
      updateDomain({
        ...domain,
        subdomains: [...domain.subdomains, data],
      })
      addSnackbarMsg({
        severity: 'success',
        content: 'Domaine ajouté',
      })
      setNewSubdomainName('')
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  const editDomain = async (event) => {
    if (
      event.target.value.trim() === '' ||
      event.target.value === domain.name
    ) {
      return
    }

    try {
      await DomainAPI.patchDomain(domain.id, event.target.value)

      updateDomain({
        ...domain,
        name: event.target.value,
      })
      addSnackbarMsg({
        severity: 'success',
        content: 'Domaine modifié',
      })
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  const deleteSubDomain = async (id) => {
    try {
      await DomainAPI.deleteSubDomain(id)

      updateDomain({
        ...domain,
        subdomains: domain.subdomains.filter((d) => d.id !== id),
      })
      addSnackbarMsg({
        severity: 'success',
        content: 'Sous-domaine supprimé',
      })
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  const editSubDomain = async (subdomain, event) => {
    if (
      event.target.value.trim() === '' ||
      event.target.value === subdomain.name
    ) {
      return
    }

    try {
      await DomainAPI.patchSubDomain(subdomain.id, event.target.value)

      updateDomain({
        ...domain,
        subdomains: domain.subdomains.map((d) =>
          d.id === subdomain.id ? { ...d, name: event.target.value } : d
        ),
      })
      addSnackbarMsg({
        severity: 'success',
        content: 'Sous-domaine modifié',
      })
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  const handleConfirmationDeleteDomain = async () => {
    if (domain.subdomains.length === 0) {
      try {
        await DomainAPI.deleteDomain(domain.id)
        addSnackbarMsg({
          severity: 'success',
          content: 'Domaine supprime',
        })
        handleDeleteDomain()
      } catch (error) {
        addSnackbarMsg({
          severity: 'error',
          content: getErrorMsgFromSymfonyResponse(error.response),
        })
      }
    }
  }

  return (
    <section className="mb-6">
      <Box style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>
        <QMuiInput
          className="DomainManager-domain"
          defaultValue={domain.name}
          onBlur={editDomain}
          onKeyPress={(e) => e.key === 'Enter' && editDomain(e)}
        />
        <QMuiButtonConfirmation
          className="DomainManager-deleteBtn"
          color="iconDanger"
          disabled={domain.subdomains.length > 0}
          confirmation="Voulez vous supprimer ce domaine ?"
          handleConfirmation={handleConfirmationDeleteDomain}
        >
          <CloseIcon />
        </QMuiButtonConfirmation>
      </Box>

      <ul className="barlist">
        {domain.subdomains.map((subdomain, idx) => (
          <li key={subdomain.id}>
            <QMuiInput
              className="DomainManager-subDomain"
              defaultValue={subdomain.name}
              onBlur={(e) => editSubDomain(subdomain, e)}
              onKeyPress={(e) =>
                e.key === 'Enter' && editSubDomain(subdomain, e)
              }
            />
            <div className="barlist-actions">
              <QMuiButtonConfirmation
                color="iconDanger"
                confirmation="Voulez vous supprimer ce sous domaine ?"
                handleConfirmation={() => deleteSubDomain(subdomain.id)}
              >
                <CloseIcon />
              </QMuiButtonConfirmation>
            </div>
          </li>
        ))}

        <li>
          <FilledInput
            className="DomainManager-domainInput"
            type="text"
            value={newSubdomainName}
            onChange={(e) => setNewSubdomainName(e.target.value)}
            disableUnderline
            placeholder="Ajouter un sous domaine"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  classes={{ root: 'DomainManager-inputBtn' }}
                  onClick={addSubDomain}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </li>
      </ul>
    </section>
  )
}
