import React from 'react'

export default function Password(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24">
      <g
        transform="translate(.757 .977)"
        fill="none"
        stroke={props.color ? props.color : '#fff'}
        strokeMiterlimit="10"
        strokeWidth="2"
      >
        <rect width="20" height="16" rx="4" transform="translate(.243 6.023)" />
        <path d="M10.243.023h0a4 4 0 014 4v2h0-8 0v-2a4 4 0 014-4z" />
        <ellipse
          cx="3"
          cy="2.5"
          rx="3"
          ry="2.5"
          transform="translate(7.243 11.023)"
        />
      </g>
    </svg>
  )
}
