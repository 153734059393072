import React, { useEffect, useState } from 'react'
import NoteCategoryInput from '@Components/NoteCategoryInput'
import { getAllNotes, deleleOneNote, addNote } from '@API/admin/parameters'
import { motion } from 'framer-motion'
import { useSnackbar } from '@Context/snackbar'
import { getErrorMsgFromSymfonyResponse } from '@Helpers'

export default function SelectionTypeManagement() {
  const [notes, setNotes] = useState([])
  const { addSnackbarMsg } = useSnackbar()

  const onDelete = async (id) => {
    try {
      await deleleOneNote(id)
      const index = notes.findIndex((note) => note.id === id)
      const copyNotes = [...notes]
      copyNotes.splice(index, 1)
      setNotes(copyNotes)
      addSnackbarMsg({
        severity: 'success',
        content: 'Note supprimée',
      })
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  const onAdd = async (form) => {
    try {
      const response = await addNote(form)
      setNotes([...notes, response.data])
      addSnackbarMsg({
        severity: 'success',
        content: 'Note ajoutée',
      })
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  useEffect(() => {
    async function fetchNotes() {
      const response = await getAllNotes()
      setNotes(response.data.results)
    }
    fetchNotes()
  }, [])

  return (
    <motion.div exit={{ opacity: 0 }}>
      <h1 className="page-title">Types de sélection pré-définie</h1>

      <NoteCategoryInput onAdd={onAdd} onDelete={onDelete} notes={notes} />
    </motion.div>
  )
}
