import APIClient from './APIClient'
import FileSaver from 'file-saver'
import Mime from 'mime'

export const getDocuments = async (apiMode, id, mode) => {
  let response
  switch (apiMode) {
    case 'admin':
      response = await APIClient.get(
        '/api/admin/' + mode + '/' + id + '/documents'
      )
      break
    case 'expert':
      response = await APIClient.get('/api/expert/documents')
      break
    case 'customer':
      response = await APIClient.get('/api/customer/documents')
      break
    default:
      break
  }
  return response
}

export const postDocument = async (apiMode, payload, id, mode) => {
  let response
  switch (apiMode) {
    case 'admin':
      response = await APIClient.post(
        '/api/admin/' + mode + '/' + id + '/documents',
        payload
      )
      break
    case 'expert':
      response = await APIClient.post('/api/expert/documents', payload)
      break
    case 'customer':
      response = await APIClient.post('/api/customer/documents', payload)
      break
    default:
      break
  }
  return response
}

export const getDocumentBlob = async (apiMode, id) => {
  const response = APIClient.get('api/' + apiMode + '/documents/' + id, {
    responseType: 'blob',
  })
  return response
}

export const downloadDocument = async (apiMode, id, fileName) => {
  return getDocumentBlob(apiMode, id).then((response) => {
    return new Promise((resolve, reject) => {
      try {
        FileSaver.saveAs(
          response.data,
          `${fileName}.${Mime.getExtension(response.headers['content-type'])}`
        )
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  })
}

export const deleteDocument = async (apiMode, id) => {
  const response = await APIClient.delete(
    '/api/' + apiMode + '/documents/' + id
  )
  return response
}
