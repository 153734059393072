import React, { useState, createRef } from 'react'
import { DialogActions, DialogContent } from '@material-ui/core'
import QMuiModal from './QMuiModal'
import QMuiButton from './QMuiButton'

export default function QMuiButtonConfirmation({
  handleConfirmation = () => {},
  confirmation,
  btnText = 'Supprimer',
  children,
  type = 'button',
  ...props
}) {
  const [open, setOpen] = useState(false)

  const _handleConfirmation = () => {
    setOpen(false)
    handleConfirmation()

    if (type === 'submit') {
      submitBtnRef.current.click()
    }
  }

  // The fake submit button is needed because QMuiModal
  // uses a portal and cannot trigger a submit otherwise
  const submitBtnRef = createRef()

  return (
    <>
      <QMuiButton {...props} onClick={() => setOpen(true)} type="button">
        {children}
      </QMuiButton>

      {type === 'submit' && (
        <button
          ref={submitBtnRef}
          style={{ display: 'none' }}
          type="submit"
        ></button>
      )}

      <QMuiModal title="Confirmation" open={open}>
        <DialogContent>
          <p style={{ marginBottom: 10 }}>{confirmation}</p>
        </DialogContent>

        <DialogActions>
          <QMuiButton onClick={() => setOpen(false)} color="danger">
            Annuler
          </QMuiButton>

          <QMuiButton type={type} onClick={_handleConfirmation}>
            {btnText}
          </QMuiButton>
        </DialogActions>
      </QMuiModal>
    </>
  )
}
