import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import QSelect from '@CommonComponents/QSelect'
import QFile from '@CommonComponents/QFile'
import formRules from '@Utils/form-rules'
import { AttachFile as AttachFileIcon } from '@material-ui/icons'
import {
  fileToBase64,
  formatSymfonyValidationErrors,
  getErrorMsgFromSymfonyResponse,
} from '@Helpers'
import { useSnackbar } from '@Context/snackbar'

import QMuiButtonConfirmation from '@CommonComponents/Mui/QMuiButtonConfirmation'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'

import userAPI from '@API/user'

export default function UserEdit() {
  const [isInitialized, setIsInitialized] = useState(false)
  const [user, setUser] = useState({ companies: [] })
  const [avatarFile, setAvatarFile] = useState(null)

  const {
    register,
    control,
    handleSubmit,
    errors,
    setValue,
    setError,
    clearErrors,
  } = useForm()

  const params = useParams()
  const history = useHistory()

  const { addSnackbarMsg } = useSnackbar()

  const isEditing = !!params.userId

  useEffect(() => {
    const fetchCustomer = async () => {
      if (isEditing) {
        const { data: customer } = await userAPI.getAdministratorById(
          params.userId
        )
        setUser(customer)

        for (let key in customer) {
          setValue(key, customer[key])
        }
      }
      setIsInitialized(true)
    }

    if (!isInitialized) {
      fetchCustomer()
    }
  }, [isInitialized, user, setValue, params, isEditing])

  const onSubmit = async (data) => {
    if (avatarFile === null) {
      addSnackbarMsg({
        severity: 'error',
        content: 'Un avatar est requis',
      })
      return
    }

    data.fileName = avatarFile.name
    data.fileContent = await fileToBase64(avatarFile)

    try {
      let resp = null
      if (!isEditing) {
        resp = await userAPI.postAdministrator(data)
      } else {
        resp = await userAPI.patchAdministrator(params.userId, data)
      }

      setUser(resp.data)

      addSnackbarMsg({
        severity: 'success',
        content: isEditing ? 'Utilisateur modifié' : 'Utilisateur créé',
      })

      history.push(`/admin/users`)
    } catch (error) {
      const resp = error.response

      if (resp.data.message === 'Validation Failed') {
        const formatedErrors = formatSymfonyValidationErrors(resp.data.errors)

        for (let key in formatedErrors) {
          setError(key, formatedErrors[key])
        }

        onError(formatedErrors)
      } else {
        addSnackbarMsg({
          severity: 'error',
          content: getErrorMsgFromSymfonyResponse(error.response),
        })
      }
    }
  }

  const onError = (errors) => {
    for (let key in errors) {
      if (['fileName', 'fileContent'].includes(key)) {
        addSnackbarMsg({
          severity: 'error',
          content: errors[key].message,
        })
      }
    }
  }

  return (
    <div className="page-container page-thin-container">
      <h1 className="page-title">
        {isEditing ? 'Modifier' : 'Ajouter'} un utilisateur
      </h1>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <section className="page-section">
          <div className="card">
            <div className="flex align-items-center p-4">
              {user.image && (
                <img className="profile-pic" src={user.image} alt="Profile" />
              )}

              <QFile
                color="secondary"
                file={avatarFile}
                onChange={(e) => {
                  clearErrors()
                  setAvatarFile(e)
                }}
                endIcon={<AttachFileIcon />}
              >
                Avatar
              </QFile>
            </div>

            <fieldset className="flex flex-wrap">
              <QSelect
                label="Civilité"
                className="form-field"
                control={control}
                name="civility"
                errorMsg={errors?.civility?.message}
                options={[
                  { value: 'm', label: 'Mr' },
                  { value: 'mme', label: 'Mme' },
                ]}
                defaultValue="m"
                rules={{ ...formRules.isRequired }}
              />

              <QMuiFormInput
                className="form-field"
                label="Nom"
                name="lastName"
                register={register({ ...formRules.isRequired })}
                control={control}
                errorMsg={errors?.lastName?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="Prénom"
                name="firstName"
                register={register({ ...formRules.isRequired })}
                control={control}
                errorMsg={errors?.firstName?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="Email"
                name="email"
                register={register({ ...formRules.isRequired })}
                control={control}
                errorMsg={errors?.email?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="Tél. fixe"
                name="phone"
                register={register()}
                control={control}
                errorMsg={errors?.phone?.message}
              />

              <QMuiFormInput
                className="form-field"
                label="Tél. mobile"
                name="mobile"
                register={register({ ...formRules.isRequired })}
                control={control}
                errorMsg={errors?.mobile?.message}
              />
            </fieldset>
          </div>
        </section>

        <div className="flex justify-end">
          <QMuiButtonConfirmation
            btnText="Enregistrer"
            confirmation="Êtes-vous sûr de vouloir enregistrer ?"
            type="submit"
          >
            Enregistrer
          </QMuiButtonConfirmation>
        </div>
      </form>
    </div>
  )
}
