import React from 'react'
import ChronologyEdit from '@Components/ChronologyEdit'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import { useSnackbar } from '@Context/snackbar'
import ChronologiesAPI from '@API/admin/parameters/chronologies'
import { useAuth } from '@Context/auth'

export default function FolderChronology({
  folder,
  refreshChronology,
  chronology,
  setChronology,
  apiMode,
}) {
  const { addSnackbarMsg } = useSnackbar()

  const duplicate = async () => {
    try {
      const { data } = await ChronologiesAPI.duplicateChronologie(chronology.id)
      addSnackbarMsg({
        severity: 'success',
        content: 'Nouveau modèle de chronologie enregistrée',
      })
      window.open('/admin/parameters/chrono/' + data.id + '/schema')
    } catch (e) {
      addSnackbarMsg({
        severity: 'error',
        content: "Impossible d'enregistrer comme nouveau modèle",
      })
    }
  }

  return (
    chronology && (
      <div className="Folder-chronology">
        <div className="ChronologyEdit-head">
          {apiMode === 'admin' && (
            <QMuiButton onClick={duplicate}>
              Enregistrer un nouveau modèle
            </QMuiButton>
          )}
        </div>

        <ChronologyEdit
          folder={folder}
          refreshChronology={refreshChronology}
          chronology={chronology}
          setChronology={setChronology}
          apiMode={apiMode}
        />
      </div>
    )
  )
}
