import React, { useEffect, useState } from 'react'
import { FormControl } from '@material-ui/core'
import QSelect from '@CommonComponents/QSelect'
import { useForm } from 'react-hook-form'
import NoteCategoryAddForm from '@Components/forms/NoteCategoryAddForm'

export default function FolderNoteFilter({ categories, onFilter, apiMode }) {
  const { control, watch, setValue, register } = useForm()
  const watchValue = watch('category')

  const [showModalNoteCategory, setShowModalNoteCategory] = useState(false)
  const [options, setOptions] = useState([])

  useEffect(() => {
    let options = []
    if (categories.length > 0) {
      options = categories.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    }
    if (apiMode === 'admin') {
      options.push({
        label: '+ Ajouter un type de note',
        value: 0,
      })
    }
    setOptions([{ value: -1, label: 'Toutes les catégories' }, ...options])
  }, [categories])

  useEffect(() => {
    if (watchValue === 0) {
      setValue('category', -1)
      setShowModalNoteCategory(true)
    } else {
      onFilter(watchValue)
    }
  }, [watchValue])

  const onSuccess = (note) => {
    if (note) {
      setOptions((current) => [
        {
          label: note.name,
          value: note.id,
        },
        ...current,
      ])
      setValue('category', note.id)
    }
    setShowModalNoteCategory(false)
  }

  const onClose = () => {
    setShowModalNoteCategory(false)
  }

  return (
    <div>
      <FormControl style={{ width: 250 }}>
        <QSelect
          label="Catégorie"
          name="category"
          control={control}
          options={options}
        />
      </FormControl>
      {apiMode === 'admin' && (
        <NoteCategoryAddForm
          open={showModalNoteCategory}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      )}
    </div>
  )
}
