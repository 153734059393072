import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'

import formRules from '@Utils/form-rules'
import foldersAPI from '@API/admin/folders'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'
import QMuiTextarea from '@CommonComponents/Mui/QMuiTextarea'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import QMuiModal from '@CommonComponents/Mui/QMuiModal'
import QSelect from '@CommonComponents/QSelect'
import { useSnackbar } from '@Context/snackbar'
import {
  formatSymfonyValidationErrors,
  getErrorMsgFromSymfonyResponse,
} from '@Helpers'

export default function DocumentRequestForm({
  open,
  onClose,
  folder,
  triggerReload,
  apiMode,
}) {
  const { register, control, handleSubmit, errors, setError, watch } = useForm()

  const watchTargetType = watch('targetType')

  const { addSnackbarMsg } = useSnackbar()

  const [targetExpertChoices, setTargetExpertChoices] = useState()

  useEffect(() => {
    const expertChoices = [...folder.experts]

    if (!folder.experts.find((e) => e.id === folder.referent.id)) {
      expertChoices.unshift(folder.referent)
    }

    setTargetExpertChoices(
      expertChoices.map((expert) => ({
        label: `${expert.firstName} ${expert.lastName}`,
        value: expert.id,
      }))
    )
  }, [folder])

  const onSubmit = async (data) => {
    try {
      await foldersAPI.postDocumentRequest(folder.id, data, apiMode)

      addSnackbarMsg({
        severity: 'success',
        content: 'Demande envoyée',
      })

      triggerReload()
      onClose()
    } catch (error) {
      const resp = error.response

      if (resp.data.message === 'Validation Failed') {
        const formatedErrors = formatSymfonyValidationErrors(resp.data.errors)

        for (let key in formatedErrors) {
          setError(key, formatedErrors[key])
        }
      } else {
        addSnackbarMsg({
          severity: 'error',
          content: getErrorMsgFromSymfonyResponse(error.response),
        })
      }
    }
  }

  return (
    <QMuiModal open={open} title="Faire une demande de document">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-wrap px-4">
          <QMuiFormInput
            className="form-field fullWidth"
            label="Nom du document"
            name="documentName"
            register={register({ ...formRules.isRequired })}
            control={control}
            errorMsg={errors?.documentName?.message}
          />

          <QMuiTextarea
            className="form-field fullWidth"
            rowsMin={3}
            label="Description"
            name="documentDescription"
            register={register({ ...formRules.isRequired })}
            control={control}
            errorMsg={errors?.documentDescription?.message}
          />

          <QSelect
            className="form-field fullWidth"
            label="A qui envoyer la demande ?"
            name="targetType"
            control={control}
            rules={{ ...formRules.isRequired }}
            errorMsg={errors?.targetType?.message}
            defaulValue={'Customer'}
            options={[
              {
                label: 'Client',
                value: 'Customer',
              },
              {
                label: 'Avocat',
                value: 'Expert',
              },
            ]}
          />

          {watchTargetType === 'Expert' && (
            <QSelect
              className="form-field fullWidth"
              label="A quel avocat envoyer la demande ?"
              name="targetExpert"
              control={control}
              rules={{ ...formRules.isRequired }}
              errorMsg={errors?.targetExpert?.message}
              defaulValue={targetExpertChoices[0].value}
              options={targetExpertChoices}
            />
          )}
        </div>

        <div className="flex p-4">
          <QMuiButton color="danger" className="ml-auto" onClick={onClose}>
            Annuler
          </QMuiButton>

          <QMuiButton type="submit" className="ml-4">
            Envoyer
          </QMuiButton>
        </div>
      </form>
    </QMuiModal>
  )
}
