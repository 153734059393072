import React from 'react'

export default function Update(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14.668 14.777"
    >
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h14.668v14.777H0z" />
        </clipPath>
      </defs>
      <g>
        <g>
          <g clipPath="url(#a)">
            <path
              d="M14.07.5l-.018-.016a1.835 1.835 0 00-2.593.117L4.9 7.782a.633.633 0 00-.133.227l-.771 2.312a.878.878 0 001.186 1.083l2.233-.977a.63.63 0 00.214-.153l6.559-7.181A1.838 1.838 0 0014.071.5zm-8.6 9.394l.453-1.357.038-.042.858.783-.038.042zm7.779-7.655l-5.576 6.1-.858-.783 5.576-6.1a.569.569 0 01.8-.036l.018.016a.57.57 0 01.036.8zm0 0"
              fill="#fff"
            />
          </g>
        </g>
        <path
          d="M12.8 5.843a.633.633 0 00-.633.633v5.373a1.612 1.612 0 01-1.61 1.611H2.881a1.613 1.613 0 01-1.611-1.611v-7.61a1.613 1.613 0 011.611-1.611h5.556a.633.633 0 100-1.267H2.881A2.881 2.881 0 000 4.239v7.61a2.881 2.881 0 002.877 2.877h7.676a2.881 2.881 0 002.877-2.877V6.472a.633.633 0 00-.633-.633zm0 0"
          fill="#fff"
        />
      </g>
    </svg>
  )
}
