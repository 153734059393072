import React from 'react'

import FirstLoginForm from '@Components/forms/login/FirstLoginForm'
import SvgWave from '@Components/icons/Wave'
import variant from '@Variants'

export default function FirstLogin() {
  return (
    <div>
      <div className="FirstLogin">
        <div className="Login-header">
          <img src={variant.logoWhite} alt="Logo" />
        </div>

        <div className="FirstLogin-left">
          <div className="FirstLogin-leftContainer">
            <h1 className="Login-title">
              Félicitations votre demande a bien été effectuée
            </h1>

            <span className="Login-subtitle">
              Votre demande a bien été effectuée. Vous pouvez suivre et gérer
              votre dossier en ligne à tout moment.
            </span>

            <span className="Login-subtitle">
              Profitez en toute liberté de votre espace sécurisé : gérez vos
              dossiers, transmettez vos documents, découvrez les avancées
              faites, discutez en live avec votre avocat (message / visio /
              rendez-vous physique)
            </span>
          </div>

          <SvgWave className="Login-wave" />
          <SvgWave className="Login-waveShadow" />
        </div>

        <div className="FirstLogin-right">
          <div className="FirstLogin-rightContainer">
            <FirstLoginForm />
          </div>
        </div>
      </div>
    </div>
  )
}
