import React from 'react'

export default function UserManagement() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.156" height="21.551">
      <g data-name="Group 3278" fill="#005a79">
        <path
          data-name="Path 9542"
          d="M5.98 18.732a9.961 9.961 0 01-1.215-.629 6.767 6.767 0 01-.325-.21l-.089-.063-.015-.011a.525.525 0 00-.732.093.533.533 0 00.076.735 11.515 11.515 0 005.511 2.822 7.062 7.062 0 001.06.08 7.336 7.336 0 001.514-.161 9.688 9.688 0 002.115-.723l.417.6a.528.528 0 00.926-.107l1.146-2.913a.528.528 0 00-.432-.719l-3.127-.353a.522.522 0 00-.5.239.531.531 0 00-.01.557l.326.545a6.446 6.446 0 01-2.707.906 8.114 8.114 0 01-3.939-.688z"
        />
        <path
          data-name="Path 9543"
          d="M15.11 3.447c.142.086.252.159.325.21l.085.06.019.014a.529.529 0 00.656-.829A11.521 11.521 0 0010.684.079 7.172 7.172 0 008.11.16a9.662 9.662 0 00-2.115.723l-.417-.6a.528.528 0 00-.926.107L3.505 3.303a.529.529 0 00.432.719l3.127.353a.489.489 0 00.06 0 .524.524 0 00.443-.242.532.532 0 00.01-.557 2.678 2.678 0 012.381-1.452 8.106 8.106 0 013.936.693 9.918 9.918 0 011.216.63z"
        />
        <path
          data-name="Path 9544"
          d="M3.669 11.664a2.894 2.894 0 10-2.9-2.895 2.874 2.874 0 002.901 2.895zm-1.505-4.4a2.128 2.128 0 111.506 3.634 2.128 2.128 0 01-1.505-3.634z"
        />
        <path
          data-name="Path 9545"
          d="M7.342 15.722a5.645 5.645 0 00-1.473-3.957.383.383 0 00-.492-.021 2.743 2.743 0 01-3.3 0 .384.384 0 00-.493.008 5.469 5.469 0 00-1.58 3.952.383.383 0 00.383.388h6.572a.384.384 0 00.383-.37zm-6.554-.394a4.846 4.846 0 011.091-2.777 3.432 3.432 0 003.682-.008 4.857 4.857 0 011 2.784z"
        />
        <path
          data-name="Path 9546"
          d="M14.438 6.717a2.895 2.895 0 002.048 4.947 2.9 2.9 0 10-2.049-4.942zm4.178 2.047a2.133 2.133 0 01-2.129 2.13 2.13 2.13 0 112.13-2.13z"
        />
        <path
          data-name="Path 9547"
          d="M18.682 11.764a.383.383 0 00-.492-.021 2.743 2.743 0 01-3.3 0 .384.384 0 00-.494.008 5.466 5.466 0 00-1.581 3.952.383.383 0 00.383.388h6.572a.383.383 0 00.383-.371 5.641 5.641 0 00-1.471-3.956zm-5.081 3.564a4.844 4.844 0 011.09-2.777 3.433 3.433 0 003.682-.008 4.854 4.854 0 011 2.784z"
        />
      </g>
    </svg>
  )
}
