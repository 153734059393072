import React from 'react'

export default function SearchIcon({ style }) {
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28.247 27.147"
    >
      <g data-name="Group 3341" fill="#005a79">
        <path
          data-name="Path 65"
          d="M22.54 4.2a7.783 7.783 0 00-11.737 10.163l-1.349 1.349a2.016 2.016 0 00-1.827.549l-5.8 5.8a2.015 2.015 0 002.849 2.849l5.8-5.8a2.014 2.014 0 00.55-1.827l1.349-1.349A7.782 7.782 0 0022.538 4.198zm-9.435 9.434a5.559 5.559 0 117.863 0 5.565 5.565 0 01-7.863 0zm0 0"
        />
        <path
          data-name="Path 66"
          d="M1.98 1.114h5.8V.003h-5.8A1.981 1.981 0 000 1.983v5.8h1.111v-5.8a.87.87 0 01.869-.869zm0 0"
        />
        <path
          data-name="Path 67"
          d="M27.136 19.365v5.8a.87.87 0 01-.868.869h-5.8v1.111h5.8a1.981 1.981 0 001.979-1.98v-5.8zm0 0"
        />
      </g>
    </svg>
  )
}
