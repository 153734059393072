import React, { useState, useEffect } from 'react'
import ChronologiesAPI from '@API/admin/parameters/chronologies'
import { useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import ChronologyEdit from '@Components/ChronologyEdit'
import ChronologyEditHeader from '@Components/ChronologyEditHeader'

export default function Chronology() {
  const { chronoId } = useParams()
  const [chronology, setChronology] = useState({})

  useEffect(() => {
    async function fetchChronology() {
      const { data } = await ChronologiesAPI.getChronologieById(chronoId)
      setChronology(data)
    }

    fetchChronology()
  }, [chronoId])

  return (
    <motion.div className="Parameters-chronology" exit={{ opacity: 0 }}>
      <ChronologyEditHeader chronology={chronology} apiMode="admin" />
      <ChronologyEdit
        chronology={chronology}
        setChronology={setChronology}
        apiMode="admin"
      />
    </motion.div>
  )
}
