import React, { useEffect, useState } from 'react'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { useForm } from 'react-hook-form'
import ChronologiesAPI from '@API/admin/parameters/chronologies'
import DomainsAPI from '@API/admin/parameters/domains'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import QMuiModal from '@CommonComponents/Mui/QMuiModal'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'
import QSelect from '@CommonComponents/QSelect'
import { useSnackbar } from '@Context/snackbar'
import {
  getErrorMsgFromSymfonyResponse,
  formatSymfonyValidationErrors,
} from '@Helpers'
import formRules from '@Utils/form-rules'

export default function AddChronologieModalForm({
  open,
  handleClose,
  handleAddChronologie,
}) {
  const {
    watch,
    register,
    control,
    handleSubmit,
    setValue,
    setError,
    errors,
  } = useForm()
  const [domains, setDomains] = useState([])
  const [selectedDomain, setSelectedDomain] = useState({})
  const watchDomain = watch('domain')
  const { addSnackbarMsg } = useSnackbar()

  useEffect(() => {
    async function onMount() {
      const { data } = await DomainsAPI.getAllDomainsAtOnce('admin')
      setDomains(data)

      setValue('domain', {})
    }
    onMount()
  }, [setValue])

  useEffect(() => {
    if (typeof watchDomain === 'number') {
      const domain = domains.find(({ id }) => id === watchDomain)
      setSelectedDomain(domain)
    }
  }, [watchDomain, domains])

  const handleAdd = async (data) => {
    try {
      delete data.domain
      const resp = await ChronologiesAPI.addChronologie(data)
      handleAddChronologie(resp.data)
      handleClose()
      addSnackbarMsg({
        severity: 'success',
        content: 'Chronologie ajoutée',
      })
    } catch (error) {
      const resp = error.response

      if (resp.data.message === 'Validation Failed') {
        const formatedErrors = formatSymfonyValidationErrors(resp.data.errors)

        for (let key in formatedErrors) {
          setError(key, formatedErrors[key])
        }
      } else {
        addSnackbarMsg({
          severity: 'error',
          content: getErrorMsgFromSymfonyResponse(error.response),
        })
      }
    }
  }

  return (
    <QMuiModal
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      title="Ajouter une chronologie"
    >
      <form onSubmit={handleSubmit(handleAdd)}>
        <DialogContent className="flex flex-col">
          <QSelect
            name="domain"
            label="Domaine"
            style={{ marginBottom: 20 }}
            control={control}
            rules={{ ...formRules.isRequired }}
            errorMsg={errors?.domain?.message}
            options={domains.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
          />

          <QSelect
            name="subdomain"
            label="Sous domaine"
            control={control}
            rules={{ ...formRules.isRequired }}
            errorMsg={errors?.subdomain?.message}
            isDisabled={!('id' in selectedDomain)}
            style={{ marginBottom: 20 }}
            options={
              'subdomains' in selectedDomain
                ? selectedDomain.subdomains.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))
                : []
            }
          />

          <QMuiFormInput
            label="Nom"
            name="name"
            register={register({ ...formRules.isRequired })}
            control={control}
            errorMsg={errors?.name?.message}
          />
        </DialogContent>

        <DialogActions>
          <QMuiButton color="danger" onClick={handleClose}>
            Annuler
          </QMuiButton>
          <QMuiButton type="submit" color="primary">
            Enregistrer
          </QMuiButton>
        </DialogActions>
      </form>
    </QMuiModal>
  )
}
