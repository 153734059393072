import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import QMuiDataGrid from '@Components/common/Mui/QMuiDataGrid'
import QMuiLinkButton from '@Components/common/Mui/QMuiLinkButton'
import StepTypesAPI from '@API/admin/parameters/step-types'
import { motion } from 'framer-motion'
import Plus from '@Components/icons/Plus'
import Update from '@Components/icons/Update'

export default function ClientList() {
  const [isLoading, setIsLoading] = useState(true)
  const [clientList, setClientList] = useState([])
  const [sortParams, setSortParams] = useState(null)

  const history = useHistory()

  const showStepType = (params) => {
    if (params.field !== 'action') {
      history.push(`/admin/parameters/step-types/${params.row.id}`)
    }
  }

  useEffect(() => {
    async function getList() {
      const {
        data: { results: list },
      } = await StepTypesAPI.getAllStepTypes(sortParams)
      setClientList(list)
      setIsLoading(false)
    }
    getList()
  }, [sortParams])

  const columns = [
    {
      headerName: 'Image',
      field: 'image',
      width: 150,
      sortable: false,
      renderCell: (params) =>
        params.value ? (
          <img className="profile-pic" src={params.value} alt="Thumbnail" />
        ) : null,
    },
    {
      headerName: 'Nom',
      field: 'name',
      flex: 1,
    },
    {
      headerName: '',
      field: 'action',
      disableClickEventBubbling: true,
      width: 320,
      renderCell: (params) => (
        <div>
          <QMuiLinkButton
            to={`/admin/parameters/step-types/${params.row.id}`}
            style={{ marginLeft: 16 }}
            size="small"
          >
            Modifier
            <Update width={13} style={{ marginLeft: 5 }} />
          </QMuiLinkButton>
        </div>
      ),
    },
  ]

  return (
    <motion.div exit={{ opacity: 0 }} transition={{ duration: 0.5 }}>
      <h1 className="page-title">Types d'étapes</h1>

      <header className="flex align-center justify-end">
        <QMuiLinkButton to="/admin/parameters/step-types/new">
          Ajouter un type
          <Plus width={15} style={{ marginLeft: 12 }} />
        </QMuiLinkButton>
      </header>

      <QMuiDataGrid
        sortParamsMode="server"
        sortModelPrefix="stepType"
        onSortParams={setSortParams}
        rowHeight={80}
        onCellClick={(params) => showStepType(params)}
        rows={clientList}
        columns={columns}
        loading={isLoading}
      />
    </motion.div>
  )
}
