import APIClient from '../APIClient'

const CUSTOMER_URI = '/api/admin/customers'

export const findCustomers = async (page, search, sortParams) => {
  let params = { page }
  if (search !== '') {
    params.search = search
  }
  if (sortParams) {
    params = { ...params, ...sortParams }
  }
  return await APIClient.get('/api/admin/customers', { params })
}

export const getAllCustomers = async () => {
  return await APIClient.get('/api/admin/customers/all')
}

export const getCustomerById = async (customerId, apiMode) => {
  return await APIClient.get(`/api/${apiMode}/customers/${customerId}`)
}

export const getCustomers = async () => {
  return await APIClient.get('/api/customer/useful-information')
}

/**
 * @param {*} payload
 * @param {string} payload.civility
 * @param {string} payload.lastName
 * @param {string} payload.firstName
 * @param {string} payload.email
 * @param {string} payload.phone
 * @param {string} payload.mobile
 * @param {string} payload.active
 * @param {string} payload.plainPassword
 */
export const postCustomer = async (payload) => {
  return await APIClient.post('/api/admin/customers', payload)
}

/**
 * @param {number} customerId
 * @param {*} payload
 * @param {string} payload.civility
 * @param {string} payload.lastName
 * @param {string} payload.firstName
 * @param {string} payload.email
 * @param {string} payload.phone
 * @param {string} payload.mobile
 * @param {string} payload.active
 * @param {string} payload.plainPassword
 */
export const patchCustomer = async (customerId, payload) => {
  return await APIClient.patch(`/api/admin/customers/${customerId}`, payload)
}

export const deleteCustomer = async (customerId) => {
  return await APIClient.delete(`/api/admin/customers/${customerId}`)
}

/**
 * @param {number} customerId
 * @param {*} payload
 * @param {string} payload.name
 * @param {string} payload.type
 * @param {string} payload.content
 * @param {string} payload.company
 * @param {string} payload.subdomain
 * @param {string} payload.referent
 * @param {array} payload.experts
 * @param {string} payload.document
 */
export const postFolder = async (customerId, payload) => {
  return await APIClient.post(
    `/api/admin/customers/${customerId}/folders`,
    payload
  )
}

export const getAllFoldersForCustomerId = async (
  customerId,
  page,
  search,
  sortParams
) => {
  let params = { page }
  if (search !== '') {
    params.search = search
  }
  if (sortParams) {
    params = { ...params, ...sortParams }
  }
  return await APIClient.get(CUSTOMER_URI + `/${customerId}/folders`, {
    params,
  })
}
