export function getErrorMsgFromSymfonyResponse(resp) {
  if (resp === undefined) {
    return 'Une erreur est survenue'
  }

  if (resp.data.message === 'Validation Failed' && resp.data.errors) {
    for (let field in resp.data.errors.children) {
      if (
        resp.data.errors.children[field].errors &&
        resp.data.errors.children[field].errors.length > 0
      ) {
        return resp.data.errors.children[field].errors[0]
      }
    }
  } else if (resp.data.message !== undefined) {
    return resp.data.message
  }

  return 'Une erreur est survenue'
}

export function formatSymfonyValidationErrors(errors) {
  function formatSymfonyValidationErrorsRec(errors) {
    const errorEntries = []

    Object.entries(errors.children).forEach(([key, value]) => {
      if ('errors' in value) {
        errorEntries.push([key, { message: value.errors[0] }])
      }

      if ('children' in value) {
        const children = formatSymfonyValidationErrors(value)

        Object.entries(children).forEach(([childKey, childValue]) => {
          errorEntries.push([`${key}.${childKey}`, childValue])
        })
      }
    })

    return errorEntries
  }

  return Object.fromEntries(formatSymfonyValidationErrorsRec(errors))
}

export function getCSSHeight(element, withoutPadding = false) {
  const rect = element.getBoundingClientRect()

  if (withoutPadding) {
    const computedStyle = getComputedStyle(element)
    return (
      rect.bottom -
      rect.top -
      parseInt(computedStyle.getPropertyValue('padding-top')) -
      parseInt(computedStyle.getPropertyValue('padding-bottom'))
    )
  }

  return rect.bottom - rect.top
}

export function fileToBase64(fileInput) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(fileInput)
    reader.onload = () => resolve(reader.result.split(',')[1])
    reader.onerror = (error) => reject(error)
  })
}

export function getAllPaths(object) {
  if (object === null) return []
  return Object.keys(object)
    .map((key) => {
      let path = key
      if (typeof object[key] === 'object' && !Array.isArray(object[key])) {
        return getAllPaths(object[key]).map(
          (nestedPath) => path + `.${nestedPath}`
        )
      } else {
        return key
      }
    })
    .flat()
}
