import React, { useState, useEffect } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import classNames from 'classnames'
import Select from 'react-select'
import QAppearDown from '@CommonComponents/transitions/QAppearDown'
import classnames from 'classnames'
import CreatableSelect from 'react-select/creatable'
import AsyncSelect from 'react-select/async'

function QSelect({
  placeholder = '',
  creatable,
  async,
  icon,
  className,
  name,
  label,
  defaultOptions,
  options,
  rules,
  control,
  errorMsg,
  defaultValue,
  selectStyles,
  isMulti,
  noOptionsMessage,
  loadOptions,
  ...props
}) {
  const [isFocus, setIsFocus] = useState(false)
  const [isEmpty, setIsEmpty] = useState(true)
  const hasErrors = typeof errorMsg === 'string'
  const watchValue = useWatch({ control, name })

  useEffect(() => {
    if (!watchValue || (isMulti && watchValue.length === 0)) {
      if (!isEmpty) {
        setIsEmpty(true)
      }
    } else {
      if (isEmpty) {
        setIsEmpty(false)
      }
    }
  }, [watchValue, isEmpty, isMulti])

  const selectRealStyles = {
    control: (styles) => {
      const baseStyles = selectStyles?.control
        ? selectStyles.control(styles)
        : styles
      return {
        ...baseStyles,
        borderColor: hasErrors ? '#f44336' : '#005a79',
      }
    },
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  }
  let Component
  if (creatable) {
    Component = CreatableSelect
  } else if (async) {
    Component = AsyncSelect
  } else {
    Component = Select
  }

  return (
    <div
      style={props.style}
      className={classNames('Qselect', className, { isFocus: isFocus })}
    >
      <div className="QSelect-container">
        {icon && (
          <img className="QSelect-icon" src={`/${icon}.png`} alt="Input icon" />
        )}

        {label && (
          <label
            className={classnames('input-label', {
              isFocused: isFocus || !isEmpty,
            })}
            style={hasErrors ? { color: '#f44336' } : {}}
          >
            {label}
          </label>
        )}

        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ onChange, value }) => {
            if (async) {
              return (
                <Component
                  {...props}
                  isMulti={isMulti}
                  styles={selectRealStyles}
                  classNamePrefix="QSelect"
                  name={name}
                  cacheOptions
                  defaultOptions={defaultOptions}
                  defaultValue={defaultValue}
                  options={options}
                  placeholder={placeholder}
                  onFocus={() => setIsFocus(true)}
                  onBlur={() =>
                    props.value && props.value.length > 0
                      ? setIsFocus(true)
                      : setIsFocus(false)
                  }
                  selectStyles={{}}
                  noOptionsMessage={() => noOptionsMessage || "Pas d'options"}
                  loadingMessage={() => 'Chargement...'}
                  loadOptions={loadOptions}
                />
              )
            } else {
              const val = !creatable
                ? isMulti
                  ? options.filter((opt) => value?.includes(opt.value))
                  : options.find((opt) => opt.value === value)
                : value?.map((sector) => ({
                    value: sector,
                    label: sector,
                  }))

              return (
                <Component
                  {...props}
                  isMulti={isMulti}
                  styles={selectRealStyles}
                  classNamePrefix="QSelect"
                  defaultValue={defaultValue}
                  name={name}
                  value={val}
                  placeholder={placeholder}
                  options={options}
                  onFocus={() => setIsFocus(true)}
                  onBlur={() => setIsFocus(false)}
                  menuPortalTarget={document.body}
                  selectStyles={{}}
                  noOptionsMessage={() => noOptionsMessage || "Pas d'options"}
                  loadOptions={loadOptions}
                  onChange={(option) =>
                    onChange(
                      !option
                        ? null
                        : isMulti
                        ? option?.map((opt) => opt.value)
                        : option.value
                    )
                  }
                />
              )
            }
          }}
        />
      </div>
      <QAppearDown isActive={hasErrors} duration=".3s">
        <span className="input-error-msg">{errorMsg}</span>
      </QAppearDown>
    </div>
  )
}

export default QSelect
