import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import classnames from 'classnames'
import Menu from '@CommonComponents/Menu'
import AskMenuIcon from './icons/AskMenu'
import Parameters from './icons/menu/Parameters'
import UserManagement from './icons/menu/UserManagement'
import Messages from './icons/menu/Messages'
import Accounting from './icons/menu/Accounting'
import Folders from './icons/menu/Folders'
import Customers from './icons/menu/Customers'
import Lawyers from './icons/menu/Lawyers'

function AdminMenu({ isOpen, setIsOpen }) {
  const filesMenu = [
    {
      icon: <AskMenuIcon />,
      title: 'Demandes',
      linkTo: '/admin/requests',
    },
    {
      icon: <Messages />,
      title: 'Messageries',
      linkTo: '/admin/messages',
    },
    {
      icon: <Accounting />,
      title: 'Compta',
    },
    {
      icon: <Folders />,
      title: 'Dossiers',
      linkTo: '/admin/folders',
    },
    {
      icon: <Customers />,
      title: 'Clients',
      linkTo: '/admin/clients',
    },
    {
      icon: <Lawyers />,
      title: 'Avocats',
      linkTo: '/admin/lawyers',
    },
  ]

  const adminMenu = [
    {
      icon: <Parameters />,
      title: 'Paramètres',
      linkTo: '/admin/parameters/domains',
    },
    {
      icon: <UserManagement />,
      title: 'Gestion des utilisateurs',
      linkTo: '/admin/users',
    },
  ]

  const history = useHistory()

  useEffect(() => {
    const unlisten = history.listen(() => {
      setIsOpen(false)
    })

    return () => unlisten()
  }, [setIsOpen, history])

  return (
    <div className={classnames('AdminMenu', { isOpen })}>
      <div className="AdminMenu-static">
        <div className="AdminMenu-top">
          <div>
            <span className="AdminMenu-title">Gestion des dossiers</span>
            <Menu itemStyle="AdminMenu-item" items={filesMenu} />
          </div>
          <div className="AdminMenu-bottom">
            <span className="AdminMenu-title">Gestion Admin</span>
            <Menu itemStyle="AdminMenu-item" items={adminMenu} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminMenu
