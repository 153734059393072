import React, { useState, useEffect } from 'react'
import QMuiDataGrid from '@Components/common/Mui/QMuiDataGrid'
import QMuiButton from '@Components/common/Mui/QMuiButton'
import QMuiLinkButton from '@Components/common/Mui/QMuiLinkButton'
import { findCustomers } from '@API/admin/customers'
import { Add as AddIcon } from '@material-ui/icons'
import { ReactComponent as RightArrow } from '@Assets/svg/arrow-right.svg'
import SearchIcon from '@Components/icons/SearchIcon'
import QMuiInput from '@CommonComponents/Mui/QMuiInput'
import Breadcrumb from '@CommonComponents/Breadcrumb'
import { InputAdornment } from '@material-ui/core'
import { debounce } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import { DateTime } from 'luxon'

export default function ClientList() {
  const [isLoading, setIsLoading] = useState(true)
  const [clientList, setClientList] = useState([])
  const [search, setSearch] = useState(null)
  const [sortParams, setSortParams] = useState(null)
  const [pageCount, setPageCount] = useState(1)

  const history = useHistory()

  const pageNum = parseInt(
    new URLSearchParams(useLocation().search).get('p') || 1
  )

  useEffect(() => {
    async function getList() {
      const resp = await findCustomers(pageNum, search, sortParams)
      const count = Math.ceil(
        resp.data.meta.total_items / resp.data.meta.items_per_page
      )
      setPageCount(count)
      setClientList(resp.data.results)
      setIsLoading(false)
    }
    getList()
  }, [pageNum, search, sortParams])

  const columns = [
    { headerName: 'N°Client', field: 'customerNumber', width: 100 },
    { headerName: 'Prénom', field: 'firstName', width: 140 },
    { headerName: 'Nom', field: 'lastName', width: 140 },
    {
      headerName: 'Entreprises',
      field: 'companies',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const companies = params.row.companies

        if (companies.length === 0) {
          return ''
        }

        if (companies.length === 1) {
          return companies[0].name
        }

        return (
          <div>
            <Tooltip
              title={companies.map((c) => c.name).join(', ')}
              arrow
              classes={{ tooltip: 'tooltip' }}
            >
              <span className="badge">{companies.length}</span>
            </Tooltip>
          </div>
        )
      },
    },
    { headerName: 'Email', field: 'email', flex: 1 },
    { headerName: 'Téléphone', field: 'phone', width: 140 },
    {
      headerName: 'Création',
      field: 'createdAt',
      width: 170,
      valueFormatter: ({ value }) => DateTime.fromISO(value).toLocaleString(),
    },
    {
      headerName: 'Modification',
      field: 'updatedAt',
      width: 170,
      valueFormatter: ({ value }) => DateTime.fromISO(value).toLocaleString(),
    },
    {
      headerName: '',
      field: 'action',
      disableClickEventBubbling: true,
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <QMuiLinkButton
          to={`/admin/clients/${params.row.id}/folders`}
          variant="text"
          color="transparent"
        >
          <RightArrow width={20} />
        </QMuiLinkButton>
      ),
    },
  ]

  const debounceSearch = debounce(
    async (e) =>
      e.target.value !== '' ? setSearch(e.target.value) : setSearch(null),
    800
  )

  const showCustomer = (params) => {
    if (params.field !== 'action') {
      history.push(`/admin/clients/${params.row.id}/folders`)
    }
  }

  return (
    <div className="page-container">
      <Breadcrumb items={[{ label: 'Clients' }]} />

      <h1 className="page-title">Liste des clients</h1>

      <header className="flex align-items-center justify-between mb-4">
        <QMuiButton
          onClick={() => history.push('/admin/clients/new')}
          endIcon={<AddIcon />}
        >
          Ajouter client
        </QMuiButton>
        <QMuiInput
          onChange={debounceSearch}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon style={{ width: 24, height: 24 }} />
            </InputAdornment>
          }
        />
      </header>

      <QMuiDataGrid
        sortParamsMode="server"
        sortModelPrefix="user"
        onSortParams={setSortParams}
        rows={clientList}
        columns={columns}
        loading={isLoading}
        onCellClick={(params) => showCustomer(params)}
        noResultMsg="Aucun client"
        page={pageNum}
        count={pageCount}
        handlePaginationChange={(e, page) => history.push(`?p=${page}`)}
      />
    </div>
  )
}
