import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import formRules from '@Utils/form-rules'
import QMuiFormInput from '@CommonComponents/Mui/QMuiFormInput'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import QMuiTextarea from '@CommonComponents/Mui/QMuiTextarea'
import QMuiModal from '@CommonComponents/Mui/QMuiModal'
import { useSnackbar } from '@Context/snackbar'

import { getCustomerById, patchCustomer } from '@API/admin/customers'

import {
  formatSymfonyValidationErrors,
  getErrorMsgFromSymfonyResponse,
} from '@Helpers'

export default function CompanyUploadForm({
  open,
  onClose,
  apiMode,
  clientId,
}) {
  const { register, control, handleSubmit, errors } = useForm()
  const [loading, setLoading] = useState(false)
  const { addSnackbarMsg } = useSnackbar()

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      let response = await getCustomerById(clientId, apiMode)
      const client = response.data
      let companies = []
      if (client.companies.length > 0) {
        client.companies.forEach((company) => {
          companies.push({
            name: company.name ? company.name : '',
            siren: company.siren ? company.siren : '',
            sector: company.sector ? company.sector : '',
            leader: company.leader ? company.leader : '',
            description: company.description ? company.description : '',
            address: company.address
              ? {
                  street: company.street ? company.street : '',
                  postalCode: company.postalCode ? company.postalCode : '',
                  city: company.city ? company.city : '',
                }
              : null,
          })
        })
      }
      companies.push(data.company)
      data.companies = companies
      delete data['company']
      response = await patchCustomer(clientId, data)
      setLoading(false)
      onClose(response.data.companies)
    } catch (error) {
      setLoading(false)
      const resp = error.response
      if (resp.data.message === 'Validation Failed') {
        const formatedErrors = formatSymfonyValidationErrors(resp.data.errors)
        addSnackbarMsg({
          severity: 'error',
          content: getLast(formatedErrors),
        })
      } else {
        addSnackbarMsg({
          severity: 'error',
          content: getErrorMsgFromSymfonyResponse(error.response),
        })
      }
    }
  }

  const getLast = (object) => {
    let prop
    if (typeof object !== 'object') {
      return object
    }
    for (prop in object) {
      return getLast(object[prop])
    }
  }

  const onError = (errors) => {
    for (let key in errors) {
      if (['fileName', 'fileContent'].includes(key)) {
        addSnackbarMsg({
          severity: 'error',
          content: errors[key].message,
        })
      }
    }
  }

  return (
    <QMuiModal open={open} title="Ajouter une entreprise">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <fieldset>
          <QMuiFormInput
            className="form-field"
            label="Raison sociale*"
            name="company.name"
            register={register({ ...formRules.isRequired })}
            control={control}
            disabled={loading}
            errorMsg={errors?.company?.name?.message}
          />

          <QMuiFormInput
            className="form-field"
            label="Siren*"
            name="company.siren"
            register={register({ ...formRules.isRequired })}
            control={control}
            disabled={loading}
            errorMsg={errors?.company?.siren?.message}
          />

          <QMuiFormInput
            className="form-field"
            label="Secteur"
            register={register}
            name="company.sector"
            control={control}
            disabled={loading}
            errorMsg={errors?.company?.sector?.message}
          />

          <QMuiFormInput
            className="form-field"
            label="Dirigeant"
            name="company.leader"
            register={register}
            control={control}
            disabled={loading}
            errorMsg={errors?.company?.leader?.message}
          />

          <QMuiFormInput
            className="form-field"
            label="Adresse"
            name="company.address.street"
            register={register}
            control={control}
            disabled={loading}
            errorMsg={errors?.company?.address?.street?.message}
          />

          <QMuiFormInput
            className="form-field"
            label="Ville"
            name="company.address.city"
            register={register}
            control={control}
            disabled={loading}
            errorMsg={errors?.company?.address?.city?.message}
          />

          <QMuiFormInput
            className="form-field"
            label="Code postal"
            name="company.address.postalCode"
            register={register}
            control={control}
            disabled={loading}
            errorMsg={errors?.company?.address?.postalCode?.message}
          />
          <QMuiTextarea
            className="form-field"
            rowsMin={3}
            register={register}
            label="Description"
            name="company.description"
            control={control}
            disabled={loading}
            errorMsg={errors?.company?.description}
          />
        </fieldset>
        <div className="flex p-4">
          <QMuiButton
            color="danger"
            className="ml-auto"
            onClick={onClose}
            disabled={loading}
          >
            Annuler
          </QMuiButton>
          <QMuiButton type="submit" className="ml-4" disabled={loading}>
            Envoyer
          </QMuiButton>
        </div>
      </form>
    </QMuiModal>
  )
}
