import { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import DOMPurify from 'dompurify'
import { Editor } from '@tinymce/tinymce-react'
import { InputBase } from '@material-ui/core'
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  Close as CloseIcon,
} from '@material-ui/icons'
import FoldersApi from '@API/admin/folders'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import QAppearDown from '@CommonComponents/transitions/QAppearDown'
import formRules from '@Utils/form-rules'
import editIcon from '@Assets/img/edit.png'
import { useSnackbar } from '@Context/snackbar'
import { useAuth } from '@Context/auth'
import { getErrorMsgFromSymfonyResponse } from '@Helpers'
import QMuiButtonConfirmation from '@CommonComponents/Mui/QMuiButtonConfirmation'

export default function FolderSynthesis({ folder, apiMode }) {
  const [items, setItems] = useState([])
  const [isAdding, setIsAdding] = useState(false)
  const [needsReload, setNeedsReload] = useState(false)

  const { addSnackbarMsg } = useSnackbar()

  const { user } = useAuth()

  useEffect(() => {
    async function fetchSynthesis() {
      const { data } = await FoldersApi.getSynthesisForOne(folder.id, apiMode)
      setItems(
        data.map((item) => ({
          ...item,
          content: item.content,
          isActive: false,
          isEdited: false,
        }))
      )
    }
    if (folder) {
      fetchSynthesis()
    }
  }, [folder, needsReload, apiMode])

  const toggleActiveItem = (itemId) => {
    setItems(
      items.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            isActive: !item.isActive,
          }
        }
        return item
      })
    )
  }

  const editItem = (itemId) => {
    setIsAdding(false)
    setItems(
      items.map((item) => ({
        ...item,
        isEdited: item.id === itemId,
      }))
    )
  }

  const onEditDone = () => {
    setItems(
      items.map((item) => ({
        ...item,
        isEdited: false,
      }))
    )
    setNeedsReload(!needsReload)
    setIsAdding(false)
  }

  const onDelete = async (itemId) => {
    setItems(items.filter((item) => item.id !== itemId))

    try {
      await FoldersApi.deleteSynthesis(itemId, apiMode)
      addSnackbarMsg({
        severity: 'success',
        content: 'Élément supprimé',
      })
    } catch {
      addSnackbarMsg({
        severity: 'error',
        content: "Échec de suppression de l'élément",
      })
      setNeedsReload(!needsReload)
    }
  }

  return (
    <div className="FolderSynthesis">
      <div className="FolderSynthesis-head">
        <h2>Synthèse du dossier</h2>
        {['admin', 'expert'].includes(apiMode) && (
          <QMuiButton isIcon onClick={() => setIsAdding(true)}>
            <AddIcon />
          </QMuiButton>
        )}
      </div>

      <ul className="is-unstyled mb-0">
        {items.map((item) => {
          if (item.isEdited) {
            return (
              <FolderSynthesisForm
                apiMode={apiMode}
                folderId={folder.id}
                item={item}
                onCancel={() => editItem(null)}
                onDone={onEditDone}
                onDelete={() => onDelete(item.id)}
              />
            )
          }

          return (
            <li key={item.id} className="FolderSynthesis-item">
              <div className="flex align-items-center justify-between">
                <h4 onClick={() => toggleActiveItem(item.id)}>{item.title}</h4>
                <div>
                  {(apiMode === 'admin' || folder?.referent.id === user.id) && (
                    <QMuiButton onClick={() => editItem(item.id)} isIcon>
                      <img alt="edit" src={editIcon} />
                    </QMuiButton>
                  )}
                  <QMuiButton onClick={() => toggleActiveItem(item.id)} isIcon>
                    {item.isActive ? <RemoveIcon /> : <AddIcon />}
                  </QMuiButton>
                </div>
              </div>

              <QAppearDown duration="200ms" isActive={item.isActive}>
                <div
                  className="pb-4"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(item.content),
                  }}
                ></div>
              </QAppearDown>
            </li>
          )
        })}

        {!isAdding && items.length === 0 && (
          <li>
            <span className="FolderSynthesis-noResults">
              Il n'y a aucun élément dans cette synthèse
            </span>
          </li>
        )}

        {isAdding && (
          <FolderSynthesisForm
            apiMode={apiMode}
            folderId={folder.id}
            onCancel={() => setIsAdding(false)}
            onDone={onEditDone}
          />
        )}
      </ul>
    </div>
  )
}

function FolderSynthesisForm({
  apiMode,
  folderId,
  item,
  onCancel,
  onDone,
  onDelete,
}) {
  const { register, handleSubmit, control, setValue } = useForm()

  const { addSnackbarMsg } = useSnackbar()

  useEffect(() => {
    if (item) {
      setValue('title', item.title)
      setValue('content', item.content)
    }
  }, [item, setValue])

  const onSubmit = async (data) => {
    try {
      if (item) {
        await FoldersApi.patchSynthesis(item.id, data, apiMode)
      } else {
        await FoldersApi.postSynthesis(folderId, data, apiMode)
      }

      addSnackbarMsg({
        severity: 'success',
        content: `Synthèse ${item ? 'modifiée' : 'ajoutée'}`,
      })

      onDone()
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  const onError = (errors) => {
    if (errors?.title?.message) {
      addSnackbarMsg({
        severity: 'error',
        content: `Titre: ${errors.title.message}`,
      })
    } else if (errors?.content?.message) {
      addSnackbarMsg({
        severity: 'error',
        content: `Contenu: ${errors.content.message}`,
      })
    }
  }

  return (
    <li className="FolderSynthesis-item">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="flex align-items-center justify-between">
          <InputBase
            className="FolderSynthesis-titleInput"
            name="title"
            placeholder="Titre"
            inputRef={register({ ...formRules.isRequired })}
            autoFocus
          />
          <div>
            <QMuiButton isIcon onClick={onCancel}>
              <CloseIcon />
            </QMuiButton>
          </div>
        </div>

        <div className="pb-4">
          <Controller
            name="content"
            control={control}
            rules={{ ...formRules.isRequired }}
            render={({ onChange, value }) => (
              <Editor
                init={{
                  height: 400,
                  branding: false,
                  language: 'fr_FR',
                  language_url: '/langs/fr_FR.js',
                }}
                value={value}
                onEditorChange={(content) => onChange(content)}
              />
            )}
          />

          <div className="flex justify-end mt-4">
            {item?.id && (
              <QMuiButtonConfirmation
                color="danger"
                className="mr-2"
                handleConfirmation={onDelete}
                confirmation="Voulez-vous vraiment supprimer cet élément ?"
              >
                Supprimer
              </QMuiButtonConfirmation>
            )}
            <QMuiButtonConfirmation
              btnText="Enregistrer"
              confirmation="Êtes-vous sûr de vouloir enregistrer ?"
              type="submit"
            >
              Enregistrer
            </QMuiButtonConfirmation>
          </div>
        </div>
      </form>
    </li>
  )
}
