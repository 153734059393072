import React, { useState, useEffect } from 'react'
import ChronologiesAPI from '@API/admin/parameters/chronologies'
import { useHistory } from 'react-router-dom'
import SearchIcon from '@Components/icons/SearchIcon'
import AddChronologieModalForm from '@Components/forms/AddChronologieModalForm'
import { debounce } from 'lodash'
import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import QMuiDataGrid from '@CommonComponents/Mui/QMuiDataGrid'
import QMuiInput from '@CommonComponents/Mui/QMuiInput'
import { InputAdornment } from '@material-ui/core'
import { motion } from 'framer-motion'
import { useSnackbar } from '@Context/snackbar'
import { getErrorMsgFromSymfonyResponse } from '@Helpers'
import QMuiButtonConfirmation from '@CommonComponents/Mui/QMuiButtonConfirmation'
import Plus from '@Components/icons/Plus'
import Update from '@Components/icons/Update'

export default function ChronologyList() {
  const [chronologiesList, setChronologiesList] = useState([])
  const [addModalShow, setAddModalShow] = useState(false)
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const { addSnackbarMsg } = useSnackbar()
  const [page, setPage] = useState(1)
  const [countPage, setCountPage] = useState(1)
  const [sortParams, setSortParams] = useState(null)
  const columns = [
    {
      headerName: 'Nom',
      field: 'name',
      flex: 1,
    },
    {
      headerName: 'Domaine',
      field: 'subdomain.domain.name',
      flex: 1,
    },
    {
      headerName: 'Sous domaine',
      field: 'subdomain.name',
      flex: 1,
    },
    {
      headerName: "Nombre d'etapes",
      field: 'steps.length',
      type: 'number',
      flex: 1,
      sortable: false,
    },
    {
      headerName: '',
      field: 'action',
      disableClickEventBubbling: true,
      width: 385,
      renderCell: (params) => (
        <div>
          <QMuiButton
            onClick={() => updateChronology(params)}
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
          >
            Modifier
            <Update width={13} style={{ marginLeft: 5 }} />
          </QMuiButton>
          <QMuiButtonConfirmation
            handleConfirmation={() => duplicateChronologie(params.row.id)}
            confirmation="Êtes-vous sur de vouloir dupliquer ce schema ?"
            btnText="Dupliquer"
            variant="contained"
            color="secondary"
            size="small"
            style={{ marginLeft: 16 }}
          >
            Dupliquer
          </QMuiButtonConfirmation>
          <QMuiButtonConfirmation
            handleConfirmation={() => deleteChronologie(params.row.id)}
            color="danger"
            size="small"
            confirmation="Êtes-vous sur de vouloir supprimer ce schema ?"
            style={{ marginLeft: 16 }}
          >
            Supprimer
          </QMuiButtonConfirmation>
        </div>
      ),
    },
  ]

  const duplicateChronologie = async (chronoId) => {
    try {
      await ChronologiesAPI.duplicateChronologie(chronoId)
      setToggleReload(!toggleReload)
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  const showChronology = (params) => {
    if (params.field !== 'action') {
      history.push(`/admin/parameters/chrono/${params.row.id}/schema`)
    }
  }

  const updateChronology = (params) => {
    history.push(`/admin/parameters/chrono/${params.row.id}/schema`)
  }

  const deleteChronologie = async (chronoId) => {
    try {
      await ChronologiesAPI.deleteChronologie(chronoId)
      const index = chronologiesList.findIndex(
        (chrono) => chrono.id === chronoId
      )
      const copyList = [...chronologiesList]
      copyList.splice(index, 1)
      setChronologiesList(copyList)
      addSnackbarMsg({
        severity: 'success',
        content: 'Chronologie supprimée',
      })
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  const debounceSearch = debounce(async (e) => setSearch(e.target.value), 800)

  const handleAddChronologie = (newChronologie) => {
    setChronologiesList([...chronologiesList, newChronologie])
  }

  const handlePaginationChange = (value) => {
    setPage(value)
  }

  const [toggleReload, setToggleReload] = useState(true)
  useEffect(() => {
    async function getChronologies() {
      setIsLoading(true)
      try {
        const { data } = await ChronologiesAPI.getAllChronologies(
          page,
          search,
          sortParams
        )
        setCountPage(
          Math.ceil(data.meta.total_items / data.meta.items_per_page)
        )
        setChronologiesList(data.results)
        setIsLoading(false)
      } catch (e) {}
    }
    getChronologies()
  }, [search, page, sortParams, toggleReload])

  return (
    <motion.div exit={{ opacity: 0 }}>
      <h1 className="page-title">Listing schéma des chronologies</h1>

      <div className="flex justify-end">
        <QMuiInput
          style={{ marginRight: 10 }}
          onChange={debounceSearch}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon style={{ width: 24, height: 24 }} />
            </InputAdornment>
          }
        />
        <QMuiButton
          onClick={() => setAddModalShow(!addModalShow)}
          variant="contained"
          color="primary"
        >
          Ajouter un schéma
          <Plus width={15} style={{ marginLeft: 12 }} />
        </QMuiButton>
        <AddChronologieModalForm
          open={addModalShow}
          handleClose={() => setAddModalShow(false)}
          handleAddChronologie={handleAddChronologie}
        />
      </div>

      <QMuiDataGrid
        sortModelPrefix="domain"
        onSortParams={setSortParams}
        rows={chronologiesList}
        columns={columns}
        onCellClick={(params) => showChronology(params)}
        count={countPage}
        loading={isLoading}
        handlePaginationChange={handlePaginationChange}
        sortParamsMode="server"
        noResultMsg="Aucune chronologie"
      />
    </motion.div>
  )
}
