import React, { useEffect, useState, useRef } from 'react'
import {
  useParams,
  useLocation,
  matchPath,
  Redirect,
  useRouteMatch,
} from 'react-router-dom'
import { Switch, Route } from 'react-router-dom'
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons'
import cx from 'classnames'
import { debounce } from 'lodash'

import { useAuth } from '@Context/auth'
import variant from '@Variants'

import FolderAPI from '@API/admin/folders'
import QMuiLinkButton from '@CommonComponents/Mui/QMuiLinkButton'
import FolderChronology from './FolderChronology'
import QualyTeam from './QualyTeam'
import Synthesis from './Synthesis'
import CustomerService from './CustomerService'
import Documents from './Documents'
import Billing from './Billing'
import LawyerCollaboration from './LawyerCollaboration'
import FolderChronologyForm from '@Components/forms/folder/FolderChronologyForm'
import FoldersApi from '@API/admin/folders'

export default function Folder({ apiMode }) {
  const [folder, setFolder] = useState()
  const [chronology, setChronology] = useState(null)
  const [folderName, setFolderName] = useState()
  const [folderDescription, setFolderDescription] = useState()
  const { folderId } = useParams()

  const location = useLocation()

  const [toggleFolderRefresh, setToggleFolderRefresh] = useState(false)
  const refreshFolder = () => setToggleFolderRefresh((current) => !current)
  useEffect(() => {
    async function fetchFolder() {
      const { data: folder } = await FolderAPI.getOne(folderId, apiMode)
      setFolder(folder)
      setFolderName(folder.name)
      setFolderDescription(
        `${folder.customer.civility === 'm' ? 'Mr' : 'Mme'} ${
          folder.customer.lastName
        } - Dossier N°${folder.folderNumber} - ${folder.subdomain.name}`
      )
    }
    fetchFolder()
  }, [folderId, toggleFolderRefresh, apiMode])

  const [toggleChronologyRefresh, setToggleChronologyRefresh] = useState(false)
  const refreshChronology = () =>
    setToggleChronologyRefresh((current) => !current)
  useEffect(() => {
    async function fetchChronology() {
      const resp = await FoldersApi.getChronology(folderId, apiMode)
      if (resp.status !== 204) {
        setChronology(resp.data)
      }
    }

    fetchChronology()
  }, [folderId, toggleChronologyRefresh, apiMode])

  const navContainerRef = useRef()
  const [navMaxScrollLeft, setNavMaxScrollLeft] = useState(0)

  const [isLeftVisible, setIsLeftVisible] = useState()
  const [isRightVisible, setIsRightVisible] = useState()

  useEffect(() => {
    if (navContainerRef.current) {
      const max =
        navContainerRef.current.scrollWidth -
        navContainerRef.current.clientWidth
      setNavMaxScrollLeft(max)
      setIsRightVisible(navContainerRef.current.scrollLeft < max)
      setIsLeftVisible(navContainerRef.current.scrollLeft > 0)
    }
  }, [navContainerRef])

  const scrollRight = () => {
    navContainerRef.current.scrollTo({
      left: navMaxScrollLeft,
      behavior: 'smooth',
    })
    setTimeout(() => {
      setIsRightVisible(navContainerRef.current.scrollLeft < navMaxScrollLeft)
      setIsLeftVisible(navContainerRef.current.scrollLeft > 0)
    }, 800)
  }

  const scrollLeft = () => {
    navContainerRef.current.scrollTo({
      left: 0,
      behavior: 'smooth',
    })
    setTimeout(() => {
      setIsRightVisible(navContainerRef.current.scrollLeft < navMaxScrollLeft)
      setIsLeftVisible(navContainerRef.current.scrollLeft > 0)
    }, 800)
  }

  const [navScrollMode, setNavScrollMode] = useState('scroll')

  useEffect(() => {
    const onResize = debounce(() => {
      const isSmallScreen = window.matchMedia('(max-width: 600px)').matches

      if (navScrollMode !== 'scroll' && isSmallScreen) {
        setNavScrollMode('scroll')
      } else if (navScrollMode !== 'buttons' && !isSmallScreen) {
        setNavScrollMode('buttons')
      }
    }, 500)

    window.addEventListener('resize', onResize)
    onResize()

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [navScrollMode, setNavScrollMode])

  const { url: routeUrl } = useRouteMatch()

  const isMatching = (subpath) =>
    matchPath(`${routeUrl}${subpath}`, {
      path: location.pathname,
      exact: true,
    }) !== null

  const { user } = useAuth()

  const userCanSetChronology =
    apiMode === 'admin' ||
    (apiMode === 'expert' && folder?.referent.id === user.id)

  return (
    <div
      className={cx('Folder page-container', {
        'full-height flex flex-col': isMatching('/chronology'),
      })}
    >
      <h1 className="page-title">
        <span className="Folder-name">{folderName}</span>
        <span className="Folder-description">- {folderDescription}</span>
      </h1>

      <div className="Folder-nav">
        <button
          className={cx('Folder-navGo toLeft', {
            isVisible: navScrollMode === 'buttons' && isLeftVisible,
          })}
          onClick={scrollLeft}
        >
          <ChevronLeftIcon />
        </button>

        <div
          ref={navContainerRef}
          className={cx('Folder-navContainer', {
            isScrollable: navScrollMode === 'scroll',
          })}
        >
          <ul>
            {apiMode === 'admin' && (
              <li>
                <QMuiLinkButton
                  to={`${routeUrl}/customer-service`}
                  size="small"
                  color={
                    isMatching('/customer-service') ? 'secondary' : 'primary'
                  }
                >
                  Service client
                </QMuiLinkButton>
              </li>
            )}
            <li>
              <QMuiLinkButton
                to={`${routeUrl}/chronology`}
                size="small"
                color={isMatching('/chronology') ? 'secondary' : 'primary'}
              >
                Chronologie
              </QMuiLinkButton>
            </li>
            {apiMode !== 'customer' && (
              <li>
                <QMuiLinkButton
                  to={`${routeUrl}/lawyer-collaboration`}
                  size="small"
                  color={
                    isMatching('/lawyer-collaboration')
                      ? 'secondary'
                      : 'primary'
                  }
                >
                  Échanges avocats
                </QMuiLinkButton>
              </li>
            )}
            <li>
              <QMuiLinkButton
                to={
                  ['customer', 'expert'].includes(apiMode)
                    ? `/messages?folder=${folderId}`
                    : `/admin/messages?folder=${folderId}`
                }
                size="small"
                color={isMatching('/messaging') ? 'secondary' : 'primary'}
              >
                Messagerie
              </QMuiLinkButton>
            </li>
            <li>
              <QMuiLinkButton
                to={`${routeUrl}/documents`}
                size="small"
                color={isMatching('/documents') ? 'secondary' : 'primary'}
              >
                Documents
              </QMuiLinkButton>
            </li>
            <li>
              <QMuiLinkButton
                to={`${routeUrl}/synthesis`}
                size="small"
                color={isMatching('/synthesis') ? 'secondary' : 'primary'}
              >
                Synthèse
              </QMuiLinkButton>
            </li>
            <li>
              <QMuiLinkButton
                to={`${routeUrl}/qualy-team`}
                size="small"
                color={isMatching('/qualy-team') ? 'secondary' : 'primary'}
              >
                Équipe {variant.title}
              </QMuiLinkButton>
            </li>
            <li>
              <QMuiLinkButton
                to={`${routeUrl}/billing`}
                size="small"
                color={isMatching('/billing') ? 'secondary' : 'primary'}
              >
                Facturation
              </QMuiLinkButton>
            </li>
          </ul>
        </div>

        <button
          className={cx('Folder-navGo toRight', {
            isVisible: navScrollMode === 'buttons' && isRightVisible,
          })}
          onClick={scrollRight}
        >
          <ChevronRightIcon />
        </button>
      </div>

      <div className="Folder-content">
        <Switch>
          {apiMode === 'admin' && (
            <Route path={`${routeUrl}/customer-service`} exact>
              <CustomerService folder={folder} />
            </Route>
          )}

          <Route path={`${routeUrl}/lawyer-collaboration`} exact>
            <LawyerCollaboration folder={folder} apiMode={apiMode} />
          </Route>

          <Route path={`${routeUrl}/chronology`} exact>
            {chronology !== null ? (
              <FolderChronology
                folder={folder}
                refreshChronology={refreshChronology}
                chronology={chronology}
                setChronology={setChronology}
                apiMode={apiMode}
              />
            ) : (
              folder &&
              (userCanSetChronology ? (
                <FolderChronologyForm
                  folder={folder}
                  setChronology={setChronology}
                  apiMode={apiMode}
                />
              ) : (
                <p className="Folder-noChronology">
                  Ce dossier n'a pas encore de chronologie.
                </p>
              ))
            )}
          </Route>

          <Route path={`${routeUrl}/documents`} exact>
            <Documents folder={folder} apiMode={apiMode} />
          </Route>

          <Route path={`${routeUrl}/synthesis`} exact>
            <Synthesis folder={folder} apiMode={apiMode} />
          </Route>

          <Route path={`${routeUrl}/qualy-team`} exact>
            <QualyTeam
              folder={folder}
              refreshFolder={refreshFolder}
              apiMode={apiMode}
            />
          </Route>
          <Route path={`${routeUrl}/billing`} exact>
            <Billing folder={folder} apiMode={apiMode} />
          </Route>
          <Route path={routeUrl} exact>
            <Redirect to={{ pathname: `${routeUrl}/chronology` }} />
          </Route>
        </Switch>
      </div>
    </div>
  )
}
