import { useState, useEffect } from 'react'
import { useSnackbar } from '@Context/snackbar.js'
import { CSSTransition } from 'react-transition-group'
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  ErrorOutline as ErrorOutlineIcon,
} from '@material-ui/icons/'
import classnames from 'classnames'

function QSnackbar() {
  const { snackbarMsgs, removeSnackbarMsg } = useSnackbar()
  const [msgsTimeouts, setMsgsTimeout] = useState([])

  useEffect(() => {
    const msgsIds = snackbarMsgs.map((msg) => msg.id)
    const newMsgsTimeouts = msgsTimeouts.filter((msgId) =>
      msgsIds.includes(msgId)
    )

    for (let msg of snackbarMsgs) {
      if (!msgsTimeouts.includes(msg.id)) {
        newMsgsTimeouts.push(msg.id)
        setTimeout(() => {
          removeSnackbarMsg(msg.id)
        }, 4000)
      }
    }

    setMsgsTimeout(newMsgsTimeouts)
  }, [snackbarMsgs, removeSnackbarMsg])

  return (
    <ul className="Snackbar">
      {snackbarMsgs.map((msg) => (
        <CSSTransition
          classNames={{
            appear: 'Snackbar-item-appear',
            appearActive: 'Snackbar-item-appearActive',
          }}
          key={msg.id}
          in={true}
          appear={true}
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false)
          }}
        >
          <li
            className={classnames('Snackbar-item', {
              isSuccess: msg.severity === 'success',
              isError: msg.severity === 'error',
            })}
          >
            {msg.severity === 'success' && <CheckCircleOutlineIcon />}
            {msg.severity === 'error' && <ErrorOutlineIcon />}
            <span>{msg.content}</span>
          </li>
        </CSSTransition>
      ))}
    </ul>
  )
}

export default QSnackbar
