import { useState, useEffect } from 'react'
import { InputAdornment } from '@material-ui/core'
import { debounce } from 'lodash'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import QMuiButton from '@CommonComponents/Mui/QMuiButton'
import QMuiInput from '@CommonComponents/Mui/QMuiInput'
import SearchIcon from '@Components/icons/SearchIcon'
import DocumentTable from '@Components/DocumentTable'
import DocumentTags from '@Components/DocumentTags'
import DocumentUploadForm from '@Components/forms/DocumentUploadForm'
import DocumentRequestForm from '@Components/forms/DocumentRequestForm'

import foldersAPI from '@API/admin/folders'
import * as documentsAPI from '@API/admin/documents'
import { useSnackbar } from '@Context/snackbar'
import { getErrorMsgFromSymfonyResponse } from '@Helpers'

export default function Documents({ folder, apiMode }) {
  const [documentType, setDocumentType] = useState('customer')
  const [documents, setDocuments] = useState([])
  const [filteredDocuments, setFilteredDocuments] = useState([])
  const [documentRequests, setDocumentRequests] = useState([])
  const [requestId, setRequestId] = useState(null)
  const [tags, setTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [actions, setActions] = useState([])
  const [editedDoc, setEditedDoc] = useState(null)
  const [modalTitle, setModalTitle] = useState('Ajouter un document')
  const [addMenuAnchor, setAddMenuAnchor] = useState(null)
  const [isDocUploadOpen, setIsDocUploadOpen] = useState(false)
  const [isDocRequestOpen, setIsDocRequestOpen] = useState(false)

  const { addSnackbarMsg } = useSnackbar()

  const [search, setSearch] = useState('')
  const debounceSearch = debounce(async (e) => setSearch(e.target.value), 800)

  const [triggerReloadDocuments, setTriggerReloadDocuments] = useState(true)
  const [isDocumentsLoading, setIsDocumentsLoading] = useState(true)

  const [triggerReloadRequests, setTriggerReloadRequests] = useState(true)
  const [isRequestsLoading, setIsRequestsLoading] = useState(true)

  useEffect(() => {
    async function fetchDocuments() {
      if (!isDocumentsLoading) setIsDocumentsLoading(true)

      const {
        data: { results },
      } = await foldersAPI.getDocuments(
        folder.id,
        documentType,
        search,
        apiMode
      )

      const newTags = []
      results.forEach((doc) => {
        doc.tags.forEach((tag) => {
          if (!newTags.includes(tag)) {
            newTags.push(tag)
          }
        })
      })

      if (results) {
        setDocuments(results)
        setTags(newTags)
        setIsDocumentsLoading(false)
      }
    }

    if (folder && folder.id) {
      fetchDocuments()
    }
  }, [documentType, folder, triggerReloadDocuments, search, apiMode])

  useEffect(() => {
    if (!isRequestsLoading) {
      setIsRequestsLoading(true)
    }

    async function fetchDocumentRequests() {
      const { data } = await foldersAPI.getDocumentRequests(
        folder.id,
        documentType,
        apiMode
      )
      if (data) {
        setDocumentRequests(data)
      }
    }

    if (folder && folder.id) {
      fetchDocumentRequests()
      setIsRequestsLoading(false)
    }
  }, [folder, triggerReloadRequests, isRequestsLoading, apiMode, documentType])

  useEffect(() => {
    if (selectedTags.length > 0) {
      setFilteredDocuments(
        documents.filter((doc) =>
          selectedTags.some((tag) => doc.tags.includes(tag))
        )
      )
    } else {
      setFilteredDocuments(documents)
    }
  }, [documents, selectedTags])

  useEffect(() => {
    setActions({
      type: 'menu',
      items: [
        {
          label: 'Voir',
          onClick: showDoc,
        },
        {
          label: 'Télécharger',
          onClick: downloadDoc,
        },
      ],
    })
    if (apiMode !== 'customer') {
      setActions({
        type: 'menu',
        items: [
          {
            label: 'Voir',
            onClick: showDoc,
          },
          {
            label: 'Télécharger',
            onClick: downloadDoc,
          },
          {
            label: 'Modifier',
            onClick: editDoc,
          },
          {
            label: 'Supprimer',
            confirm: {
              btnText: 'Supprimer',
              msg: 'Êtes-vous sûr de vouloir supprimer ce document ?',
              handleConfirmation: deleteDoc,
            },
          },
        ],
      })
    }
  }, [apiMode])

  const validateDocReq = async (docReq) => {
    try {
      await foldersAPI.patchValidateDocumentRequest(docReq.id, apiMode)
      addSnackbarMsg({
        severity: 'success',
        content: 'Requète de document validée',
      })
      setTriggerReloadRequests((current) => !current)
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  const remindDocReq = async (docReq) => {
    try {
      await foldersAPI.patchRemindDocumentRequest(docReq.id, apiMode)
      addSnackbarMsg({
        severity: 'success',
        content: 'Requète de document relancée',
      })
      setTriggerReloadRequests((current) => !current)
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  const deleteDocReq = async (docReq) => {
    try {
      await foldersAPI.deleteDocumentRequest(docReq.id, apiMode)
      addSnackbarMsg({
        severity: 'success',
        content: 'Requète de document supprimée',
      })
      setTriggerReloadRequests((current) => !current)
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  const showDoc = async (doc) => {
    window.open(`/download/${doc.id}`)
  }

  const addDocReq = (id) => {
    setRequestId(id)
    setAddMenuAnchor(null)
    setEditedDoc(null)
    setModalTitle('Ajouter un document')
    setIsDocUploadOpen(true)
  }

  const downloadDoc = (doc) => {
    try {
      documentsAPI.downloadDocument(doc.id, doc.name, apiMode)
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  const editDoc = (doc) => {
    setEditedDoc(doc)
    setModalTitle('Modifier un document')
    setIsDocUploadOpen(true)
  }

  const deleteDoc = async (doc) => {
    try {
      await documentsAPI.deleteDocument(doc.id, apiMode)
      setTriggerReloadDocuments((current) => !current)
      addSnackbarMsg({
        severity: 'success',
        content: 'Document supprimé',
      })
    } catch (error) {
      addSnackbarMsg({
        severity: 'error',
        content: getErrorMsgFromSymfonyResponse(error.response),
      })
    }
  }

  return (
    <div className="Documents">
      {apiMode !== 'customer' && (
        <div className="Documents-head mt-6">
          <div className="flex justify-center mt-4">
            <QMuiButton
              size="small"
              color="secondary"
              variant={documentType === 'customer' ? 'contained' : 'outlined'}
              onClick={() => setDocumentType('customer')}
              className="mr-4"
            >
              Client
            </QMuiButton>

            <QMuiButton
              size="small"
              color="primary"
              variant={documentType === 'expert' ? 'contained' : 'outlined'}
              onClick={() => setDocumentType('expert')}
            >
              Avocats
            </QMuiButton>
          </div>
        </div>
      )}
      <div
        className="page-section"
        style={{ marginTop: apiMode === 'customer' ? 10 : 30 }}
      >
        <div className="Documents-sectionHead">
          <h2>Documents demandés</h2>

          <QMuiButton
            className="ml-auto"
            onClick={(event) => setAddMenuAnchor(event.currentTarget)}
          >
            Ajouter un document
          </QMuiButton>

          <Menu
            id="add-document-menu"
            anchorEl={addMenuAnchor}
            keepMounted
            open={addMenuAnchor !== null}
            onClose={() => {
              setAddMenuAnchor(null)
            }}
            transformOrigin={{ vertical: -40, horizontal: 0 }}
          >
            <MenuItem
              onClick={() => {
                setAddMenuAnchor(null)
                setEditedDoc(null)
                setIsDocUploadOpen(true)
              }}
            >
              Ajouter un document
            </MenuItem>
            {apiMode !== 'customer' && (
              <MenuItem
                onClick={() => {
                  setAddMenuAnchor(null)
                  setIsDocRequestOpen(true)
                }}
              >
                Demande de document
              </MenuItem>
            )}
          </Menu>
        </div>

        <DocumentTable
          isLoading={isRequestsLoading}
          documents={documentRequests}
          type="request"
          style={{ paddingTop: 0 }}
          columns={[
            { headerName: 'Nom', field: 'documentName', minWidth: 150 },
            {
              headerName: 'Description',
              field: 'documentDescription',
              minWidth: 300,
            },
          ]}
          actions={
            apiMode === 'customer'
              ? {
                  type: 'link',
                  items: [
                    {
                      label: 'Ajouter le document',
                      onClick: addDocReq,
                    },
                  ],
                }
              : {
                  type: 'menu',
                  items: [
                    {
                      label: 'Valider',
                      onClick: validateDocReq,
                    },
                    {
                      label: 'Relancer',
                      onClick: remindDocReq,
                    },
                    {
                      label: 'Supprimer',
                      onClick: deleteDocReq,
                    },
                  ],
                }
          }
        />
      </div>

      <div className="page-section">
        <div className="Documents-sectionHead">
          <h2>Tous les documents</h2>
          <QMuiInput
            className="ml-auto mt-4"
            onChange={debounceSearch}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon style={{ width: 24, height: 24 }} />
              </InputAdornment>
            }
          />
        </div>

        {tags.length > 0 && (
          <DocumentTags
            tags={tags}
            onSelect={(tags) => setSelectedTags(tags)}
          />
        )}

        <DocumentTable
          isLoading={isDocumentsLoading}
          documents={filteredDocuments}
          columns={[
            { headerName: 'Nom', field: 'name', minWidth: 150 },
            {
              headerName: 'Description',
              field: 'description',
              minWidth: 300,
            },
          ]}
          actions={actions}
        />
      </div>

      {folder && (
        <>
          <DocumentUploadForm
            apiMode={apiMode}
            document={editedDoc}
            open={isDocUploadOpen}
            requestId={requestId}
            title={modalTitle}
            onClose={() => setIsDocUploadOpen(false)}
            folderId={folder.id}
            triggerReload={() =>
              setTriggerReloadDocuments((current) => !current)
            }
          />

          <DocumentRequestForm
            apiMode={apiMode}
            open={isDocRequestOpen}
            onClose={() => setIsDocRequestOpen(false)}
            folder={folder}
            triggerReload={() =>
              setTriggerReloadRequests((current) => !current)
            }
          />
        </>
      )}
    </div>
  )
}
