import APIClient from '../../APIClient'

const DOMAINS_URI = '/api/admin/domains'
const SUBDOMAINS_URI = (domainId) => `/api/admin/domains/${domainId}/subdomains`

const getAllDomains = async (page, search) => {
  return await APIClient.get(DOMAINS_URI, { params: { page, search } })
}

const getAllDomainsAtOnce = async (apiMode) => {
  return await APIClient.get(`/api/${apiMode}/domains/all`)
}

const addDomain = async (name) => {
  return await APIClient.post(DOMAINS_URI, { name })
}

const deleteDomain = async (id) => {
  return await APIClient.delete(DOMAINS_URI + `/${id}`)
}

const patchDomain = async (domainId, name) => {
  return await APIClient.patch(`/api/admin/domains/${domainId}`, { name })
}

const addSubDomain = async (domainId, name) => {
  return await APIClient.post(SUBDOMAINS_URI(domainId), { name })
}

const deleteSubDomain = async (id) => {
  return await APIClient.delete(`/api/admin/subdomains/${id}`)
}

const patchSubDomain = async (id, name) => {
  return await APIClient.patch(`/api/admin/subdomains/${id}`, { name })
}

const DomainsAPI = {
  getAllDomains,
  getAllDomainsAtOnce,
  addDomain,
  deleteDomain,
  patchDomain,
  addSubDomain,
  patchSubDomain,
  deleteSubDomain,
}
export default DomainsAPI
