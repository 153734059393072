import logo from '@Assets/img/lk-avocats/logo-white.png'
import menuArrow from '@Assets/img/lk-avocats/arrow-menu.svg'

export default {
  title: 'LK Avocats',
  logoWhite: logo,
  headerSvgGradient: {
    left: '#003445',
    right: '#003445',
  },
  menuArrow: menuArrow,
  signinLink: 'https://www.lk-avocats.fr/',
  cssVars: {
    '--color-alpha': '#003445',
    '--color-alpha-light': '#004860',
    '--color-btn-alpha': 'var(--color-alpha)',
    '--color-btn-alpha-dark': '#00212d',
    '--login-bg': 'var(--color-alpha-light)',
    '--header-bg': 'var(--color-alpha)',
  },
}
