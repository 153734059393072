import Chat from '@Components/Chat'

export default function CustomerService({ folder, apiMode }) {
  return (
    <div className="CustomerService">
      <Chat
        title="Historique sur le dossier"
        folder={folder}
        type="folder"
        mode="chat"
        apiMode="admin"
        permissions="rw"
      />
      <Chat
        title="Historique général sur le client"
        folder={folder}
        type="customer"
        mode="chat"
        apiMode="admin"
        permissions="rw"
      />
    </div>
  )
}
